import React, { useContext, useState } from 'react';
import './topbar.scss';
import './sidenav.scss';
import ArrowRight from '../../assets/icons/ArrowRight.svg';
import UserIcon from '../../assets/icons/UserIcon.svg';
import loginIcon from '../../assets/icons/loginIcon.svg';
import AppIcon from '../Icon/AppIcon';
import AppButton from '../Button/AppButton';
import FribourgOpenLogoNb from '../../assets/img/FRIBOURG_OPEN.svg';
import { Link, useNavigate } from 'react-router-dom';
import LanguageButtons from '../LanguageButtons';
import { useTranslation } from 'react-i18next';
import AppText from '../Text/AppText';
import {
  DiscoverCompaniesButton,
  MobileWrapper,
  TopbarWrapper,
  UserButtonWrapper,
  UserWrapper,
  Wrapper,
} from './styled';
import IconButton from '../IconButton';
import UserSidebar from '../UserSidebar';
import TopbarInput from './components/TopbarInput';
import Flex from '../Flex/Flex';
import { AuthContext } from '../../context/AuthContext';
import EventSelector from './components/EventSelector';

const Topbar = ({ isCompanyPage = false }) => {
  const [token, setToken] = useContext(AuthContext);
  const [showSidebar, setShowSidebar] = useState(false);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const handleLogin = () => {
    if (!token) {
      navigate('/login');
      return;
    }
    if (navigator.userAgent.includes('Unity')) {
      window.location.href = 'uniwebview://userLoggedOut';
    }
    localStorage.setItem('language', i18n.language);
    localStorage.removeItem('token');
    setToken(null);
    navigate('/');
  };

  const handleSidebar = () => {
    setShowSidebar((prevState) => !prevState);
  };

  const handleCampanyRedirect = () => {
    navigate('/company');
  };

  return (
    <Wrapper>
      <TopbarWrapper>
        <Link to={'/'}>
          <div className="topbar-text">
            <img src={FribourgOpenLogoNb} alt="" className="topbar-logo" />
          </div>
        </Link>
        {isCompanyPage ? (
          <>
            <TopbarInput />
            <MobileWrapper>
              <EventSelector />
            </MobileWrapper>
            <LanguageButtons />
          </>
        ) : (
          <Flex
            justifyContent={'space-between'}
            className={isCompanyPage ? 'topbar_input' : ''}
            gap={'30px'}
            sx={{ 'margin-right': '10px' }}
          >
            <DiscoverCompaniesButton>
              <AppButton
                type="primary"
                text={'nav.discoverCompanies'}
                fontWeight={'900'}
                icon={ArrowRight}
                onClick={handleCampanyRedirect}
              />
            </DiscoverCompaniesButton>
            <LanguageButtons />
          </Flex>
        )}
        <UserWrapper>
          {token ? (
            <>
              <AppText className={'user_info'} text={'profile.topBarText'} fontWeight={'900'} color={'GrayBlue70'} />
              <IconButton onClick={handleSidebar}>
                <UserButtonWrapper>
                  <AppIcon icon={UserIcon} />
                </UserButtonWrapper>
              </IconButton>
            </>
          ) : (
            <>
              <AppText className={'user_info'} text={'login.title'} fontWeight={'900'} color={'GrayBlue70'} />
              <IconButton onClick={handleLogin}>
                <UserButtonWrapper>
                  <AppIcon icon={loginIcon} />
                </UserButtonWrapper>
              </IconButton>
            </>
          )}
        </UserWrapper>
        {
          <UserSidebar
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            isCompanyPage={isCompanyPage}
            setToken={setToken}
          />
        }
      </TopbarWrapper>
    </Wrapper>
  );
};

export default Topbar;
