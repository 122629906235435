import { useMutation } from 'react-query';
import api from '../../../api/api';

const sendFav = async (isActive, companyId) => {
  const user = JSON.parse(localStorage.getItem('userdata'));
  try {
    if (isActive) {
      await api.delete(`/favorites/${companyId}`);
    } else {
      const body = {
        user_id: user.id,
        company_id: parseInt(companyId),
      };

      await api.post(`/favorites`, body);
    }
  } catch (error) {
    throw new Error('Failed to perform the mutation');
  }
};

export function useMutateFavorites() {
  return useMutation(
    async (data) => {
      await sendFav(data.isActive, data.companyId);
    },
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );
}
