import React, { useContext, useEffect, useState } from 'react';
import Modal from '../../../Components/Modal';
import StepOne from '../../CompanyPage/components/VisitModal/components/StepOne';
import StepTwo from '../../CompanyPage/components/VisitModal/components/StepTwo';
import AppButton from '../../../Components/Button/AppButton';
import { useGetVisit } from '../../../hooks/api/visits/useGetVisit';
import Loader from '../../../Components/Loader';
import styled from 'styled-components';
import AppText from '../../../Components/Text/AppText';
import { usePutRegistrations } from '../../../hooks/api/registrations/usePutRegistrations';
import { EventContext } from '../../../context/EventContext';
import { useTranslation } from 'react-i18next';

const LoaderWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.2);
`;

const EditVisit = ({ selectedVisitId, registrationId, setOpen }) => {
  const { visitsData, isLoading } = useGetVisit(selectedVisitId);
  const [event] = useContext(EventContext);
  const { i18n } = useTranslation();
  const [companions, setCompanions] = useState([]);
  const [newRow, setNewRow] = useState({ firstName: '', lastName: '', date_of_birth: '' });
  const mutation = usePutRegistrations(setOpen);
  const activityTypesNames = visitsData?.activity_types?.map((at) => at?.activity_type[i18n.language]);
  const activityRestriction = visitsData?.activity_restrictions.map((res) =>
    res?.activity_restriction?.fr ? res?.activity_restriction[i18n.language] : null
  );
  const continueDisabled = Boolean(newRow.firstName || newRow.lastName);

const selectedTimeLanguage = visitsData?.language || 'FR'; // By Default 'FR' if no language is defined.

  useEffect(() => {
    if (visitsData?.people_list) {
      setCompanions(
        visitsData.people_list[0]
          .map((person, index) => {
            if (index !== 0) {
              return {
                ...person,
                date_of_birth: person.date_of_birth.slice(0, 4),
              };
            }
            return null;
          })
          .filter((person) => person !== null)
      );
    }
  }, [visitsData]);

  const sendData = async () => {
    await mutation.mutateAsync({
      people_list: [
        visitsData?.people_list[0][0],
        ...companions.map((person) => ({
          ...person,
          date_of_birth:
            parseInt(person.date_of_birth) < 1900 ? '1900-01-01' : person.date_of_birth.toString() + '-01-01',
          has_limited_mobility: 0,
        })),
      ],
      id: registrationId,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader wrapper={true} />
      </LoaderWrapper>
    );
  }

  return (
    <Modal width={window.innerWidth > 850 ? 'unset' : '80%'}>
      <AppText
        text={'visit.page.edit.title'}
        color={'GrayBlue50'}
        fontSize={'xl'}
        fontWeight={'900'}
        textAlign={'center'}
      />
      <AppText text={'visit.page.edit.body'} color={'GrayBlue55'} fontSize={'md'} textAlign={'center'} />
      <div style={{ marginBottom: '20px' }}>
        {visitsData && (
          <StepOne
            companyData={{
              name: visitsData.company_name,
              has_support_for_limited_mobility: visitsData.has_support_for_limited_mobility,
              full_address: visitsData.full_address,
              is_trainer_company: visitsData.is_trainer_company,
              description: visitsData.description,
            }}
            activityTypesNames={activityTypesNames}
            activityRestriction={activityRestriction}
            timeAndPlaces={{
              nPlaces: visitsData.nPlaces,
              nTakenPlaces: visitsData.nTakenPlaces,
              endTime: visitsData.endTime,
              startTime: visitsData.startTime,
            }}
            visit={visitsData}
            selectedTimeLanguage={selectedTimeLanguage}
          />
        )}
      </div>
      <div>
        {visitsData && (
          <StepTwo
            visit={visitsData}
            timeAndPlaces={{
              nPlaces: visitsData.nPlaces,
              nTakenPlaces: visitsData.nTakenPlaces,
              endTime: visitsData.endTime,
              startTime: visitsData.startTime,
            }}
            isMainCounted={0}
            showPlacesGauge={event?.show_filling_gauge}
            companions={companions}
            setNewRow={setNewRow}
            setCompanions={setCompanions}
            isVisitPage
          />
        )}
        <AppButton
          text={'visit.page.edit.button'}
          size={'xl'}
          textAlign={'center'}
          disabled={
            JSON.stringify(companions) ===
              JSON.stringify(
                visitsData?.people_list[0].map((person) => ({
                  ...person,
                  date_of_birth: person.date_of_birth.slice(0, 4),
                }))
              ) || continueDisabled
          }
          onClick={sendData}
          styles={{ width: '50%', margin: '40px auto' }}
        />
        <AppButton
          text={'company.page.return'}
          size={'xl'}
          textAlign={'center'}
          styleType={'ghost'}
          onClick={handleClose}
          styles={{ margin: '40px auto 0' }}
        />
      </div>
    </Modal>
  );
};

export default EditVisit;
