export const colors = {
  primary: 'rgba(51, 97, 255, 1)',
  black: 'rgba(0, 0, 0, 0.3)',
  dark: 'rgba(19, 25, 38, 1)',
  default: 'rgb(255,255,255, 1)',
  ghost: 'transparent',
  secondary: 'rgba(51, 97, 255, 0.1)',
  white: 'rgba(255, 255, 255, 0.3)',
  orange: 'rgba(255, 102, 51, 1)',
  bronze: 'rgba(204, 116, 41, 1)',
  green: 'rgba(41, 204, 57, 1)',
  pink: 'rgba(230, 46, 123, 1)',
  dark_pink: 'rgba(204, 0, 86, 1)',
  purple: 'rgba(136, 51, 255, 1)',
  red: 'rgba(230, 46, 46, 1)',
  teal: 'rgba(51, 191, 255, 1)',
  tiffany: 'rgba(20, 184, 166, 1)',
  yellow: 'rgba(255, 203, 51, 1)',
  orangeSecondary: 'rgba(255, 102, 51, 0.1)',
  bronzeSecondary: 'rgba(204, 116, 41, 0.1)',
  greenSecondary: 'rgba(41, 204, 57, 0.1)',
  pinkSecondary: 'rgba(230, 46, 123, 0.1)',
  purpleSecondary: 'rgba(136, 51, 255, 0.1)',
  redSecondary: 'rgba(230, 46, 46, 0.1)',
  tealSecondary: 'rgba(51, 191, 255, 0.1)',
  tiffanySecondary: 'rgba(20, 184, 166, 0.1)',
  yellowSecondary: 'rgba(255, 203, 51, 0.1)',
};

export const fontColors = {
  DarkBlue2: '#0039FF',
  DarkBlue1: '#194DFF',
  Blue: '#3361FF',
  LightBlue1: '#4D74FF',
  LightBlue2: '#6688FF',
  DarkGreen2: '#00B211',
  DarkGreen1: '#13BF24',
  Green: '#29CC39',
  LightGreen1: '#45E655',
  LightGreen2: '#66FF75',
  DarkPink2: '#CC0056',
  DarkPink1: '#D91668',
  Pink: '#E62E7B',
  LightPink1: '#F24990',
  LightPink2: '#FF66A6',
  DarkPurple2: '#6A00FF',
  DarkPurple1: '#7919FF',
  Purple: '#8833FF',
  LightPurple1: '#974DFF',
  LightPurple2: '#A666FF',
  DarkOrange2: '#CC4314',
  DarkOrange1: '#E64B17',
  Orange: '#FF6633',
  LightOrange1: '#FF794D',
  LightOrange2: '#FF8D66',
  DarkTealBlue2: '#008CCC',
  DarkTealBlue1: '#17A5E6',
  TealBlue: '#33BFFF',
  LightTealBlue1: '#4DC7FF',
  LightTealBlue2: '#66CFFF',
  DarkRed2: '#B20000',
  DarkRed1: '#CC1414',
  Red: '#E62E2E',
  LightRed1: '#F24949',
  LightRed2: '#FF6666',
  DarkYellow2: '#D9A200',
  DarkYellow1: '#E6B117',
  Yellow: '#FFCB33',
  LightYellow1: '#FFD559',
  LightYellow2: '#FFD559',
  Bronze: '#994700',
  DarkTiffany2: '#00B397',
  DarkTiffany1: '#14CCB0',
  Tiffany: '#2EE6CA',
  LightTiffany1: '#49F2D8',
  LightTiffany2: '#66FFE8',
  Black: '#000000',
  GrayBlue10: '#0D111A',
  GrayBlue15: '#131926',
  GrayBlue20: '#1A2233',
  GrayBlue25: '#202B40',
  GrayBlue30: '#26334D',
  GrayBlue35: '#2D3C59',
  GrayBlue40: '#334466',
  GrayBlue45: '#3F5073',
  GrayBlue50: '#4D5E80',
  GrayBlue55: '#62708C',
  GrayBlue60: '#6B7A99',
  GrayBlue65: '#7C8AA6',
  GrayBlue70: '#7D8FB3',
  GrayBlue75: '#99A6BF',
  GrayBlue80: '#ADB8CC',
  GrayBlue85: '#C3CAD9',
  GrayBlue90: '#DADEE6',
  GrayBlue91: '#DCE0E8',
  GrayBlue92: '#DFE3EB',
  GrayBlue93: '#E1E5ED',
  GrayBlue94: '#E4E7F0',
  GrayBlue95: '#EDEFF2',
  GrayBlue96: '#F2F3F5',
  GrayBlue97: '#F5F6F7',
  GrayBlue98: '#FFFFFF',
  GrayBlue99: '#F7F8FA',
  White: '#FAFBFC',
};
