import { useQuery } from 'react-query';
import { configUseQuery } from '../../helpers/queryConfig';
import api from '../../api/api';
import { sortByNameField } from '../../helpers/helpers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const fetchActivitySectors = async () => {
  const response = await api
    .get('/activitysectors')
    .then((response) => response)
    .catch((error) => console.log(error));
  return response.data;
};

export function useGetActivitySectors() {
  const [activitySectors, setActivitySectors] = useState(null);
  const { data, isLoading } = useQuery('activity_sectors', fetchActivitySectors, configUseQuery);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (data?.activitySectors.length > 0) {
      setActivitySectors(sortByNameField(data?.activitySectors, i18n.language));
    }
  }, [data, i18n.language]);

  return { activitySectors: activitySectors, isLoading };
}
