import AppIcon from '../Icon/AppIcon';
import facebook from '../../assets/icons/facebook.svg';
import inst from '../../assets/icons/inst.svg';
import mail from '../../assets/icons/mail.svg';
import local_phone from '../../assets/icons/local_phone.svg';
import './footer.scss';
import Divider from '../Divider';
import { FooterContentWrapper, IconButtonWrapper, MailSocialWrapper } from './styled';
import Flex from '../Flex/Flex';
import IconButton from '../IconButton';
import React from 'react';
import FooterTextLink from './components/FooterTextLink';

const Footer = () => {
  const handleFacebook = () => {
    window.open('https://www.facebook.com/ccif.hikf/', '_blank');
  };

  const handleInst = () => {
    window.open('https://www.instagram.com/ccif.hikf/', '_blank');
  };

  return (
    <footer className="c-footer">
      <Divider />
      <FooterContentWrapper>
        <MailSocialWrapper>
          <Flex alignItems={'center'} gap={'20px'}>
            <AppIcon icon={mail} />
            <FooterTextLink text={'public@fribourgopen.ch'} link={'mailto:public@fribourgopen.ch'} blank />
          </Flex>
          <Flex gap={'15px'} justifyContent={'flex-end'}>
            <IconButton onClick={handleFacebook}>
              <IconButtonWrapper>
                <AppIcon icon={facebook} />
              </IconButtonWrapper>
            </IconButton>
            <IconButton onClick={handleInst}>
              <IconButtonWrapper>
                <AppIcon icon={inst} />
              </IconButtonWrapper>
            </IconButton>
          </Flex>
        </MailSocialWrapper>
        <div style={{ marginTop: '5px' }}>
          <Flex alignItems={'center'} gap={'20px'}>
            <AppIcon icon={local_phone} />
            <FooterTextLink text={'+41 26 347 12 20'} link={'tel:+41263471220'} blank />
          </Flex>
        </div>
        <div className={'c-footer__text-cotainer'}>
          <FooterTextLink
            text={'footer.ccifAddress'}
            link={
              "https://www.google.com/maps/place/Chambre+de+commerce+et+d'industrie+du+canton+de+Fribourg/@46.8095895,7.1458384,17z/data=!3m1!4b1!4m6!3m5!1s0x478e6eccc77a7dc7:0xc707d0db81a23e90!8m2!3d46.8095859!4d7.1484133!16s%2Fg%2F1vqmh85w?entry=ttu"
            }
            blank
          />
          <FooterTextLink
            text={'footer.ccifAddressDetails'}
            link={
              "https://www.google.com/maps/place/Chambre+de+commerce+et+d'industrie+du+canton+de+Fribourg/@46.8095895,7.1458384,17z/data=!3m1!4b1!4m6!3m5!1s0x478e6eccc77a7dc7:0xc707d0db81a23e90!8m2!3d46.8095859!4d7.1484133!16s%2Fg%2F1vqmh85w?entry=ttu"
            }
            blank
          />
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <FooterTextLink text={'footer.ccifCity'} link={'/'} />
            <Flex gap={'33px'}>
              <FooterTextLink text={'footer.conf'} link={'/news/7'} />
            </Flex>
          </Flex>
        </div>
      </FooterContentWrapper>
    </footer>
  );
};

export default Footer;
