import React from 'react';

const IconButton = ({ onClick, children, ...restProps }) => {
  return (
    <button onClick={onClick} {...restProps}>
      {children}
    </button>
  );
};

export default IconButton;
