import { useQuery } from 'react-query';
import { configUseQuery } from '../../helpers/queryConfig';
import { useEffect, useState } from 'react';
import api from '../../api/api';

const getPages = async () => {
  const response = await api
    .get('/pages')
    .then((response) => response)
    .catch((error) => console.log(error));
  return response.data;
};

export function useGetPages() {
  const { data: pageData, isLoading } = useQuery('pages', getPages, configUseQuery);
  const [news, setNews] = useState([]);
  const [articles, setArticles] = useState([]);
  const [headline, setHeadline] = useState([]);

  const data = pageData?.page;

  useEffect(() => {
    if (data?.length) {
      setNews(data?.filter((el) => el.type === 'news' && el.enabled));
      setArticles(data?.filter((el) => el.type === 'article' && el.enabled));
      setHeadline(data?.filter((el) => el.type === 'headline' && el.enabled));
    }
  }, [data]);

  return { news, articles, headline, isLoading };
}
