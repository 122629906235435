import { useMutation, useQueryClient } from 'react-query';
import api from '../../../api/api';

const updateData = async (people_list, id) => {
  const jsonList = JSON.stringify(people_list);

  const body = {
    people_list: jsonList,
  };

  await api
    .put('/registrations/' + id, { ...body })
    .then((res) => {
      console.log(res, 'put success');
    })
    .catch((error) => {
      console.log(error.response.status);
    });
};

export function usePutRegistrations(setOpen) {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      await updateData(data.people_list, data.id);
    },
    {
      onSuccess: () => {
        setTimeout(() => queryClient.invalidateQueries('registrations'), 1000);
        setOpen(false);
      },
      onError: () => {},
    }
  );
}
