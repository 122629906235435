import styled from 'styled-components';

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: normal;
`;

export const Wrapper = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;
export const StyledThead = styled.thead``;
export const StyledTbody = styled.tbody`
  margin-top: 10px;
  tr {
    margin-top: 10px;
  }
`;
export const StyledTr = styled.tr`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  margin-top: 10px;
  align-items: center;
  border: 2px solid rgba(235, 236, 240, 1);
  padding: 10px 20px;
  background: white;
  border-radius: 10px;
  z-index: 2;
`;
export const StyledTh = styled.th``;
export const StyledTd = styled.td`
  text-align: left;
  padding: 0 10px;
`;

export const IconSpace = styled.div`
  width: 30px;
  height: 30px;
`;
