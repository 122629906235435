import React from 'react';
import { useParams } from 'react-router-dom';
import Flex from '../../Components/Flex/Flex';
import ArticleHeader from './componentns/ArticleHeader';
import { ArticleCard } from './styled';
import { useTranslation } from 'react-i18next';
import { useGetPage } from '../../hooks/api/useGetPage';
import Markdown from 'markdown-to-jsx';
import { overrides } from '../../helpers/markdownOverrides/markdownOverrides';
import styled from 'styled-components';
import Loader from '../../Components/Loader';
import AppText from '../../Components/Text/AppText';

const MarkdownWrapper = styled.div`
  max-width: 60%;
  margin: 0 auto;
  text-align: left !important;
`;

const formatMarkdown = (contentMarkdown) => {
  return (
    contentMarkdown.replace(/(\r\n|\r|\n){2,}/g, (match) => {
      return '\n\n' + '&nbsp;\n\n'.repeat(match.length / 2 - 1);
    }) + '\n'
  );
};

const News = () => {
  const { id } = useParams();
  const { i18n } = useTranslation();
  const { pageData, isLoading } = useGetPage(id);

  if (isLoading) {
    return <Loader wrapper />;
  }

  if (!pageData?.title && !pageData?.subtitle && !isLoading) {
    return (
      <AppText
        text={'news.page.notFound'}
        color={'GrayBlue70'}
        fontWeight={'900'}
        textAlign={'center'}
        style={{ margin: '150px 0' }}
      />
    );
  }

  return (
    !isLoading && (
      <Flex flexDirection={'column'} gap={'30px'}>
        <ArticleHeader
          body={pageData?.subtitle}
          title={pageData?.title}
          date={pageData?.created_at}
          backgroundImage={pageData?.thumbnail}
        />
        <ArticleCard>
          <MarkdownWrapper>
            <Markdown
              options={{
                overrides: overrides,
              }}
            >
              {formatMarkdown(pageData?.body[i18n.language])}
            </Markdown>
          </MarkdownWrapper>
        </ArticleCard>
      </Flex>
    )
  );
};

export default News;
