import { useQuery } from 'react-query';
import { configUseQuery } from '../../helpers/queryConfig';
import api from '../../api/api';

const getPages = async ({ queryKey }) => {
  const id = queryKey[1];
  const response = await api
    .get(`/pages/${id}`)
    .then((response) => response)
    .catch((error) => console.log(error));
  return response.data;
};

export function useGetPage(id) {
  const { data: pageData, isLoading } = useQuery(['pages', id], getPages, configUseQuery);

  return { pageData: pageData?.page, isLoading };
}
