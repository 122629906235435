import React from 'react';
import Modal from '../../../Components/Modal';
import AppText from '../../../Components/Text/AppText';
import Flex from '../../../Components/Flex/Flex';
import AppButton from '../../../Components/Button/AppButton';
import { useDeleteVisit } from '../../../hooks/api/visits/useDeleteVisit';
import deleteIcon from '../../../assets/icons/delete_white.svg';
import goBack from '../../../assets/icons/arrow_right_white.svg';

const DeleteVisit = ({ registrationId, setRegistrations, setOpenDeleteModal }) => {
  const mutate = useDeleteVisit();

  const handleDelete = async () => {
    await mutate.mutateAsync(
      { id: registrationId, setRegistrations: setRegistrations },
      { onSuccess: () => setOpenDeleteModal(false) }
    );
  };

  const handleModalDelete = () => {
    setOpenDeleteModal(false);
  };

  return (
    <Modal>
      <AppText
        text={'visit.page.delete.title'}
        fontSize={window.innerWidth > 900 ? 'xl' : 'md'}
        color={'GrayBlue50'}
        fontWeight={'900'}
        textAlign={'center'}
      />
      <Flex gap={'85px'} justifyContent={'center'} sx={{ margin: '40px 0 0 0' }}>
        <AppButton
          text={window.innerWidth > 900 ? 'form.profile.cancel' : ''}
          size={window.innerWidth > 900 ? 'xl' : 'm'}
          icon={window.innerWidth > 900 ? '' : goBack}
          onClick={handleModalDelete}
        />
        <AppButton
          text={window.innerWidth > 900 ? 'visit.page.delete' : ''}
          size={window.innerWidth > 900 ? 'xl' : 'm'}
          icon={window.innerWidth > 900 ? '' : deleteIcon}
          styleType={'red'}
          onClick={handleDelete}
        />
      </Flex>
    </Modal>
  );
};

export default DeleteVisit;
