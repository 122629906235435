import React from 'react';
import Flex from '../../../../Components/Flex/Flex';
import Map from './components/Map';
import styled from 'styled-components';

const MapWrapper = styled(Flex)`
  padding: 0 45px 45px;
  display: flex;

  @media (max-width: 1200px) {
    display: block;
    padding: 0 40px 45px;
  }
`;

const DesktopWrapper = styled.div`
  display: block;
  width: 100%;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  display: none;
  width: 100%;

  @media (max-width: 1200px) {
    display: block;
  }
`;

const CompaniesMap = ({ points }) => {
  return (
    <MapWrapper>
      <MobileWrapper>
        <Map points={points} />
      </MobileWrapper>
      <DesktopWrapper>
        <Map points={points} />
      </DesktopWrapper>
    </MapWrapper>
  );
};

export default CompaniesMap;
