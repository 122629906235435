import styled from 'styled-components';

export const FormCard = styled.div`
  background: white;
  border-radius: 20px;
  padding: 40px 30px;
  z-index: 10000;
  min-width: 400px;
  max-width: 50%;
  margin: ${({ margin }) => margin || 'unset'};
  position: relative;
`;
