import React, { useContext, useEffect, useState } from 'react';
import AppIcon from '../../Components/Icon/AppIcon';
import store from '../../assets/icons/store.svg';
import AppText from '../../Components/Text/AppText';
import Flex from '../../Components/Flex/Flex';
import { Link, useNavigate, useParams } from 'react-router-dom';
import chevRight from '../../assets/icons/chevron_right.svg';
import AppButton from '../../Components/Button/AppButton';
import arrow_right from '../../assets/icons/arrow_right.svg';
import { useGetCompany } from '../../hooks/api/useGetCompany';
import location from '../../assets/icons/location_on.svg';
import accessible_forward from '../../assets/icons/accessible_forward.svg';
import school_dark from '../../assets/icons/school_dark.svg';
import open_link_white from '../../assets/icons/open_link_white.svg';
import Media from './components/Media/Media';
import {
  Card1,
  Card2,
  Card3,
  CardSingle1,
  CardSingle2,
  Logo,
  ImagesContainer,
  LogoWrapper,
  SideCard,
  ContactIconWrapper,
  TitleImageWrapper,
  ContentWrapper,
  ReturnWrapper,
  MobilityAndTrainingWrapper,
  VisitsDropDownWrapper,
} from './styled';
import { useTranslation } from 'react-i18next';
import TextWithIcon from './components/TextWithIcon';
import ActivitySectorItem from './components/ActivitySectorItem';
import Divider from '../../Components/Divider';
import contact_info from '../../assets/icons/contact_info.svg';
import local_phone from '../../assets/icons/local_phone.svg';
import mail from '../../assets/icons/mail.svg';
import ProgressBar from '../../Components/ProgressBar';
import VisitDateCards from './components/VisitDateCards';
import VisitDropdown from './components/VisitDropdown';
import VisitModal from './components/VisitModal';
import { mediaList, TextToParagraph, sortVisits } from './utils';
import Loader from '../../Components/Loader';
import { AuthContext } from '../../context/AuthContext';
import Dropdown from '../../Components/Dropdown';
import { EventContext } from '../../context/EventContext';
import LoginModal from '../../Components/LoginModal';
import { CompanyPageRedirectContext } from '../../context/CompanyPageRedirectContext';
import { MobileWrapper } from '../CompaniesPage/styled';
import { sortByNameField } from '../../helpers/helpers';
import Gallery from '../../Components/Gallery';
import VideoPlayer from '../../Components/VideoPlayer';

const CompanyPage = () => {
  const { id } = useParams();
  const { companyData, isLoading } = useGetCompany(id);
  const [token] = useContext(AuthContext);
  const [event] = useContext(EventContext);
  const [_, setCompanyId] = useContext(CompanyPageRedirectContext);
  const { i18n } = useTranslation();
  const [selectedVisit, setSelectedVisit] = useState();
  const [openVisit, setOpenVisit] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [sortedVisits, setSortedVisits] = useState([]);
  const [visitIndex, setVisitIndex] = useState(null);
  const [timeAndPlaces, setTimeAndPlaces] = useState({});
  const [selectedTimeLanguage, setSelectedTimeLanguage] = useState('');
  const [images, setImages] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [activitySectors, setActivitySectors] = useState([]);
  const [openGallery, setOpenGallery] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const nav = useNavigate();

  useEffect(() => {
    if (companyData?.jobs?.length > 0) {
      setJobs(sortByNameField(companyData?.jobs, i18n.language));
    }
  }, [companyData?.jobs]);

  useEffect(() => {
    if (companyData?.activitySectors?.length > 0) {
      setActivitySectors(sortByNameField(companyData?.activitySectors, i18n.language));
    }
  }, [companyData?.activitySectors]);

  useEffect(() => {
    if (companyData?.visits) {
      setSortedVisits(sortVisits(companyData?.visits));
    }
  }, [companyData?.visits]);

  useEffect(() => {
    setTimeAndPlaces({});
    setVisitIndex(null);
  }, [selectedVisit]);

  useEffect(() => {
    if (companyData?.media_collection) {
      const imagesArr = mediaList(companyData?.media_collection);
      if (imagesArr?.length > 0) {
        setImages(imagesArr);
      }
    }
  }, [companyData?.media_collection]);

  const lang = i18n.language;
  const visitDescriptionEnabled =
    visitIndex !== null &&
    Object.keys(timeAndPlaces).length > 0 &&
    selectedVisit?.description[visitIndex] &&
    selectedVisit?.description[visitIndex]?.de &&
    selectedVisit?.description[visitIndex]?.fr;
  const showContact =
    companyData?.reveal_contact &&
    (companyData?.contact_person_first_and_last_name || companyData?.phone_number || companyData?.email_address);

  const selectVisitTime = (option, index) => {
    setTimeAndPlaces(option.value);
    setVisitIndex(index);

    setSelectedTimeLanguage(selectedVisit.languages[index]);
      
  };

  const handleSelectVisitDate = (index) => {
    setSelectedVisit(sortedVisits[index]);
  };

  const handelOpenWebsite = () => {
    const companyUrl = companyData?.website[lang].startsWith('https://')
      ? companyData?.website[lang]
      : 'https://' + companyData?.website[lang];
    window.open(companyUrl, '_blank');
  };

  const handleClickVisit = () => {
    if (token) {
      setOpenVisit(true);
    } else {
      setCompanyId(companyData.id);
      setOpenLoginModal(true);
    }
  };

  const handleOpenGallery = (index) => {
    setStartIndex(index);
    setOpenGallery(true);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div style={{ padding: window.innerWidth > 600 ? '20px 45px 60px 45px' : '0px 0 60px 0' }}>
      {!isLoading && companyData && (
        <>
          <Flex justifyContent={'space-between'}>
            <Flex alignItems={'center'} gap={'20px'} sx={{ 'padding-bottom': '20px' }}>
              <Link to={'/company'}>
                <Flex alignItems={'center'} gap={'20px'}>
                  <AppIcon icon={store} dimensions={'30px'} />
                  <MobileWrapper>
                    <AppText text={'companyPage.breadcrumb'} color={'GrayBlue70'} fontWeight={'900'} />
                  </MobileWrapper>
                </Flex>
              </Link>
              <AppIcon icon={chevRight} dimensions={'30px'} />
              <AppText text={companyData?.name[lang]} color={'GrayBlue50'} fontWeight={'900'} />
            </Flex>
            <ReturnWrapper>
              <AppButton
                text={'company.page.return'}
                styleType={'default'}
                icon={arrow_right}
                size={'l'}
                onClick={() => nav('/company')}
              />
            </ReturnWrapper>
          </Flex>
          <Media companyData={companyData} />

          <VisitsDropDownWrapper>
            <SideCard>
              <Dropdown label={'company.page.visit.sign'} color={'GrayBlue50'} fontWeight={'900'} fontSize={'as'}>
                <Divider margin={'30px 0 20px 0'} />
                {companyData?.visits?.length ? (
                  <>
                    <VisitDateCards visits={sortedVisits} onClick={handleSelectVisitDate} />
                  </>
                ) : (
                  <AppText text={'company.page.visit.novisits'} color={'DarkRed2'} fontWeight={'900'} fontSize={'as'} />
                )}
                {selectedVisit ? <VisitDropdown visit={selectedVisit} onClick={selectVisitTime} /> : null}
                {Object.keys(timeAndPlaces).length && event?.show_filling_gauge ? (
                  <ProgressBar
                    min={timeAndPlaces?.nPlaces - timeAndPlaces?.nTakenPlaces || 0}
                    max={timeAndPlaces?.nPlaces || 0}
                  />
                ) : null}
                {!selectedVisit?.registration_open &&
                  Object.keys(timeAndPlaces).length > 0 &&
                  timeAndPlaces?.nPlaces - timeAndPlaces?.nTakenPlaces > 0 && (
                    <AppText
                      color={'GrayBlue70'}
                      fontWeight={'900'}
                      style={{ marginTop: '10px' }}
                      text={'companyPage.registration.closed'}
                    />
                  )}
                {selectedVisit &&
                  Object.keys(timeAndPlaces).length &&
                  timeAndPlaces?.nPlaces - timeAndPlaces?.nTakenPlaces > 0 &&
                  selectedVisit.registration_open && (
                    <AppButton
                      text={'companyPage.showModal'}
                      onClick={handleClickVisit}
                      styles={{ margin: '20px auto 0' }}
                    />
                  )}
                {companyData?.visits?.length > 0 &&
                  Object.keys(timeAndPlaces).length > 0 &&
                  timeAndPlaces?.nPlaces - timeAndPlaces?.nTakenPlaces <= 0 && (
                    <AppText
                      text={'company.page.visit.noplaces'}
                      style={{ margin: '10px 0 0 0' }}
                      color={'DarkRed2'}
                      fontWeight={'900'}
                      fontSize={'as'}
                    />
                  )}
                {visitDescriptionEnabled && (
                  <>
                    <Divider margin={'30px 0 20px 0'} />
                    <AppText text={'company.page.visit.body'} color={'GrayBlue50'} fontWeight={'900'} fontSize={'as'} />
                    {TextToParagraph(
                      visitIndex !== null && selectedVisit?.description[visitIndex]?.fr
                        ? selectedVisit?.description[visitIndex][i18n.language]
                        : selectedVisit?.description[visitIndex]
                    )}
                  </>
                )}
              </Dropdown>
            </SideCard>
          </VisitsDropDownWrapper>

          <ContentWrapper>
            <div style={{ flexGrow: '3', flexBasis: '0' }}>
              <TitleImageWrapper justifyContent={'space-between'}>
                {companyData?.logo && (
                  <Card2 mobileHide={1}>
                    <LogoWrapper>
                      <Logo src={companyData?.logo} alt="" />
                    </LogoWrapper>
                  </Card2>
                )}
                <Card1>
                  <AppText text={companyData?.name[lang]} color={'GrayBlue50'} fontWeight={'900'} fontSize={'slg'} />
                  <TextWithIcon text={companyData?.full_address} icon={location} margin={'15px 0 0 0'} />
                  <MobilityAndTrainingWrapper sx={{ margin: '30px 0 0 0' }} gap={'56px'}>
                    {!!companyData?.has_support_for_limited_mobility && (
                      <TextWithIcon text={'company.page.access'} icon={accessible_forward} color={'GrayBlue80'} />
                    )}
                    {!!companyData?.is_trainer_company && (
                      <TextWithIcon text={'companyType.training'} icon={school_dark} color={'GrayBlue80'} />
                    )}
                  </MobilityAndTrainingWrapper>
                  <AppButton
                    text={'company.page.site'}
                    icon={open_link_white}
                    iconPosition={'right'}
                    onClick={handelOpenWebsite}
                    styles={{ margin: '30px 0 0 0' }}
                  />
                </Card1>
                {companyData?.logo && (
                  <Card2 mobileHide={2}>
                    <LogoWrapper>
                      <Logo src={companyData?.logo} alt="" />
                    </LogoWrapper>
                  </Card2>
                )}
              </TitleImageWrapper>
              {companyData?.description[i18n.language]?.length > 0 && (
                <Card3>
                  <AppText text={'company.page.about'} color={'GrayBlue50'} fontWeight={'900'} fontSize={'as'} />
                  {TextToParagraph(companyData?.description[i18n.language])}
                </Card3>
              )}

              {companyData?.media_collection.length > 0 && (
                <>
                  <CardSingle1>
                    <AppText text={'company.page.media'} color={'GrayBlue50'} fontWeight={'900'} fontSize={'as'} />
                  </CardSingle1>
                  <CardSingle2>
                    <ImagesContainer>
                      {companyData?.media_collection.map((media, index) => {
                        return media.original_url.endsWith('.mp4') ? (
                          <div onClick={() => handleOpenGallery(index)}>
                            <VideoPlayer mediaUrl={media.original_url} cropped />
                          </div>
                        ) : (
                          <img
                            key={media.id}
                            src={media?.original_url}
                            onClick={() => handleOpenGallery(index)}
                            alt="Image"
                          />
                        );
                      })}
                    </ImagesContainer>
                  </CardSingle2>
                </>
              )}
              {jobs?.length > 0 && (
                <>
                  <CardSingle1>
                    <AppText text={'company.page.jobs'} color={'GrayBlue50'} fontWeight={'900'} fontSize={'as'} />
                  </CardSingle1>
                  <CardSingle2>
                    <Flex gap={'30px'} flexWrap={'wrap'}>
                      {jobs.map((element) => (
                        <ActivitySectorItem text={element.name[lang]} />
                      ))}
                    </Flex>
                  </CardSingle2>
                </>
              )}
              {activitySectors?.length > 0 && (
                <>
                  <CardSingle1>
                    <AppText text={'company.page.activity'} color={'GrayBlue50'} fontWeight={'900'} fontSize={'as'} />
                  </CardSingle1>
                  <CardSingle2>
                    <Flex gap={'30px'} flexWrap={'wrap'}>
                      {activitySectors.map((element) => (
                        <ActivitySectorItem text={element.name[lang]} />
                      ))}
                    </Flex>
                  </CardSingle2>
                </>
              )}
            </div>
            <div style={{ flexGrow: '1', flexBasis: '0' }}>
              <SideCard mobileHide={true}>
                <AppText text={'company.page.visit.sign'} color={'GrayBlue50'} fontWeight={'900'} fontSize={'as'} />
                <Divider margin={'30px 0 20px 0'} />
                {companyData?.visits?.length > 0 ? (
                  <>
                    <VisitDateCards visits={sortedVisits} onClick={handleSelectVisitDate} />
                  </>
                ) : (
                  <AppText text={'company.page.visit.novisits'} color={'DarkRed2'} fontWeight={'900'} fontSize={'as'} />
                )}
                {selectedVisit ? <VisitDropdown visit={selectedVisit} onClick={selectVisitTime} /> : null}
                {Object.keys(timeAndPlaces).length && event?.show_filling_gauge ? (
                  <ProgressBar
                    min={timeAndPlaces?.nPlaces - timeAndPlaces?.nTakenPlaces || 0}
                    max={timeAndPlaces?.nPlaces || 0}
                  />
                ) : null}
                {!selectedVisit?.registration_open &&
                  Object.keys(timeAndPlaces).length > 0 &&
                  timeAndPlaces?.nPlaces - timeAndPlaces?.nTakenPlaces > 0 && (
                    <AppText
                      color={'GrayBlue70'}
                      fontWeight={'900'}
                      style={{ marginTop: '10px' }}
                      text={'companyPage.registration.closed'}
                    />
                  )}
                {selectedVisit &&
                  Object.keys(timeAndPlaces).length > 0 &&
                  timeAndPlaces?.nPlaces - timeAndPlaces?.nTakenPlaces > 0 &&
                  selectedVisit.registration_open && (
                    <AppButton
                      text={'companyPage.showModal'}
                      onClick={handleClickVisit}
                      styles={{ margin: '20px auto 0' }}
                    />
                  )}
                {companyData?.visits?.length > 0 &&
                  Object.keys(timeAndPlaces).length > 0 &&
                  timeAndPlaces?.nPlaces - timeAndPlaces?.nTakenPlaces <= 0 && (
                    <AppText
                      text={'company.page.visit.noplaces'}
                      style={{ margin: '10px 0 0 0' }}
                      color={'DarkRed2'}
                      fontWeight={'900'}
                      fontSize={'as'}
                    />
                  )}
                {visitDescriptionEnabled && (
                  <>
                    <Divider margin={'30px 0 20px 0'} />
                    <AppText text={'company.page.visit.body'} color={'GrayBlue50'} fontWeight={'900'} fontSize={'as'} />
                    {TextToParagraph(
                      visitIndex !== null && selectedVisit?.description[visitIndex]?.fr
                        ? selectedVisit?.description[visitIndex][i18n.language]
                        : selectedVisit?.description[visitIndex]
                    )}
                  </>
                )}
              </SideCard>

              {showContact && (
                <SideCard margin={'30px 0 0 0'}>
                  <AppText
                    text={'company.page.visit.contact'}
                    color={'GrayBlue50'}
                    fontWeight={'900'}
                    fontSize={'as'}
                  />
                  <Divider margin={'30px 0 25px 0'} />
                  <Flex gap={'40px'}>
                    {companyData?.contact_person_first_and_last_name && (
                      <>
                        <div>
                          <ContactIconWrapper>
                            <AppIcon icon={contact_info} styles={{ position: 'absolute', top: '8px', left: '8px' }} />
                          </ContactIconWrapper>
                        </div>
                        <Flex flexDirection={'column'} justifyContent={'space-between'}>
                          <AppText
                            color={'GrayBlue50'}
                            fontWeight={'900'}
                            fontSize={'as'}
                            text={companyData?.contact_person_first_and_last_name[lang]}
                          />
                          <AppText
                            text={'company.page.visit.job'}
                            color={'GrayBlue70'}
                            fontWeight={'900'}
                            fontSize={'as'}
                          />
                        </Flex>
                        <Divider margin={'30px 0 25px 0'} />
                      </>
                    )}
                  </Flex>

                  {companyData?.phone_number && (
                    <TextWithIcon icon={local_phone} text={companyData?.phone_number[lang]} />
                  )}
                  {companyData?.email_address && <TextWithIcon icon={mail} text={companyData?.email_address[lang]} />}
                </SideCard>
              )}
            </div>
          </ContentWrapper>
        </>
      )}
      {openVisit && (
        <VisitModal
          visit={selectedVisit}
          visitIndex={visitIndex}
          timeAndPlaces={timeAndPlaces}
          selectedTimeLanguage={selectedTimeLanguage}
          companyData={companyData}
          setOpenVisit={setOpenVisit}
        />
      )}
      {openLoginModal && <LoginModal setOpenLoginModal={setOpenLoginModal} />}
      {openGallery && (
        <Gallery media={companyData?.media_collection} setIsOpen={setOpenGallery} startIndex={startIndex} />
      )}
    </div>
  );
};

export default CompanyPage;
