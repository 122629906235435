import styled from 'styled-components';

export const PopUpLoading = styled.div`
  border: 2px solid #e4e6eb;
  box-shadow: 0 2px 5px 0 #26334d08;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  border-radius: 10px;
  flex: 1 0 0;
  margin-right: 30px;
  position: relative;
  background: white;
  width: 100%;
`;

export const ToolTip = styled.div`
  padding: 22px 15px;
  background: white;
  border-radius: 10px;
  margin-bottom: 20px;
  z-index: 1000;
`;

export const ToolTipImage = styled.img`
  max-width: 130px;
  width: auto;
  height: 100%;
`;
