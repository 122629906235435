import React from 'react';
import Flex from '../../../../../Components/Flex/Flex';
import styled from 'styled-components';
import AppText from '../../../../../Components/Text/AppText';

const Number = styled.div`
  border-radius: 50%;
  padding: 12px;
  height: 50px;
  width: 50px;
  background: ${({ isActive }) => (isActive ? '#3361ff' : 'white')};
  border: ${({ isActive }) => (isActive ? '2px solid #3361ff' : '2px solid #EBECF0')};
`;

const StepsWrapper = styled(Flex)`
  gap: 20px;
  align-items: center;
`;

const Steps = ({ steps, currentStep }) => {
  return (
    <div style={{ marginTop: '35px' }}>
      <StepsWrapper gap={'30px'} justifyContent={'center'}>
        {steps.map((step) => {
          const isActive = step.number === currentStep;
          return (
            <Flex
              gap={'20px'}
              key={step.number}
              alignItems={'center'}
              sx={{ display: window.innerWidth > 850 ? 'flex' : 'block' }}
            >
              <Number isActive={isActive}>
                <AppText
                  text={step.number}
                  fontWeight={'900'}
                  fontSize={'xs'}
                  textAlign={'center'}
                  color={isActive ? 'White' : 'GrayBlue50'}
                />
              </Number>
              <AppText
                text={window.innerWidth > 850 ? step.name : ''}
                fontWeight={'900'}
                fontSize={'sm'}
                color={isActive ? 'Blue' : 'GrayBlue50'}
                textAlign={'center'}
              />
            </Flex>
          );
        })}
      </StepsWrapper>
    </div>
  );
};

export default Steps;
