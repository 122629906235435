import React, { useContext } from 'react';
import search from '../../../assets/icons/search.svg';
import arrow_topbar_input from '../../../assets/icons/arrow_topbar_input.svg';
import Input from '../../Form/Input';
import { SearchContext } from '../../../context/SearchContext';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

const InputWrapper = styled.div`
  flex-grow: 1;
  max-width: 300px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const TopbarInput = () => {
  const [searchValue, setSearchValue] = useContext(SearchContext);
  const location = useLocation();
  const nav = useNavigate();

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  const onSearch = () => {
    if (location.pathname !== '/company') {
      nav('/company');
    }
  };

  return (
    <InputWrapper style={{ flexGrow: 1, maxWidth: '300px' }}>
      <Input
        size={'small'}
        placeholder={'companiesList.search'}
        startIcon={search}
        endIcon={arrow_topbar_input}
        onChange={handleChange}
        value={searchValue}
        onClickEndIcon={onSearch}
        onKeyDown={handleEnterPress}
        debounced
        topStartIcon={'8px'}
      />
    </InputWrapper>
  );
};

export default TopbarInput;
