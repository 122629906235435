import { useMutation } from 'react-query';
import api from '../../../api/api';

async function deleteVisit(id, setRegistrations) {
  return await api
    .delete('/registrations/' + id)
    .then((response) => {
      setRegistrations((prevState) => prevState.filter((registration) => registration.id !== id));
      return response;
    })
    .catch((error) => console.log(error));
}

export function useDeleteVisit() {
  return useMutation(
    async (data) => {
      await deleteVisit(data.id, data.setRegistrations);
    },
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );
}
