import styled from 'styled-components';

export const ColoredCards = ['#E62E7B', '#FF6633', '#FFCB33', '#8833FF', '#3361FF', '#14B8A6'];

export const GridContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const Wrapper = styled.div`
  margin: 30px 0;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(auto-fill, minmax(${({ articles }) => getGridItemWidth(articles)}, 1fr));
  gap: 30px;
  align-items: flex-start;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(${({ articles }) => getGridItemWidth(Math.round(articles / 2))}, 1fr)
    );
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(${({ articles }) => getGridItemWidth(Math.round(articles / 3))}, 1fr)
    );
  }
`;

const getGridItemWidth = (articles) => {
  switch (articles) {
    case 1:
      return '100%';
    case 2:
      return 'calc(50% - 30px)';
    case 3:
      return 'calc(33% - 30px)';
    case 4:
      return 'calc(25% - 30px)';
    case 5:
      return 'calc(20% - 30px)';
    case 6:
      return 'calc(16% - 30px)';
    default:
      return '200px';
  }
};
