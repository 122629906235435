import React, { useContext, useEffect, useState } from 'react';
import school_white from '../../../../assets/icons/school_white.svg';
import favorite from '../../../../assets/icons/favorite.svg';
import share_white from '../../../../assets/icons/share_white.svg';
import AppText from '../../../../Components/Text/AppText';
import AppIcon from '../../../../Components/Icon/AppIcon';
import Flex from '../../../../Components/Flex/Flex';
import AppButton from '../../../../Components/Button/AppButton';
import VideoPlayer from '../../../../Components/VideoPlayer';
import { MediaButtonsWrapper, StyledImage, StyledImageWrapper, StyledVideoWrapper, TrainingWrapper } from './styled';
import { useMutateFavorites } from '../../../../hooks/api/favorites/useMutateFavorites';
import { CopyToClipboard } from 'react-copy-to-clipboard/src';
import Notification from '../../../../Components/Notification';
import { AuthContext } from '../../../../context/AuthContext';

const Media = ({ companyData }) => {
  const [isFavorite, setIsFavorite] = useState(companyData?.is_favorite);
  const [showNotification, setShowNotification] = useState(false);
  const mediaUrl = companyData?.media_collection[0]?.original_url;
  const [token] = useContext(AuthContext);
  const mutate = useMutateFavorites();

  const handleAddFavorites = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsFavorite((prevState) => !prevState);
    mutate.mutateAsync({ isActive: isFavorite, companyId: companyData.id });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowNotification(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [showNotification]);

  useEffect(() => {}, []);

  const handleShare = () => {
    setShowNotification(true);
  };

  if (!mediaUrl) {
    return null;
  }

  return mediaUrl.endsWith('mp4') ? (
    <StyledVideoWrapper>
      <VideoPlayer mediaUrl={mediaUrl} />
      {!!companyData.is_trainer_company && (
        <TrainingWrapper>
          <AppIcon icon={school_white} />
          <AppText text={'companyType.training'} color={'White'} fontSize={'xs'} fontWeight={'900'} />
        </TrainingWrapper>
      )}
      <MediaButtonsWrapper>
        <Flex gap={'10px'}>
          {token && (
            <AppButton
              text={window.innerWidth > 700 ? 'company.page.media.fav' : ''}
              styleType={isFavorite ? 'dark_pink' : 'black'}
              icon={favorite}
              onClick={handleAddFavorites}
            />
          )}
          <CopyToClipboard text={window.location.href}>
            <AppButton
              text={window.innerWidth > 700 ? 'company.page.media.share' : ''}
              styleType={'black'}
              data-clipboard-target="#copyLink"
              icon={share_white}
              onClick={handleShare}
            />
          </CopyToClipboard>
        </Flex>
      </MediaButtonsWrapper>
      <Notification text={'companyPage.share.alert'} isVisible={showNotification} />
    </StyledVideoWrapper>
  ) : (
    <StyledImageWrapper>
      <div style={{ background: 'rbga(1,1,1,0.2)' }}>
        <StyledImage src={mediaUrl} id={'your-image-id'} />
      </div>
      {!!companyData.is_trainer_company && (
        <TrainingWrapper>
          <AppIcon icon={school_white} />
          <AppText text={'companyType.training'} color={'White'} fontSize={'xs'} fontWeight={'900'} />
        </TrainingWrapper>
      )}
      <MediaButtonsWrapper>
        <Flex gap={'10px'}>
          {token && (
            <AppButton
              text={window.innerWidth > 700 ? 'company.page.media.fav' : ''}
              styleType={isFavorite ? 'dark_pink' : 'black'}
              icon={favorite}
              onClick={handleAddFavorites}
            />
          )}
          <CopyToClipboard text={window.location.href}>
            <AppButton
              text={window.innerWidth > 700 ? 'company.page.media.share' : ''}
              styleType={'black'}
              data-clipboard-target="#copyLink"
              icon={share_white}
              onClick={handleShare}
            />
          </CopyToClipboard>
        </Flex>
      </MediaButtonsWrapper>
      <Notification text={'companyPage.share.alert'} isVisible={showNotification} />
    </StyledImageWrapper>
  );
};

export default Media;
