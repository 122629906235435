const daysDE = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
const monthsDE = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

const daysFR = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
const monthsFR = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

export const GermanTranslations = {
  localize: {
    day: (n) => daysDE[n],
    month: (n) => monthsDE[n],
  },
  formatLong: {
    date: () => 'MM/dd/yyyy',
  },
};

export const FrenchTranslations = {
  localize: {
    day: (n) => daysFR[n],
    month: (n) => monthsFR[n],
  },
  formatLong: {
    date: () => 'MM/dd/yyyy',
  },
};
