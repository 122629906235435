import { useLocation } from 'react-router-dom';
import './css/App.scss';
import './css/Base.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import MobileStandByPage from './Pages/MobileStandBy';
import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import ReactGA from 'react-ga';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useGetIsUnityContainer } from './hooks/useGetIsUnityContainer';
import AppRouter from './router/AppRouter';
import { MyAuthProvider } from './context/AuthContext';
import { MyEventProvider } from './context/EventContext';
import { MySearchProvider } from './context/SearchContext';
import { MyAlertProvider } from './context/AlertContext';
import Alert from './Components/Alert';
import ScrollToTop from './Components/ScrollToTop';
import { MyCompanyPageRedirectProvider } from './context/CompanyPageRedirectContext';

mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN;

function App() {
  ReactGA.initialize('G-NS5ZS6GZVG');
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const { i18n } = useTranslation();
  const { isUnity } = useGetIsUnityContainer();

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage !== null) {
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  if (isUnity) {
    return <MobileStandByPage />;
  }

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <MyAuthProvider>
          <MyEventProvider>
            <MySearchProvider>
              <MyAlertProvider>
                <MyCompanyPageRedirectProvider>
                  <Alert />
                  <ScrollToTop />
                  <AppRouter />
                </MyCompanyPageRedirectProvider>
              </MyAlertProvider>
            </MySearchProvider>
          </MyEventProvider>
        </MyAuthProvider>
      </I18nextProvider>
    </>
  );
}
export default App;
