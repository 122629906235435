import styled from 'styled-components';
import MyLink from './MyLink';

const MyHeading = styled.h1`
  color: #4d5e80;
  font-size: 13px;
  text-align: left;
  font-family: 'Fopen Midi Black', sans-serif;
`;

const MySubHeading = styled.h2`
  color: #4d5e80;
  font-size: 13px;
  text-align: left;
  font-family: 'Fopen Midi Black', sans-serif;
`;

const MyParagraph = styled.p`
  color: #4d5e80;
  font-size: 13px;
  text-align: left;
  font-family: 'Fopen Midi Regular', sans-serif;
`;

const MyBoldText = styled.strong`
  color: #737f99;
  font-size: 13px;
  text-align: left;
  font-family: 'Fopen Midi Black', sans-serif;
`;

const MyItalicText = styled.em`
  color: #4d5e80;
  font-size: 13px;
  text-align: left;
  font-family: 'Fopen Midi Regular', sans-serif;
`;

const MyOrderedList = styled.ol`
  color: #4d5e80;
  font-size: 13px;
  text-align: left;
  font-family: 'Fopen Midi Black', sans-serif;
`;

const MyUnorderedList = styled.ul`
  list-style-type: none;
  li {
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -50px;
      top: 10px;
      width: 20px;
      height: 2px;
      background: #c3cad9;
      border-radius: 1px;
    }
  }
`;

const MyListItem = styled.li`
  text-align: left;
  color: #737f99;
  font-size: 13px;
  font-family: 'Fopen Midi Black', sans-serif;
`;

const MyImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const overrides = {
  h1: MyHeading,
  h2: MySubHeading,
  p: MyParagraph,
  strong: MyBoldText,
  em: MyItalicText,
  ol: MyOrderedList,
  ul: MyUnorderedList,
  li: MyListItem,
  a: MyLink,
  img: MyImage,
};
