import { useMutation } from 'react-query';
import api from '../../../api/api';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AlertContext } from '../../../context/AlertContext';

export function usePostVisits() {
  const nav = useNavigate();
  const [_, setAlert] = useContext(AlertContext);

  const handleConfirm = async (selectedVisits, isCheck, inscriptionData, interests, custom_interest) => {
    const user = JSON.parse(localStorage.getItem('userdata'));
    const nameParts = user.name.split(/\s+/).filter(Boolean);
    const firstName = nameParts[0];
    const lastName = nameParts[nameParts.length - 1];

    const userData = {
      firstName: firstName,
      lastName: lastName,
      has_limited_mobility: 0,
      date_of_birth: user.date_of_birth,
    };

    let people_list = [];

    if (isCheck) {
      people_list.push(userData);
    }

    for (let person of inscriptionData) {
      people_list.push({
        firstName: person.firstName,
        lastName: person.lastName,
        date_of_birth:
          parseInt(person.date_of_birth) < 1900 ? '1900-01-01' : person.date_of_birth.toString() + '-01-01',
        has_limited_mobility: 0,
      });
    }

    const body = {
      visit_id: selectedVisits.id,
      people_list: JSON.stringify(people_list),
      interests: interests,
      custom_interest: custom_interest,
    };

    const postResponse = await api
      .post('/registrations', { ...body })
      .then((response) => response)
      .catch((error) => console.log(error));
    if (navigator.userAgent.includes('Unity')) {
      window.location.href = 'uniwebview://registrationsUpdated';
    }
    return postResponse;
  };

  return useMutation(
    async (data) => {
      await handleConfirm(
        data.selectedVisits,
        data.isCheck,
        data.inscriptionData,
        data.interests,
        data.custom_interest
      );
    },
    {
      onSuccess: () => {
        setAlert({ type: 'success', message: 'companyPage.visit.success' });
        nav('/my-visits');
      },
      onError: (e) => {
        if (e.response.status === 409) {
          setAlert({ type: 'error', message: 'companiesPage.visit.sameVisitError' });
        }
      },
    }
  );
}
