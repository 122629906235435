import styled from 'styled-components';

export const FooterContentWrapper = styled.div`
  padding-top: 50px;

  @media (max-width: 680px) {
    padding-bottom: 50px;
  }
`;

export const MailSocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: unset;
  }
`;

export const IconButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 10px;
  border: 2px #ebecf0 solid;
  box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  width: 50px;
  height: 50px;
  background: white;
`;
