import React from 'react';
import { Link } from 'react-router-dom';
import AppText from '../../Text/AppText';

const FooterTextLink = ({ link, text, blank }) => {
  return blank ? (
    <a href={link} target={'_blank'}>
      <AppText text={text} className={'b-700'} fontSize={'sm'} color={'GrayBlue70'} />
    </a>
  ) : (
    <Link to={link}>
      <AppText text={text} className={'b-700'} fontSize={'sm'} color={'GrayBlue70'} />
    </Link>
  );
};

export default FooterTextLink;
