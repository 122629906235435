import AppText from '../../Components/Text/AppText';
import '../Login/login.scss';
import { FormCard } from '../../Components/Form/FormCard';
import Input from '../../Components/Form/Input';
import withForm from '../../hoc/withForm';
import AppButton from '../../Components/Button/AppButton';
import axios from 'axios';
import React, { useState } from 'react';
import { StyledForm } from '../../Components/Form/styled';
import FormError from '../../Components/Form/FormError';
import Modal from '../../Components/Modal';
import { useNavigate } from 'react-router-dom';
import AppIcon from '../../Components/Icon/AppIcon';
import keyboard_arrow_left from '../../assets/icons/keyboard_arrow_left.svg';
import { BackButtonWrapper } from '../Profile/styled';
import { useTranslation } from 'react-i18next';

const handlePasswordReset = (formData, setResponseError, setShowModal, lang) => {
  const body = {
    email: formData.email,
  };

  const requestOptions = {
    method: 'post',
    url: process.env.REACT_APP_API_URL + '/password/create',
    headers: { 'Content-Type': 'application/json' },
    params: {
      lang,
    },
    withCredentials: true,
    data: JSON.stringify(body),
  };

  axios(requestOptions.url, requestOptions)
    .then(() => {
      setShowModal(true);
    })
    .catch((error) => {
      console.log(error);
      setResponseError(error.response.data.message);
    });
};

const validate = (formData) => {
  const errors = {};
  if (!/^[\w-]+(\.[\w-]+)*\+?[\w-]+@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)) {
    errors.email = 'validation.email.email';
  }
  return errors;
};

const PasswordReset = ({ errors, handleChange, handleSubmit }) => {
  const [responseError, setResponseError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="login-container login-box">
      <div className="form-section">
        <FormCard>
          <div className={'title'}>
            <AppText
              text={'passwordReset.title'}
              color={'GrayBlue50'}
              fontSize={'lg'}
              fontWeight={'900'}
              textAlign={'center'}
            />
            <AppText text={'passwordReset.body'} color={'GrayBlue55'} fontSize={'sm'} textAlign={'center'} />
          </div>
          <StyledForm
            onSubmit={(e) => {
              handleSubmit(e, setResponseError, setShowModal, i18n.language);
            }}
            style={{ margin: '0 auto' }}
          >
            <Input
              title={'login.placeholder.email'}
              margin={'0 0 20px 0'}
              size={'large'}
              name={'email'}
              error={errors.email}
              onChange={handleChange}
            />
            <FormError message={responseError} margin={'0 0 20px 30px'} position={'left'} />
            <AppButton type={'submit'} text={'registration.terms.next'} size={'xl'} />
          </StyledForm>
        </FormCard>
        {showModal && (
          <Modal width={'80%'}>
            <AppText
              text={'passwordReset.modal.title'}
              color={'GrayBlue50'}
              fontSize={'lg'}
              fontWeight={'900'}
              textAlign={'center'}
            />
            <AppText text={'passwordReset.modal.body'} color={'GrayBlue55'} fontSize={'sm'} textAlign={'center'} />
          </Modal>
        )}
        <BackButtonWrapper onClick={handleGoBack}>
          <AppIcon icon={keyboard_arrow_left} />
        </BackButtonWrapper>
      </div>
    </div>
  );
};

export default withForm(PasswordReset, { email: '' }, handlePasswordReset, validate);
