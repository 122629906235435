import React from 'react';
import styled from 'styled-components';
import AppText from '../../Components/Text/AppText';
import AppButton from '../../Components/Button/AppButton';
import background from '../../assets/img/large_background.png';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  position: relative;
  background: #f7f8fa;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    min-height: 100vh;
    width: 100vw;
    background-image: url(${({ background }) => background});
  }
`;

const NotFoundPage = () => {
  const nav = useNavigate();

  return (
    <Wrapper background={background}>
      <div style={{ zIndex: 2 }}>
        <AppText text={'404'} textAlign={'center'} color={'GrayBlue50'} fontSize={'xl'} fontWeight={'900'} />
        <AppText text={'notfound.title'} textAlign={'center'} color={'GrayBlue50'} fontSize={'md'} fontWeight={'900'} />
        <div style={{ marginTop: '40px' }}>
          <AppText
            text={'notfound.body'}
            textAlign={'center'}
            color={'GrayBlue70'}
            fontSize={'md'}
            fontWeight={'900'}
          />
          <AppButton text={'notfound.return'} onClick={() => nav('/')} styles={{ margin: '20px auto' }} />
        </div>
      </div>
    </Wrapper>
  );
};

export default NotFoundPage;
