import WebAppHeader from './Header/WebAppHeader';
import TopBar from './Topbar/Topbar';
import './layout.scss';
import Footer from './Footer/Footer';
import styled from 'styled-components';
import AppButton from './Button/AppButton';
import ArrowRight from '../assets/icons/ArrowRight.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const StyledMain = styled.main`
  margin-top: 100px;
  padding: ${({ enablePadding }) => (enablePadding ? '20px 45px 50px' : 'unset')};
`;

const CompaniesButtonWrapper = styled.div`
  position: fixed;
  display: none;
  bottom: 20px;
  left: 50%;
  z-index: 10000000;
  transform: translate(-50%, 0);
  text-wrap: nowrap;
  @media (max-width: 680px) {
    display: block;
  }
`;

const Layout = ({
  enablePadding = true,
  children,
  isHeader,
  showFooter = true,
  isHeaderConcour,
  breadCrumbArray,
  hideHeader,
  title,
  subtitle,
  isCompanyPage = false,
  breadCrumbIcon,
}) => {
  const navigate = useNavigate();
  const handleCampanyRedirect = () => {
    navigate('/company');
  };

  return (
    <>
      <div className="content">
        <header>
          <TopBar isCompanyPage={isCompanyPage} />
        </header>
        <StyledMain enablePadding={enablePadding}>
          {!hideHeader && (
            <WebAppHeader
              isHeaderConcour={isHeaderConcour}
              breadCrumbArray={breadCrumbArray}
              breadCrumbIcon={breadCrumbIcon}
              isHeader={isHeader}
              title={title}
              subtitle={subtitle}
            />
          )}
          {children}
        </StyledMain>
        {!isCompanyPage && (
          <CompaniesButtonWrapper>
            <AppButton
              type="primary"
              text={'nav.discoverCompanies'}
              fontWeight={'900'}
              icon={ArrowRight}
              onClick={handleCampanyRedirect}
            />
          </CompaniesButtonWrapper>
        )}

        {showFooter && <Footer />}
      </div>
    </>
  );
};

export default Layout;
