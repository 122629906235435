import React, { useState } from 'react';
import styled from 'styled-components';
import AppText from '../../../Components/Text/AppText';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  border: 2px solid #ebecf0;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  max-width: 75px;

  background: ${({ isActive }) => (isActive ? '#3361FF0D' : 'white')};
`;

function getDayFromDate(dateString, lang) {
  const date = new Date(dateString);
  const day = date.getDate();
  const daysOfWeek =
    lang === 'fr'
      ? ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
      : ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'];
  return { day, dayOfWeek: daysOfWeek[date.getDay()] + '.' };
}

const GridDateCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
  gap: 15px;
  justify-content: center;
  align-content: start;
  max-width: calc(75px * 4 + 15px * 2);
  margin: 0 auto;
`;

const VisitDateCards = ({ visits, onClick }) => {
  const [activeButtonIndex, setActiveButtonIndex] = useState(null);
  const { i18n } = useTranslation();

  const handleClick = (index) => {
    setActiveButtonIndex(index);
    onClick(index);
  };

  return (
    <GridDateCardsWrapper>
      {visits.map((visit, index) => {
        const isActive = index === activeButtonIndex;
        const { day, dayOfWeek } = getDayFromDate(visit.visit_date, i18n.language);

        return (
          <Wrapper
            isActive={isActive}
            onClick={() => {
              handleClick(index);
            }}
          >
            <AppText text={day} color={isActive ? 'Blue' : 'GrayBlue50'} fontWeight={'900'} textAlign={'center'} />
            <AppText
              text={dayOfWeek}
              color={isActive ? 'Blue' : 'GrayBlue70'}
              fontWeight={'900'}
              textAlign={'center'}
            />
          </Wrapper>
        );
      })}
    </GridDateCardsWrapper>
  );
};

export default VisitDateCards;
