import React from 'react';
import styled, { keyframes } from 'styled-components';
import AppText from '../Text/AppText';
import Flex from '../Flex/Flex';

const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0px);
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-100%);
  }
`;

const Wrapper = styled.div`
  position: fixed;
  top: ${({ isVisible }) => (isVisible ? '15px' : '-100%')};
  left: 0;
  width: 100%;
  height: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${({ isVisible }) => (isVisible ? slideIn : slideOut)} 0.5s ease-in-out;
  z-index: 9999;
`;

const Notification = ({ text, isVisible }) => {
  return (
    <Wrapper isVisible={isVisible}>
      <Flex sx={{ 'background-color': 'rgba(0, 0, 0, 0.8)', padding: '15px 20px', 'border-radius': '50px' }}>
        <AppText text={text} fontWeight={'900'} color={'White'} />
      </Flex>
    </Wrapper>
  );
};

export default Notification;
