import { useQuery } from 'react-query';
import { configUseQuery } from '../../helpers/queryConfig';
import { useContext } from 'react';
import { EventContext } from '../../context/EventContext';
import api from '../../api/api';

const getCompanies = async ({ queryKey }) => {
  const id = queryKey[1];
  const eventId = queryKey[2];
  if (id) {
    const response = await api
      .get(`/companies/${id}?eventID=${eventId}`)
      .then((response) => response)
      .catch((error) => console.log(error));
    return response.data;
  }
};

export function useGetCompany(id) {
  const [event] = useContext(EventContext);
  const { data, isLoading } = useQuery(['companies', id, event.id], getCompanies, {
    ...configUseQuery,
    enabled: event.id !== null && event.enabled === true,
  });

  return { companyData: data?.company, isLoading };
}
