export const formatDate = (dateString, lang) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const frenchMonths = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];
  const germanMonths = [
    'januar',
    'februar',
    'märz',
    'april',
    'mai',
    'juni',
    'juli',
    'august',
    'september',
    'oktober',
    'november',
    'dezember',
  ];

  const month = lang === 'fr' ? frenchMonths[monthIndex] : germanMonths[monthIndex];
  const monthNumber = monthIndex + 1 > 10 ? monthIndex + 1 : '0' + (monthIndex + 1);

  return { day, month, year, monthNumber: monthNumber };
};
