import React, { useEffect, useState } from 'react';
import AppText from '../Text/AppText';
import Input from '../Form/Input';
import styled from 'styled-components';
import edit_dark from '../../assets/icons/edit_dark.svg';
import delete_dark from '../../assets/icons/delete_dark.svg';
import add_white from '../../assets/icons/add_white.svg';
import check_save from '../../assets/icons/check_save.svg';
import AppButton from '../Button/AppButton';
import Flex from '../Flex/Flex';
import AppIcon from '../Icon/AppIcon';
import FormError from '../Form/FormError';
import SelectDatepicker from '../Form/Datepicker/SelectDatepicker';

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-radius: 10px;
  min-width: 415px;
`;

const TableRow = styled.tr`
  border: solid #ebecf0;
  border-width: 0 0 2px 0;
  &:first-child {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  border: 2px solid #ebecf0;

  @media (max-width: 800px) {
    display: none;
  }

  &::-webkit-scrollbar {
    display: block;
    width: 12px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(197, 197, 197);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
  }
`;

const Container = styled.table`
  width: 100%;
  margin-top: 20px;
  padding-top: 10px;
  border-top: 2px solid #ebecf0;
`;

const Row = styled.tr``;

const Value = styled.div`
  flex: 2;
`;

const EditButton = styled.div`
  margin-left: 10px;
`;

const MobileTable = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`;

const AddButton = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #4d74ff;
  color: white;
  padding: 10px;
  cursor: pointer;
  margin-top: 20px;
`;

const TableCell = styled.td`
  padding: ${({ isInput }) => (isInput ? '10px' : '20px 30px')};

  @media (max-width: 800px) {
    padding: unset;
  }
`;

const AddRowButton = styled.tr`
  background: #4d74ff;
  opacity: ${({ disabled }) => (!disabled ? '0.5' : '1')};
  text-align: center;
  cursor: pointer;
`;

const AddRowCell = styled.td`
  padding: 10px;
`;

function EditableTable({
  rows,
  setRows,
  numberOfNewRows,
  setNumberOfNewRows,
  readOnly = false,
  limit = null,
  setIsEditing,
  ageLimit = 15,
  isVisitPage = false,
  maxPlaces,
}) {
  const [newRow, setNewRow] = useState({ firstName: '', lastName: '', date_of_birth: '' });
  const [editIndex, setEditIndex] = useState(-1);
  const [errorMessage, setErrorMessage] = useState('');

  const { name, date_of_birth } = JSON.parse(localStorage.getItem('userdata'));
  const limitCheck = isVisitPage ? rows.length < maxPlaces : rows.length < limit;

  useEffect(() => {
    setIsEditing(newRow);
    if (editIndex > -1) {
      setIsEditing(rows[editIndex]);
    }
  }, [newRow, editIndex]);

  const handleAddRow = () => {
    const companionAgeCheck = new Date().getFullYear() - parseInt(newRow.date_of_birth.split('-')[0]) < ageLimit;
    const userAgeCheck = new Date().getFullYear() - parseInt(date_of_birth?.split('-')[0]) < ageLimit;
    if (companionAgeCheck || userAgeCheck) {
      setErrorMessage('registration.minimumAgeDisclaimer');
      return;
    }
    if (newRow.firstName.length < 2 || newRow.lastName.length < 2) {
      setErrorMessage('registration.minNameCharacters');
      return;
    }
    if (newRow.firstName && newRow.lastName && newRow.date_of_birth) {
      setErrorMessage('');
      setRows([
        ...rows,
        {
          firstName: newRow.firstName.substring(0, 15),
          lastName: newRow.lastName.substring(0, 15),
          date_of_birth: newRow.date_of_birth,
        },
      ]);
      setNumberOfNewRows((prevState) => prevState + 1);
      setNewRow({ firstName: '', lastName: '', date_of_birth: newRow.date_of_birth });
      setIsEditing({ firstName: '', lastName: '', date_of_birth: '' });
    }
  };

  const handleEdit = (index) => {
    setEditIndex(index);
  };

  const handleSave = () => {
    const companionAgeCheck = rows.every((row) => new Date().getFullYear() - parseInt(row.date_of_birth) > ageLimit);
    if (!companionAgeCheck) {
      setErrorMessage('registration.minimumAgeDisclaimer');
      return;
    }
    setEditIndex(-1);
    setErrorMessage('');
    setIsEditing({ firstName: '', lastName: '', date_of_birth: '' });
  };

  const handleRowChange = (e, index, field) => {
    const updatedRows = rows.map((row, idx) =>
      idx === index
        ? { ...row, [field]: field !== 'date_of_birth' ? e.target.value?.substring(0, 15) : e.target.value }
        : row
    );
    setRows(updatedRows);
  };

  const handleRemoveRow = (index) => {
    setNumberOfNewRows((prevState) => prevState - 1);
    setRows(rows.filter((_, idx) => idx !== index));
  };

  return (
    <div>
      <TableWrapper>
        <FormError message={errorMessage} params={{ ageLimit }} margin={'0 0 20px 0'} />
        <Table>
          <thead>
            <TableRow>
              <TableCell>
                <AppText text={'form.profile.firstName'} fontWeight={'900'} fontSize={'xs'} color={'GrayBlue70'} />
              </TableCell>
              <TableCell>
                <AppText text={'form.profile.lastName'} fontWeight={'900'} fontSize={'xs'} color={'GrayBlue70'} />
              </TableCell>
              <TableCell>
                <AppText text={'form.profile.birthDate'} fontWeight={'900'} fontSize={'xs'} color={'GrayBlue70'} />
              </TableCell>
              <TableCell />
            </TableRow>
          </thead>
          <tbody>
            <TableRow>
              <TableCell>
                <AppText text={name?.split(' ')[0]} fontWeight={'900'} fontSize={'xs'} color={'GrayBlue50'} />
              </TableCell>
              <TableCell>
                <AppText text={name?.split(' ')[1]} fontWeight={'900'} fontSize={'xs'} color={'GrayBlue50'} />
              </TableCell>
              <TableCell>
                <AppText text={date_of_birth?.split('-')[0]} fontWeight={'900'} fontSize={'xs'} color={'GrayBlue50'} />
              </TableCell>
              <TableCell />
            </TableRow>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell isInput={editIndex === index}>
                  {editIndex === index ? (
                    <Input
                      value={row.firstName}
                      onChange={(e) => handleRowChange(e, index, 'firstName')}
                      placeholder="form.profile.firstName"
                      size={'small'}
                    />
                  ) : (
                    <AppText text={row.firstName} fontWeight={'900'} fontSize={'xs'} color={'GrayBlue50'} />
                  )}
                </TableCell>
                <TableCell isInput={editIndex === index}>
                  {editIndex === index ? (
                    <Input
                      value={row.lastName}
                      onChange={(e) => handleRowChange(e, index, 'lastName')}
                      placeholder="form.profile.lastName"
                      size={'small'}
                    />
                  ) : (
                    <AppText text={row.lastName} fontWeight={'900'} fontSize={'xs'} color={'GrayBlue50'} />
                  )}
                </TableCell>
                <TableCell isInput={editIndex === index}>
                  {editIndex === index ? (
                    <SelectDatepicker
                      onFormChange={(e) => handleRowChange(e, index, 'date_of_birth')}
                      initialSelectedYear={row.date_of_birth}
                      minAge={1}
                    />
                  ) : (
                    <AppText text={row.date_of_birth} fontWeight={'900'} fontSize={'xs'} color={'GrayBlue70'} />
                  )}
                </TableCell>
                <TableCell>
                  {editIndex === index ? (
                    <AppButton icon={check_save} styleType={'ghost'} onClick={() => handleSave(index)} />
                  ) : (
                    <Flex gap={'10px'}>
                      <AppButton icon={edit_dark} styleType={'ghost'} onClick={() => handleEdit(index)} />
                      <AppButton icon={delete_dark} styleType={'ghost'} onClick={() => handleRemoveRow(index)} />
                    </Flex>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {!readOnly && limitCheck && editIndex === -1 && (
              <TableRow>
                <TableCell isInput>
                  <Input
                    value={newRow.firstName}
                    onChange={(e) => setNewRow({ ...newRow, firstName: e.target.value })}
                    placeholder="form.profile.firstName"
                    size={'small'}
                  />
                </TableCell>
                <TableCell isInput>
                  <Input
                    value={newRow.lastName}
                    onChange={(e) => setNewRow({ ...newRow, lastName: e.target.value })}
                    placeholder="form.profile.lastName"
                    size={'small'}
                  />
                </TableCell>
                <TableCell isInput>
                  <SelectDatepicker
                    onFormChange={(e) => setNewRow({ ...newRow, date_of_birth: e.target.value })}
                    minAge={1}
                  />
                </TableCell>
                <TableCell />
              </TableRow>
            )}
          </tbody>
          {!readOnly && limitCheck && (
            <tfoot>
              <AddRowButton disabled={(newRow.firstName || newRow.lastName) && editIndex === -1} onClick={handleAddRow}>
                <AddRowCell colSpan="4">
                  <Flex alignItems={'center'} justifyContent={'center'}>
                    <AppIcon icon={add_white} />
                    <AppText
                      text={'company.page.visit.table.addNew'}
                      textParams={{
                        min: rows.length,
                        max: isVisitPage ? maxPlaces : limit,
                      }}
                      fontSize={'xs'}
                      fontWeight={'900'}
                      color={'BlueGray97'}
                      textAlign={'center'}
                    />
                  </Flex>
                </AddRowCell>
              </AddRowButton>
            </tfoot>
          )}
        </Table>
      </TableWrapper>
      <MobileTable>
        <FormError message={errorMessage} params={{ ageLimit }} margin={'0 0 20px 0'} />
        <Container>
          <Row>
            <TableCell>
              <AppText text={'form.profile.firstName'} fontWeight={'900'} fontSize={'xs'} color={'GrayBlue70'} />
            </TableCell>
            <TableCell>
              <AppText
                text={name?.split(' ')[0]}
                fontWeight={'900'}
                fontSize={'xs'}
                color={'GrayBlue50'}
                textAlign={'right'}
              />
            </TableCell>
          </Row>
          <Row>
            <TableCell>
              <AppText text={'form.profile.lastName'} fontWeight={'900'} fontSize={'xs'} color={'GrayBlue70'} />
            </TableCell>
            <TableCell>
              <AppText
                text={name?.split(' ')[1]}
                fontWeight={'900'}
                fontSize={'xs'}
                color={'GrayBlue50'}
                textAlign={'right'}
              />
            </TableCell>
            <TableCell></TableCell>
          </Row>
          <Row>
            <TableCell>
              <AppText text={'form.profile.birthDate'} fontWeight={'900'} fontSize={'xs'} color={'GrayBlue70'} />
            </TableCell>
            <TableCell>
              <AppText
                text={date_of_birth?.split('-')[0]}
                fontWeight={'900'}
                fontSize={'xs'}
                color={'GrayBlue50'}
                textAlign={'right'}
              />
            </TableCell>
            <TableCell></TableCell>
          </Row>
        </Container>

        {rows.map((row, index) => (
          <>
            <Container key={index}>
              <Row>
                <TableCell>
                  <AppText text={'form.profile.firstName'} fontWeight={'900'} fontSize={'xs'} color={'GrayBlue70'} />
                </TableCell>
                <TableCell>
                  <Value>
                    {editIndex === index ? (
                      <Input
                        value={row.firstName}
                        onChange={(e) => handleRowChange(e, index, 'firstName')}
                        placeholder="First Name"
                        size={'small'}
                      />
                    ) : (
                      <span>
                        <AppText
                          text={row.firstName}
                          fontWeight={'900'}
                          fontSize={'xs'}
                          color={'GrayBlue50'}
                          textAlign={'right'}
                        />
                      </span>
                    )}
                  </Value>
                </TableCell>
              </Row>
              <Row>
                <TableCell>
                  <AppText text={'form.profile.lastName'} fontWeight={'900'} fontSize={'xs'} color={'GrayBlue70'} />
                </TableCell>
                <TableCell>
                  <Value>
                    {editIndex === index ? (
                      <Input
                        value={row.lastName}
                        onChange={(e) => handleRowChange(e, index, 'lastName')}
                        placeholder="form.profile.lastName"
                        size={'small'}
                      />
                    ) : (
                      <span>
                        <AppText
                          text={row.lastName}
                          fontWeight={'900'}
                          fontSize={'xs'}
                          color={'GrayBlue50'}
                          textAlign={'right'}
                        />
                      </span>
                    )}
                  </Value>
                </TableCell>
              </Row>
              <Row>
                <TableCell>
                  <AppText text={'form.profile.birthDate'} fontWeight={'900'} fontSize={'xs'} color={'GrayBlue70'} />
                </TableCell>
                <TableCell>
                  <Value>
                    {editIndex === index ? (
                      <SelectDatepicker
                        onFormChange={(e) => handleRowChange(e, index, 'date_of_birth')}
                        initialSelectedYear={row.date_of_birth}
                        minAge={1}
                      />
                    ) : (
                      <span>
                        <AppText
                          text={row.date_of_birth}
                          fontWeight={'900'}
                          fontSize={'xs'}
                          color={'GrayBlue50'}
                          textAlign={'right'}
                        />
                      </span>
                    )}
                  </Value>
                </TableCell>
              </Row>
            </Container>
            {editIndex === index ? (
              <EditButton>
                <Flex gap={'10px'} justifyContent={'space-evenly'} styles={{ width: '100%' }}>
                  <AppButton icon={check_save} styleType={'ghost'} onClick={() => handleSave(index)} />
                </Flex>
              </EditButton>
            ) : (
              <EditButton>
                <Flex gap={'10px'} justifyContent={'space-evenly'} styles={{ width: '100%' }}>
                  <AppButton icon={edit_dark} styleType={'ghost'} onClick={() => handleEdit(index)} />
                  <AppButton icon={delete_dark} styleType={'ghost'} onClick={() => handleRemoveRow(index)} />
                </Flex>
              </EditButton>
            )}
          </>
        ))}

        {!readOnly && limitCheck && editIndex === -1 && (
          <Container>
            <Row>
              <Value>
                <Input
                  value={newRow.firstName}
                  onChange={(e) => setNewRow({ ...newRow, firstName: e.target.value })}
                  title={'form.profile.firstName'}
                  size={'small'}
                />
              </Value>
            </Row>
            <Row>
              <Value>
                <Input
                  value={newRow.lastName}
                  onChange={(e) => setNewRow({ ...newRow, lastName: e.target.value })}
                  title={'form.profile.lastName'}
                  size={'small'}
                />
              </Value>
            </Row>
            <Row>
              <Value>
                <SelectDatepicker
                  title={'form.profile.birthDate'}
                  onFormChange={(e) => setNewRow({ ...newRow, date_of_birth: e.target.value })}
                  minAge={1}
                  margin={'0 0 20px 0'}
                />
              </Value>
            </Row>
          </Container>
        )}

        <div style={{ marginBottom: editIndex >= 0 ? '40px' : '20px' }} />

        {!readOnly && limitCheck && (
          <AddRowButton
            disabled={(newRow.firstName || newRow.lastName) && editIndex === -1}
            onClick={handleAddRow}
            style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', padding: '10px 10px' }}
          >
            <Flex alignItems={'center'} justifyContent={'center'}>
              <AppIcon icon={add_white} />
              <AppText
                text={'company.page.visit.table.addNew'}
                textParams={{
                  min: rows.length,
                  max: isVisitPage ? maxPlaces : limit,
                }}
                fontSize={'xs'}
                fontWeight={'900'}
                color={'BlueGray97'}
                textAlign={'center'}
              />
            </Flex>
          </AddRowButton>
        )}
      </MobileTable>
    </div>
  );
}

export default EditableTable;
