import styled from 'styled-components';

export const Tag = styled.div`
  border-radius: 15px;
  align-self: flex-start;
  z-index: 3;
  background: rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
`;
export const DashboardContentWrapper = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  padding: 40px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media (max-width: 450px) {
    padding: 10px;
  }
`;
