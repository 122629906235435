import React, { useEffect, useState } from 'react';
import { CardSingle1, CardSingle2 } from '../../../styled';
import AppText from '../../../../../Components/Text/AppText';
import ProgressBar from '../../../../../Components/ProgressBar';
import EditableTable from '../../../../../Components/EditableTable';
import { useTranslation } from 'react-i18next';

const StepTwo = ({
  visit,
  companions,
  setCompanions,
  timeAndPlaces,
  showPlacesGauge,
  isMainCounted = 1,
  isTableReadOnly = false,
  setNewRow,
  isVisitPage,
}) => {
  const { i18n } = useTranslation();
  const [numberOfNewRows, setNumberOfNewRows] = useState(0);
  const [limit, setLimit] = useState(-1);
  const [maxPlaces, setMaxPlaces] = useState(-100);

  useEffect(() => {
    if (isVisitPage) {
      setLimit(
        timeAndPlaces?.nPlaces - (timeAndPlaces?.nTakenPlaces + numberOfNewRows) + companions.length > 6
          ? 6
          : timeAndPlaces?.nPlaces - (timeAndPlaces?.nTakenPlaces + numberOfNewRows) - isMainCounted
      );
    }
  }, [numberOfNewRows, timeAndPlaces]);

  useEffect(() => {
    if (!isVisitPage) {
      setLimit(
        timeAndPlaces?.nPlaces - (timeAndPlaces?.nTakenPlaces + numberOfNewRows) + companions.length > 6
          ? 6
          : timeAndPlaces?.nPlaces - (timeAndPlaces?.nTakenPlaces + numberOfNewRows) - 1
      );
    }
  }, []);

  useEffect(() => {
    if (isVisitPage && maxPlaces === -100 && companions.length > 0) {
      setMaxPlaces(companions.length);
    }
    if (isVisitPage && timeAndPlaces?.nPlaces - timeAndPlaces?.nTakenPlaces > 0) {
      setMaxPlaces(timeAndPlaces?.nPlaces - timeAndPlaces?.nTakenPlaces + companions.length - numberOfNewRows);
    }
  }, [companions.length]);

  return (
    <div>
      <CardSingle1>
        {(visit.restrictions?.fr || visit.restrictions?.de) && (
          <AppText text={visit.restrictions[i18n.language]} color={'GrayBlue50'} fontSize={'as'} />
        )}
        {showPlacesGauge && (
          <ProgressBar
            min={timeAndPlaces?.nPlaces - timeAndPlaces?.nTakenPlaces - numberOfNewRows - isMainCounted || 0}
            max={timeAndPlaces?.nPlaces || 0}
          />
        )}
      </CardSingle1>
      {!(companions.length === 0 && isTableReadOnly) && (
        <CardSingle2>
          <AppText
            text={'company.page.visit.modal.step.2'}
            color={'GrayBlue50'}
            fontWeight={'900'}
            fontSize={'as'}
            style={{ margin: '0 0 20px 0' }}
          />
          <EditableTable
            rows={companions}
            setRows={setCompanions}
            numberOfNewRows={numberOfNewRows}
            setNumberOfNewRows={setNumberOfNewRows}
            ageLimit={visit?.age_restriction}
            limit={isMainCounted === 0 ? limit : limit}
            readOnly={isTableReadOnly}
            setIsEditing={setNewRow}
            maxPlaces={maxPlaces > 6 ? 6 : maxPlaces}
            isVisitPage={isVisitPage}
          />
        </CardSingle2>
      )}
    </div>
  );
};

export default StepTwo;
