import { useEffect, useState } from 'react';

export function useGetIsUnityContainer() {
  const [isUnity, setIsUnity] = useState(false);
  useEffect(() => {
    setIsUnity(navigator.userAgent.includes('Unity'));
  }, []);

  return { isUnity, setIsUnity };
}
