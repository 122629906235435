import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import AppIcon from '../../../Components/Icon/AppIcon';
import AppText from '../../../Components/Text/AppText';
import { useTranslation } from 'react-i18next';

const DashboardColoredCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: ${(props) => props.bgcolor};
  box-shadow: 0px 10px 30px 0px ${(props) => props.bgcolor};
  background-color: ${(props) => props.bgcolor || '#333'};
  color: white;
  justify-content: center;
  padding: 20px;
  text-align: center;
  width: 100%;
  height: 200px;
`;

const ColoredCard = ({ bgcolor, title, onClick }) => {
  const { i18n } = useTranslation();

  return (
    <DashboardColoredCard bgcolor={bgcolor} onClick={onClick}>
      <AppText text={title[i18n.language] ? title[i18n.language] : title} fontSize={'sm'} className={'b-700'} />
    </DashboardColoredCard>
  );
};

export default ColoredCard;
