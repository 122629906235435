import PasswordReset from '../Pages/PasswordReset/PasswordReset';
import Register from '../Pages/SignUp/SignUp';
import Login from '../Pages/Login/Login';
import Dashboard from '../Pages/Dashboard/Dashboard';
import favorite_pink from '../assets/icons/favorite_pink.svg';
import home from '../assets/icons/home.svg';
import event from '../assets/icons/event.svg';
import News from '../Pages/News';
import CompaniesPage from '../Pages/CompaniesPage';
import CompanyPage from '../Pages/CompanyPage';
import Favorites from '../Pages/Favorites';
import Profile from '../Pages/Profile/Profile';
import Visits from '../Pages/Visits';
import CreateNewPassword from '../Pages/NewPassword/CreateNewPassword';

export const nonPrivateRoutes = [
  {
    path: '/password-reset',
    component: PasswordReset,
    clean: true,
  },
  {
    path: '/reset-password',
    component: CreateNewPassword,
    clean: true,
  },
  {
    path: '/register',
    component: Register,
    clean: true,
  },
  {
    path: '/login',
    component: Login,
    clean: true,
  },
];

export const publicRoutes = [
  {
    path: '/',
    component: Dashboard,
    layoutProps: {
      isHeader: true,
      topBarText: 'home.topbar.title',
      title: 'home.title',
      breadCrumbIcon: home,
      breadCrumbArray: ['home'],
      subtitle: 'home.subtitle',
    },
  },
  {
    path: '/news/:id',
    component: News,
    layoutProps: {
      isHeader: true,
      topBarText: 'news.title',
      title: 'news.text.title',
      breadCrumbIcon: home,
      breadCrumbArray: ['home', 'news'],
      subtitle: 'news.text.subtitle',
    },
  },
  {
    path: '/company',
    component: CompaniesPage,
    layoutProps: {
      isCompanyPage: true,
      enablePadding: false,
      showFooter: false,
    },
  },
  {
    path: '/company/:id',
    component: CompanyPage,
    layoutProps: { isCompanyPage: true, enablePadding: true },
  },
];

export const privateRoutes = [
  {
    path: '/favorites',
    component: Favorites,
    layoutProps: {
      topBarText: 'favorites.title',
      isCompanyPage: true,
      breadCrumbIcon: favorite_pink,
      breadCrumbArray: ['fav'],
      isHeader: true,
    },
  },
  {
    path: '/profile',
    component: Profile,
    clean: true,
  },
  {
    path: '/my-visits',
    component: Visits,
    layoutProps: {
      isHeader: true,
      isCompanyPage: true,
      breadCrumbIcon: event,
      breadCrumbArray: ['visits'],
      topBarText: 'mesVisits.topBarText',
    },
  },
];
