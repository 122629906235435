import styled, { css } from 'styled-components';
import { getFontSize, getLineHeight, getColor, getFontWeight, getMobileFontSize } from './utils';

const Text = styled.p`
  font-family: 'Fopen Midi Regular', sans-serif;
  font-size: ${({ fontSize }) => getFontSize(fontSize)};
  line-height: ${({ fontSize }) => getLineHeight(fontSize)};
  text-align: ${({ textAlign }) => textAlign || 'left'};

  @media (max-width: 450px) {
    font-size: ${({ fontSize }) => getMobileFontSize(fontSize)};
  }

  ${(props) =>
    props.isTitle &&
    css`
      font-weight: bold;
    `};

  color: ${({ color }) => getColor(color)};

  font-family: ${({ fontWeight }) => getFontWeight(fontWeight)};

  ${({ noSelect }) =>
    noSelect
      ? `-webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none;
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none;`
      : ''}

  ${({ style }) => style}
`;

const TitleH1 = styled.h1`
  font-size: 36px;
  font-family: ${({ fontWeight }) => getFontWeight(fontWeight)};
`;

const TitleH2 = styled.h2`
  font-size: 30px;
  font-family: ${({ fontWeight }) => getFontWeight(fontWeight)};
`;

const TitleH3 = styled.h3`
  font-size: 24px;
  font-family: ${({ fontWeight }) => getFontWeight(fontWeight)};
`;

export { Text, TitleH1, TitleH2, TitleH3 };
