import React, { useState } from 'react';

const withForm = (WrappedComponent, initialValues, onSubmitCallback, validate) => {
  return (props) => {
    const [formData, setFormData] = useState(initialValues || {});
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      const newValue = type === 'checkbox' ? checked : value;

      setFormData((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));
      if (errors[name]) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: null,
        }));
      }
    };

    const handleSubmit = (e, ...args) => {
      e.preventDefault();
      setErrors({});
      const validationErrors = validate ? validate(formData) : {};
      if (Object.keys(validationErrors).length === 0) {
        onSubmitCallback(formData, ...args);
      } else {
        setErrors(validationErrors);
      }
    };

    return (
      <WrappedComponent
        formData={formData}
        errors={errors}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        {...props}
      />
    );
  };
};

export default withForm;
