import React from 'react';
import DatePicker from 'react-datepicker';
import './style.scss';
import AppButton from '../../../Button/AppButton';
import { useTranslation } from 'react-i18next';
import { FrenchTranslations, GermanTranslations } from './translations';

const CalendarDatepicker = ({ selectedDate, setSelectedDate, ...rest }) => {
  const { i18n } = useTranslation();

  const locale = i18n.language === 'fr' ? FrenchTranslations : GermanTranslations;

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleReset = () => {
    setSelectedDate(null);
  };

  return (
    <div style={{ maxWidth: '285px', margin: '0 auto' }}>
      <DatePicker
        selected={selectedDate}
        inline
        onChange={handleDateChange}
        calendarClassName="react-datepicker"
        monthClassName={() => 'react-datepicker__month'}
        selectedDayClassName={() => 'react-datepicker__day--selected'}
        outsideMonthDayClassName={() => 'react-datepicker__day--outside-month'}
        calendarStartDay={1}
        locale={locale}
        {...rest}
      />
      {selectedDate && (
        <AppButton
          text={'companyPage.filters.datepicker.reset'}
          onClick={handleReset}
          styles={{ margin: '20px auto 0' }}
        />
      )}
    </div>
  );
};

export default CalendarDatepicker;
