import React from 'react';
import AppText from '../Text/AppText';
import AppIcon from '../Icon/AppIcon';
import Flex from '../Flex/Flex';
import chevRight from '../../assets/icons/chevron_right.svg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const TextWrapper = styled.div`
  @media (max-width: 450px) {
    display: none;
  }
`;

const breadCrumbConst = {
  home: { text: 'home.topbar.title', link: '/' },
  news: { text: 'news.title', link: '/news/1' },
  visits: { text: 'mesVisits.topBarText', link: '/my-visits' },
  fav: { text: 'favorites.title', link: '/favorites' },
};

const WebAppHeader = ({ isHeaderConcour, breadCrumbArray, title, subtitle, isHeader, breadCrumbIcon }) => {
  return (
    <>
      {isHeader && (
        <Flex alignItems={'center'} gap={'20px'} sx={{ 'padding-bottom': '20px' }}>
          <Link to={breadCrumbConst[breadCrumbArray[0]].link}>
            <Flex gap={'20px'}>
              <AppIcon icon={breadCrumbIcon} dimensions={'30px'} />
              <TextWrapper>
                <AppText text={breadCrumbConst[breadCrumbArray[0]].text} fontWeight={'900'} color={'GrayBlue85'} />
              </TextWrapper>
              {breadCrumbArray.length > 1 && <AppIcon icon={chevRight} dimensions={'30px'} />}
            </Flex>
          </Link>
          {breadCrumbArray?.map((element, i) => {
            const isLast = i === breadCrumbArray.length - 1;
            if (i === 0) {
              return;
            }
            return (
              <Flex gap={'20px'} alignItems={'center'} key={breadCrumbConst[element].link}>
                <Link to={breadCrumbConst[element].link} style={{ pointerEvents: isLast && 'none' }}>
                  <Flex gap={'20px'}>
                    <AppText
                      text={breadCrumbConst[element].text}
                      fontWeight={'900'}
                      color={isLast ? 'GrayBlue50' : 'GrayBlue85'}
                    />
                  </Flex>
                </Link>
                {!isLast && <AppIcon icon={chevRight} dimensions={'30px'} />}
              </Flex>
            );
          })}
        </Flex>
      )}
      {isHeaderConcour && (
        <div className="c-header">
          <AppText text={subtitle} color="black" fontSize="md" />
          <AppText text={title} color="blue" fontSize="lg" className="b-700" />
        </div>
      )}
    </>
  );
};

export default WebAppHeader;
