import React from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardContentWrapper, Tag } from './appCardsStyle';
import AppText from '../Text/AppText';
import ImageWithOverlay from '../ImageWithOverlay/ImageWithOverlay';
import AppButton from '../Button/AppButton';
import ArrowRight from '../../assets/icons/ArrowRight.svg';
import { Link, useNavigate } from 'react-router-dom';

const AppCardsDashboard = ({ content, tag, button }) => {
  const { i18n } = useTranslation();
  const nav = useNavigate();

  const handleReadMore = () => {
    nav(`/news/${content.id}`);
  };

  return (
    <Link to={`/news/${content.id}`}>
      <ImageWithOverlay image={content.thumbnail} height={'540px'}>
        <DashboardContentWrapper>
          <div />
          <div style={{ maxWidth: '50%' }}>
            <AppText text={content.title[i18n.language]} fontWeight={'900'} fontSize={'xl'} />
            <AppText text={content.subtitle[i18n.language]} fontWeight={'900'} />
          </div>
          <AppButton
            text={button}
            styleType={'orange'}
            styles={{ alignSelf: 'flex-end' }}
            icon={ArrowRight}
            onClick={handleReadMore}
          />
        </DashboardContentWrapper>
      </ImageWithOverlay>
    </Link>
  );
};

export default AppCardsDashboard;
