import AppText from '../../Components/Text/AppText';
import '../Login/login.scss';
import Flex from '../../Components/Flex/Flex';
import Input from '../../Components/Form/Input';
import withForm from '../../hoc/withForm';
import { FormCard } from '../../Components/Form/FormCard';
import SelectDatepicker from '../../Components/Form/Datepicker/SelectDatepicker';
import { StyledForm } from '../../Components/Form/styled';
import Checkbox from '../../Components/Form/Checkbox';
import AppButton from '../../Components/Button/AppButton';
import React, { useRef, useState } from 'react';
import Modal from '../../Components/Modal';
import AppIcon from '../../Components/Icon/AppIcon';
import launch from '../../assets/icons/launch.svg';
import { Link, useNavigate } from 'react-router-dom';
import FormError from '../../Components/Form/FormError';
import { calculateAge } from '../../helpers/form';
import axios from 'axios';
import LanguageButtons from '../../Components/LanguageButtons';
import { LanguageButtonsContainer } from '../Profile/styled';
import Select from '../../Components/Select';
import { useTranslation } from 'react-i18next';

const handleRegister = (formData, setResponseError, setSuccessRegistration, lang) => {
  const body = {
    name: `${formData.firstName} ${formData.lastName}`,
    email: formData.email,
    lang: formData.lang,
    password: formData.password,
    password_confirmation: formData.password,
    tel: formData.tel,
    has_limited_mobility: !!formData.has_limited_mobility,
    date_of_birth: `${formData.year}-01-01`,
  };

  const requestOptions = {
    method: 'post',
    url: process.env.REACT_APP_API_URL + '/register',
    headers: { 'Content-Type': 'application/json' },
    params: {
      lang,
    },
    data: JSON.stringify(body),
  };

  axios(requestOptions.url, requestOptions)
    .then(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('userdata');
      setSuccessRegistration(true);
    })
    .catch((error) => {
      console.log('Error occurred:', error);
      setResponseError(error.response.data.message);

      const logBody = {
        type: 'register error',
        log: error,
      };
      const logRequestOptions = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/log',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(logBody),
      };

      axios(logRequestOptions.url, logRequestOptions);
    });
};

const validate = (formData) => {
  const errors = {};
  const dateOfBirth = `${formData.year}-${formData.month}-${formData.day}`;
  if (calculateAge(dateOfBirth) < 15) {
    errors.day = 'registration.minimumAgeDisclaimer.15';
  }
  if (formData.firstName.length <= 1) {
    errors.firstName = 'validation.firstName.min';
  }
  if (formData.firstName.length >= 20) {
    errors.firstName = 'validation.firstName.max';
  }
  if (formData.tel.length > 0 && !/^[0-9+ ]+$/.test(formData.tel)) {
    errors.tel = 'Error';
  }
  if (formData.lastName.length <= 1) {
    errors.lastName = 'validation.lastName.min';
  }
  if (!/^[\w-]+(\.[\w-]+)*\+?[\w-]+@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)) {
    errors.email = 'validation.email.email';
  }
  if (formData.lastName.length >= 20) {
    errors.lastName = 'validation.lastName.max';
  }
  if (formData.password.length < 8) {
    errors.password = 'validation.password.min';
  }
  if (formData.password.length >= 20) {
    errors.password = 'validation.password.max';
  }
  return errors;
};

const SignUp = ({ errors, handleChange, handleSubmit }) => {
  const [agreeTerms, setAgreeTerms] = useState(false);
  const submitRef = useRef(null);
  const [showTerms, setShowTerms] = useState(false);
  const [successRegistration, setSuccessRegistration] = useState(false);
  const [responseError, setResponseError] = useState('');
  const { i18n } = useTranslation();
  const nav = useNavigate();

  const handleShowTerms = () => {
    setShowTerms((prevState) => !prevState);
  };

  const handleAgreeTerms = () => {
    setAgreeTerms((prevState) => !prevState);
  };

  const handleValidateTerms = () => {
    if (!agreeTerms) {
      setShowTerms(true);
    }
  };

  const handleSuccess = () => {
    nav('/');
  };

  return (
    <div className="login-container  login-box">
      <div className="form-section">
        <FormCard margin={'170px 0'}>
          <div className={'title'}>
            <AppText
              text={'registration.title'}
              color={'GrayBlue50'}
              fontSize={'lg'}
              fontWeight={'900'}
              textAlign={'center'}
            />
            <AppText text={'registration.desc'} color={'GrayBlue55'} fontSize={'sm'} textAlign={'center'} />
          </div>
          <StyledForm
            onSubmit={(e) => {
              handleSubmit(e, setResponseError, setSuccessRegistration, i18n.language);
            }}
          >
            <Flex gap={'20px'}>
              <Input
                title={'form.profile.firstName'}
                name={'firstName'}
                error={errors.firstName}
                onChange={handleChange}
                required
              />
              <Input
                title={'form.profile.lastName'}
                name={'lastName'}
                error={errors.lastName}
                onChange={handleChange}
                required
              />
            </Flex>
            <Input title={'form.phonenumber'} type={'tel'} name={'tel'} error={errors.tel} onChange={handleChange} />
            <Select
              name={'lang'}
              title={'language.select'}
              options={[
                { name: 'form.profile.french', value: 'fr' },
                { name: 'form.profile.german', value: 'de' },
              ]}
              onChange={handleChange}
              style={{ width: '100%' }}
            />
            <Input
              title={'form.profile.email'}
              name={'email'}
              error={errors.email}
              onChange={handleChange}
              required
              margin={'20px 0'}
            />
            <Input
              title={'login.placeholder.password'}
              type={'password'}
              name={'password'}
              error={errors.password}
              onChange={handleChange}
              required
            />
            <SelectDatepicker title={'form.profile.birthDate'} onFormChange={handleChange} />
            <FormError message={errors.day} margin={'0 0 20px 30px'} />
            <Checkbox
              title={'form.profile.mobility.title'}
              label={'form.profile.mobility'}
              onChange={handleChange}
              name={'has_limited_mobility'}
            />
            <div style={{ width: '100%', margin: '20px 0' }}>
              <AppText text={'registration.terms.1'} color={'GrayBlue40'} style={{ display: 'inline' }} />
              <AppText
                text={'registration.terms.2'}
                color={'DarkBlue2'}
                style={{ display: 'inline', cursor: 'pointer' }}
                onClick={handleShowTerms}
              />
            </div>
            <FormError message={responseError} margin={'0 0 20px 0'} />
            <AppButton
              type={agreeTerms ? 'submit' : 'button'}
              text={'registration.success'}
              ref={submitRef}
              onClick={handleValidateTerms}
              size={'xl'}
            />
            <div style={{ marginTop: '40px' }}>
              <Link to={'/login'}>
                <Flex alignItems={'center'} gap={'10px'} justifyContent={'center'}>
                  <AppText text={'registration.haveAcc'} color={'GrayBlue55'} fontSize={'sm'} />
                  <AppIcon icon={launch} />
                </Flex>
              </Link>
            </div>
          </StyledForm>
        </FormCard>
        {showTerms && (
          <Modal width={'80%'}>
            <AppText
              text={'registration.terms.title'}
              fontWeight={'900'}
              fontSize={'xl'}
              color={'GrayBlue50'}
              textAlign={'center'}
              style={{ marginBottom: '35px' }}
            />
            <AppText text={'registration.terms.body'} color={'GrayBlue55'} textAlign={'center'} />
            <Link to={'/news/7'} target={'_blank'}>
              <AppText
                text={'registration.readMore.policy'}
                textAlign={'center'}
                color={'Orange'}
                style={{ textDecoration: 'underline' }}
              />
            </Link>
            <Flex justifyConent={'space-between'} alignItems={'center'} sx={{ margin: '95px 0 0 0' }}>
              <Checkbox label={'registration.terms.checkbox'} onChange={handleAgreeTerms} value={agreeTerms} />
              <AppButton
                text={'registration.terms.next'}
                size={'l'}
                disabled={!agreeTerms}
                onClick={() => {
                  if (submitRef.current) {
                    submitRef.current.click();
                  }
                  handleShowTerms();
                }}
              />
            </Flex>
          </Modal>
        )}
        {successRegistration && (
          <Modal width={'80%'}>
            <AppText
              text={'registration.modal.success.title'}
              color={'GrayBlue50'}
              fontSize={'xl'}
              textAlign={'center'}
              fontWeight={'900'}
            />
            <AppText text={'registration.modal.success.body'} color={'GrayBlue55'} textAlign={'center'} />
            <AppButton text={'registration.continue'} onClick={handleSuccess} styles={{ margin: '40px auto 0' }} />
          </Modal>
        )}
        <LanguageButtonsContainer>
          <LanguageButtons menuTop={'-20px'} menuLeft={'-25px'} />
        </LanguageButtonsContainer>
      </div>
    </div>
  );
};
export default withForm(
  SignUp,
  {
    firstName: '',
    lastName: '',
    email: '',
    lang: 'fr',
    password: '',
    tel: '',
    has_limited_mobility: '',
    day: '1',
    month: '1',
    year: '2024',
  },
  handleRegister,
  validate
);
