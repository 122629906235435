import { useQuery } from 'react-query';
import { configUseQuery } from '../../helpers/queryConfig';
import api from '../../api/api';

const fetchJobs = async () => {
  const response = await api
    .get('/jobs')
    .then((response) => response)
    .catch((error) => console.log(error));
  return response.data;
};

export function useGetJobs() {
  const { data, isLoading } = useQuery('jobs', fetchJobs, configUseQuery);

  return { jobs: data?.jobs, isLoading };
}
