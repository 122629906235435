import React, { useContext } from 'react';
import styled from 'styled-components';
import { useGetFavorites } from '../../hooks/api/favorites/useGetFavorites';
import Loader from '../../Components/Loader';
import CompanyCard from '../CompaniesPage/components/CompaniesList/components/CompanyCard';
import { AuthContext } from '../../context/AuthContext';
import AppText from '../../Components/Text/AppText';
import { EventContext } from '../../context/EventContext';

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 30px;
  padding: 0 80px;
  align-items: flex-start;
  ${({ style }) => style};

  @media (max-width: 500px) {
    padding: 0;
  }
`;

const Favorites = () => {
  const [token] = useContext(AuthContext);
  const { favorites, isLoading } = useGetFavorites(token);
  const [event] = useContext(EventContext);

  if (event?.disabled) {
    return (
      <AppText
        text={'events.disabled'}
        color={'GrayBlue70'}
        fontWeight={'900'}
        textAlign={'center'}
        style={{ margin: '60px' }}
      />
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  if (favorites?.length === 0 && !isLoading) {
    return (
      <AppText
        text={'favorites.list.empty'}
        color={'GrayBlue70'}
        fontWeight={'900'}
        textAlign={'center'}
        style={{ margin: '150px 0' }}
      />
    );
  }

  return (
    <GridContainer>
      {favorites?.length > 0 && favorites.map((favorite) => <CompanyCard favPage company={favorite.company} />)}
    </GridContainer>
  );
};

export default Favorites;
