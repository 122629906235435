import React, { useEffect, useState } from 'react';
import AppText from '../Text/AppText';
import Flex from '../Flex/Flex';
import { SwitchInput, SwitchWrapper, SwitchSlider } from './styled';

const Switch = ({ onChange, label, style, value, ...rest }) => {
  const [isSwitched, setIsSwitched] = useState(value);

  useEffect(() => {
    setIsSwitched(value);
  }, [value]);

  const handleChange = (e) => {
    setIsSwitched((prevState) => !prevState);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <Flex justifyContent={'space-between'} sx={style}>
      <AppText text={label} fontWeight={'900'} color={'GrayBlue70'} fontSize={'xs'} />
      <SwitchWrapper>
        <SwitchInput type="checkbox" checked={isSwitched} onChange={handleChange} {...rest} />
        <SwitchSlider />
      </SwitchWrapper>
    </Flex>
  );
};

export default Switch;
