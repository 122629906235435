import AppText from '../Text/AppText';
import './appButton.scss';
import AppIcon from '../Icon/AppIcon';
import { ButtonTypes } from './utils';
import { StyledButton, StyledIconButton } from './styled';
import { forwardRef } from 'react';

const AppButton = forwardRef(
  (
    {
      styleType = 'primary',
      size = 'm',
      rectangle = false,
      type,
      text,
      onClick,
      textSize = 'sm',
      icon,
      iconPosition = 'left',
      textAlign = 'left',
      disabled = false,
      styles,
    },
    ref
  ) => {
    return text ? (
      <StyledButton
        onClick={onClick}
        styleType={styleType}
        disabled={disabled}
        size={size}
        textAlign={textAlign}
        rectangle={rectangle}
        type={type}
        styles={styles}
        ref={ref}
      >
        {icon && iconPosition === 'left' && <AppIcon icon={icon} />}
        <AppText
          text={text}
          fontSize={textSize}
          fontWeight={'900'}
          textAlign={textAlign}
          style={{ textWrap: 'nowrap' }}
          color={(ButtonTypes[styleType] || ButtonTypes.colored(styleType)).color}
        />
        {icon && iconPosition === 'right' && <AppIcon icon={icon} />}
      </StyledButton>
    ) : (
      <StyledIconButton
        onClick={onClick}
        styleType={styleType}
        disabled={disabled}
        size={size}
        rectangle={rectangle}
        type={type}
        styles={styles}
        ref={ref}
      >
        <AppIcon icon={icon} />
      </StyledIconButton>
    );
  }
);

export default AppButton;
