import React, { useState } from 'react';
import Flex from '../Flex/Flex';
import AppText from '../Text/AppText';
import { DropdownWrapper, SelectedItems } from './styled';
import AppIcon from '../Icon/AppIcon';
import keyboard_arrow_up from '../../assets/icons/keyboard_arrow_up.svg';

const Dropdown = ({ children, selectedTagsSize, margin, label, ...textRest }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <DropdownWrapper margin={margin}>
      <Flex
        justifyContent={'space-between'}
        onClick={handleOpen}
        sx={{ margin: isOpen ? '0 0 17px 0' : '', cursor: 'pointer' }}
      >
        <Flex gap={'10px'} alignItems={'center'}>
          <AppText text={label} fontWeight={'900'} color={'GrayBlue70'} fontSize={'xs'} {...textRest} />
          {selectedTagsSize > 0 && (
            <SelectedItems>
              <AppText
                text={selectedTagsSize}
                fontWeight={'900'}
                color={'white'}
                fontSize={'xxs'}
                textAlign={'center'}
              />
            </SelectedItems>
          )}
        </Flex>
        {isOpen ? <AppIcon icon={keyboard_arrow_up} /> : <AppIcon icon={keyboard_arrow_up} rotate={180} />}
      </Flex>
      <div style={{ display: isOpen ? 'block' : 'none', width: '100%' }}>{children}</div>
    </DropdownWrapper>
  );
};

export default Dropdown;
