import { useState } from 'react';

export function useDebounce(callback, delay = 500) {
  const [timerId, setTimerId] = useState(null);

  return function debouncedCallback(...args) {
    clearTimeout(timerId);
    const newTimerId = setTimeout(() => {
      callback(...args);
    }, delay);
    setTimerId(newTimerId);
  };
}
