import React from 'react';
import styled from 'styled-components';

const BackgroundContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: ${(props) => props.height};
  position: relative;
  border-radius: 10px;

  &::before {
    content: '';
    background: ${(props) => (props.image ? `url(${props.image})` : 'rgb(49, 67, 128)')};
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
  }

  &:hover::before {
    transform: scale(1.3);
  }
`;

const ImageWithOverlay = ({ image, style, height, children, onClick }) => {
  return (
    <div onClick={onClick} style={style}>
      <BackgroundContainer image={image} height={height}>
        {children}
      </BackgroundContainer>
    </div>
  );
};

export default ImageWithOverlay;
