import React from 'react';
import AppIcon from '../../../Components/Icon/AppIcon';
import AppText from '../../../Components/Text/AppText';
import Flex from '../../../Components/Flex/Flex';

const TextWithIcon = ({ icon, text, textParams, color = 'GrayBlue70', margin = '0 0 0 0' }) => {
  return (
    <Flex gap={'15px'} sx={{ margin }} alignItems={'center'}>
      <AppIcon icon={icon} />
      <AppText text={text} color={color} textParams={textParams} fontWeight={'900'} fontSize={'xs'} />
    </Flex>
  );
};

export default TextWithIcon;
