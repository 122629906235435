import styled from 'styled-components';

const StyledIcon = styled.img`
  width: ${({ dimensions }) => dimensions};
  height: ${({ dimensions }) => dimensions};
  rotate: ${({ rotate }) => rotate || '0'}deg;
  min-height: unset !important;
`;

const AppIcon = ({ icon, alt, styles, onClick, dimensions, rotate }) => {
  return <StyledIcon src={icon} alt={alt} style={styles} rotate={rotate} dimensions={dimensions} onClick={onClick} />;
};

export default AppIcon;
