import { useQuery } from 'react-query';
import { configUseQuery } from '../../../helpers/queryConfig';
import { useContext } from 'react';
import { EventContext } from '../../../context/EventContext';
import api from '../../../api/api';

const getRegistrations = async ({ queryKey }) => {
  const eventId = queryKey[1];
  const response = await api
    .get(`/registrations?eventID=${eventId || 1}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });

  return response?.data?.registrations;
};

export function useGetRegistrations() {
  const [event] = useContext(EventContext);
  const { data, isLoading, error } = useQuery(['registrations', event.id], getRegistrations, {
    ...configUseQuery,
    enabled: event.id !== null && event.enabled === true,
  });

  return { registrationsData: data, isLoading, error };
}
