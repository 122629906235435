const translationDE = {
  'main.header.title':
    'FribourgOPEN wird in 2024 wieder stattfinden, vom 18. bis 21. September (Glane, Greyerz, Vivisbach) und vom 25. bis 28. September (Broye, See, Saane, Sense). Weitere Informationen folgen bald!',
  'dashboard.cards.title.1': 'FribourgOPEN, offene Türen XXL im gesamten Kanton Freiburg',
  'dashboard.cards.map.title': 'Interaktive Karte',
  'nav.visitors': 'Besucher',
  'nav.about': 'Informationen',
  'nav.discoverCompanies': 'Unternehmen entdecken',
  'welcome.title': 'Willkommen!',
  'signup.info': 'Geben Sie die für die Erstellung Ihres Kontos erforderlichen Informationen ein.',
  'dashboard.cards.map.text': 'Entdecken Sie die FribourgOPEN-Unternehmen',
  'dashboard.cards.text.2': 'Hier finden Sie die neuesten Nachrichten',
  'download.mobile.title': 'Mobile et tablette',
  'download.android.version': 'Erfordert Android OS 7.0 oder neuer',
  'download.ios.version': 'Erfordert iOS 12.0 oder neuer',
  'download.android.title': 'Android',
  'download.ios.title': 'IOS',
  'competition.topbar.title': 'Wettbewerb',
  'competition.title': "Nehmen Sie an FribourgOPEN teil und gewinnen Sie ein E-Bike Scott im Wert von CHF 4'899.–!",
  'competition.subtitle': 'Grosser Wettbewerb FribourgOPEN',
  'news.title': 'Nachrichten',
  'home.topbar.title': 'Startseite',
  'home.title': 'Willkommen bei FribourgOPEN',
  'home.subtitle': 'Vom 18. bis 21. und vom 25. bis 28. September 2024: entdecken Sie die Unternehmen des Kantons!',
  'login.title': 'Anmeldung',
  'login.text': 'Melden Sie sich an, um alle FribourgOPEN-Online-Services zu nutzen',
  'login.dontHaveAcc': 'Ich habe noch kein FribourgOPEN-Konto',
  'login.passwordChange': 'Ich habe mein Passwort vergessen',
  'company.title': 'Unternehmen',
  'news.text.title': 'Alle wichtigen Informationen',
  'news.text.subtitle': 'Hier finden Sie die neuesten Nachrichten',
  'favorites.title': 'Meine Favoriten',
  'favorites.text.title': 'Übersicht Ihrer Favoriten',
  'favorites.text.subtitle': 'Finden Sie alle Unternehmen von FribourgOPEN auf unserer interaktiven Karte!',
  'dl.title': 'Herunterladen',
  'dl.text.title': 'Die FribourgOPEN App ist verfügbar!',
  'aide.topBarText': 'FàQ (DE)',
  'aide.title': 'Questions posées fréquemment (DE)',
  'aide.subtitle':
    'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. (DE)',
  'profile.topBarText': 'mein Konto',
  'profile.text.title': 'Persönliche Angaben',
  'profile.text.subtitle': 'Hier können Sie Ihre persönlichen Angabe überprüfen und ändern',
  'mesVisits.topBarText': 'Meine Besuche',
  'mesVisits.title': 'Meine Besuche',
  'mesVisits.subtitle': 'Liste der Besuche, für die Sie sich angemeldet haben',
  'favorites.page.title': 'Liste der Unternehmen',
  'favorites.empty.text': 'Keine Unternehmen in Ihren Favoriten gespeichert',
  'favorites.list.title': 'Liste der Unternehmen',
  'download.first.title': "L'application FribourgOPEN bientôt disponible ! (DE)",
  'download.guide.text': 'Besuchen Sie FribourgOPEN.ch/dl auf Ihrem Smartphone, um die Installation zu starten!',
  'landing.discovery': 'Auf der Entdeckung der Unternehmen des Kantons',
  'landing.date': 'Vom 27. bis 30. September 2023.',
  'landing.description':
    'FribourgOPEN ist die grösste Veranstaltung mit offenen Türen, die je im Kanton Freiburg stattgefunden hat. Kommen Sie und entdecken Sie die Unternehmen des Kantons sowie ihr Know-how!',
  'landing.register': 'Anmeldung',
  'landing.openDoors': 'FribourgOPEN',
  'landing.openDoorsDescription': 'XXL offene Türen im gesamten Freiburger Kanton.',
  'landing.registrationsOpen': 'Die Anmeldungen sind eröffnet',
  'landing.howToRegister': 'Erfahren Sie hier, wie Sie sich anmelden können.',
  'landing.allCompanies': 'Alle Unternehmen auf ',
  'landing.allCompaniesAtGlance': 'einen Blick',
  'landing.mapDescription':
    'Finden Sie auf der interaktiven Karte die 100 Unternehmen, die vom 27. bis 30. September 2023 ihre Türen für Sie öffnen werden.',
  'landing.variousCompanies': 'Vielfältige Unternehmen ',
  'landing.discover': 'zum Entdecken',
  'landing.learnMore':
    'Erfahren Sie mehr über die Unternehmen anhand ihrer Beschreibung, Fotogalerie und Videos und erstellen Sie mit Ihrem persönlichen Konto eine Favoritenliste, indem Sie Filter nach Branche oder Beruf verwenden.',
  'landing.activitiesToDo': 'Eine Vielzahl ',
  'landing.activitiesToDo2': 'von Aktivitäten, die Sie machen können',
  'landing.beInspired':
    'Lassen Sie sich von den Aktivitäten inspirieren, die Sie während der vier Veranstaltungstage unternehmen können, und wählen Sie so viele Besuche aus, wie Sie möchten',
  'landing.visitsBasedOn': 'Besuche nach ',
  'landing.yourWishes': 'Lust und Laune',
  'landing.selectYourVisit': 'Wählen Sie Ihre Tour aus und melden Sie sich und Ihre Begleitpersonen an.',
  'footer.projectOf': 'Ein Projekt von',
  'footer.contactUs': 'Uns kontaktieren',
  'footer.ccifAddress': "Chambre de commerce et d'industrie du canton de Fribourg (CCIF)",
  'footer.ccifAddressDetails': 'Rte du Jura 37B / CP 160',
  'footer.ccifCity': 'CH - 1701 Fribourg',
  'footer.learnMore': 'Mehr über die CCIF und ihre Aktivitäten',
  'footer.telephone': 'Tel. ',
  'footer.phoneNumber': '+41 26 347 12 20',
  'footer.email': 'Mail.',
  'footer.poweredBy': 'Powered by',
  'userdata.set.success': 'Ihre Informationen wurden erfolgreich geändert!',
  'userdata.set.error': 'Bei der Speicherung Ihrer Informationen ist ein Problem aufgetreten.',
  'form.profile.deleteAccountConfirmation': 'Ihre Anmeldung wurde genehmigt.',
  'form.profile.deleteAccountDetail':
    'Es wurde eine E-Mail an Ihre E-Mail-Adresse gesendet. Bitte bestätigen Sie dies, indem Sie auf den Link in der E-Mail klicken, damit Sie sich anschließend anmelden können.',
  'form.profile.continue': 'Weiter',
  'form.profile.cancel': 'Abbrechen',
  'form.profile.firstName': 'Vorname',
  'form.profile.lastName': 'Name',
  'form.profile.language': 'Sprache:',
  'language.select': 'Sprache',
  'form.profile.birthDate': 'Geburtsjahr',
  'form.profile.email': 'E-Mail-Adresse',
  'form.profile.mobility': 'Meine Mobilität/Meine Zugänglichkeit ist eingeschränkt',
  'form.profile.saveInfo': 'Meine Informationen speichern',
  'form.profile.deleteYourAccount': 'Ihr Account Löschen',
  'form.profile.french': 'Französisch',
  'form.profile.german': 'Deutsch',
  'form.profile.english': 'Englisch',
  'visit.success.alert': 'Ihr Besuch wurde erfolgreich geändert.',
  'visit.error.alert': 'Es gab einen Fehler bei der Änderung Ihres Besuchs. (code :',
  'visits.companions': 'Begleitpersonen (3 maximum)',
  'visits.description': 'Beschreibung des Besuchs',
  'visits.restrictions': 'Einschränkungen',
  'visits.info': 'Praktische Informationen',
  'visits.visit.completed': 'Die Besichtigung ist ausgebucht.',
  'visits.booking': 'Belegte Plätze:  ',
  'visits.empty': 'Zurzeit sind keine Veranstaltungen geplant',
  'visits.popup.cancel': 'Sind Sie sicher, dass Sie den Besuch absagen möchten?',
  'visits.popup.cancel.info':
    'Wenn Sie dem Löschen dieses Besuchs zustimmen, verschwindet er aus der Besuchsliste und Ihre Anmeldung wird gelöscht.',
  'visits.popup.button.apply': 'Bestätigen',
  'visits.popup.button.annuler': 'Stornieren',
  'section-entreprises.title': 'Die Unternehmen öffnen ihre Türen',
  'section-entreprises.dates': 'Vom 27. bis 30. September 2023',
  'section-entreprises.paragraph1':
    'FribourgOPEN ist eine einzigartige Gelegenheit, die allen Unternehmen des Kantons offen steht, vom kleinsten bis zum grössten, vom traditionellsten bis zum technologischsten, und die eine schöne Präsentation in der breiten Öffentlichkeit bietet.',
  'section-entreprises.paragraph2':
    'Die HIKF bietet ihren Mitgliedsunternehmen die Möglichkeit, das Image ihres Unternehmens zu verschönern, sich besser bekannt zu machen, ihr Know-how und ihre verschiedenen Berufe zu präsentieren, Kontakte mit zukünftigen Fachkräften zu knüpfen und Talente anzuziehen, indem sie ihre Türen vom 27. bis 30. September 2023 nach Wahl öffnen.',
  'section-entreprises.paragraph3':
    'Die teilnehmenden Unternehmen werden einen Empfang im Betrieb anbieten, mit Rundgängen (bei grösseren Unternehmen) oder Präsentationen durch Vorführungen. Wenn dies aus Gründen der Grösse oder des verfügbaren Platzes nicht möglich ist, kann sie eine thematische Konferenz, einen kleinen praktischen Workshop, einen Kurs, eine spielerische Aktivität, eine Ausstellung usw. anbieten. Seine Türen zu öffnen bedeutet, Aktivitäten seiner Wahl zu organisieren, die mit seinem Kerngeschäft in Verbindung stehen.',
  'section-entreprises.paragraph4':
    'Alle Vorschläge für Experimente, die von den Besuchern - Jugendlichen und Erwachsenen - durchgeführt werden können, sind zu bevorzugen. Sie wecken immer grosses Interesse und die konkrete Umsetzung in eine Situation wertet die Berufe auf. Der Besuch von emblematischen Baustellen und/oder Projekten ist ebenfalls eine gute Gelegenheit, um das Know-how zur Geltung zu bringen.',
  'popup.connexion.content': 'Inhalte nur für angemeldete Nutzer',
  'popup.connexion.detail':
    'Wenn Sie an den vorgeschlagenen Besuchen teilnehmen möchten, melden Sie sich bitte an oder erstellen Sie ein Konto.',
  'popup.connexion.loginButton': 'Anmelden',
  'popup.connexion.cancelButton': 'Abbrechen',
  'c-presentation.visitWebsite': 'Website besuchen',
  'c-presentation.inImages': 'In Bildern',
  'c-presentation.professions': 'Berufe',
  'c-filters.deleteFilters': 'Filter löschen',
  'c-filters.byCompanyName': 'Nach Firmennamen',
  'c-filters.specificJobs': 'Spezifische Berufe',
  'c-filters.activitySectors': 'Tätigkeitsbereiche',
  'c-filters.trainingCompanies': 'Ausbildendes Unternehmen',
  'menu.logout': 'Abmelden',
  'menu.login': 'Anmelden',
  'contact.title': 'Uns kontaktieren',
  'contact.address': 'Handels- und Industriekammer des Kantons Freiburg (HIKF)',
  'contact.addressLine': 'Route du Jura 37B / PF 160',
  'contact.city': 'CH - 1701 Freiburg',
  'contact.learnMore': 'Mehr über die HIKF und ihre Aktivitäten',
  'contact.tel': 'Tel.',
  'contact.email': 'Mail.',
  'visits.confirmation.title': 'Anmeldung',
  'visits.confirmation.subtitle': 'Bitte bestätigen',
  'visits.confirmation.summaryWith': `Zusammenfassung Ihrer Anmeldung für einen Besuch bei {{companyName}} mit {{inscriptionDataLength}} Begleitpersonen`,
  'visits.confirmation.summaryWithout': `Zusammenfassung Ihrer Anmeldung für einen Besuch bei {{companyName}} ohne Begleitperson`,
  'visits.confirmation.date': 'Datum',
  'visits.confirmation.time': 'Uhr',
  'visits.confirmation.address': 'Adresse',
  'visits.confirmation.cancel': 'Stornieren',
  'visits.confirmation.confirm': 'Anmeldung bestätigen',
  'news.card.subtitle': 'Informations (DE)',
  'news.card.title': 'FribourgOpen, les entreprises du canton ouvertes au public (DE)',
  'bookmark.remove': 'Favoriten entfernen',
  'bookmark.add': 'Favoriten hinzufügen',
  'login.error.badCredentials': 'Die Informationen sind nicht korrekt.',
  'login.placeholder.email': 'E-Mail-Adresse',
  'login.placeholder.password': 'Passwort',
  'login.button.signIn': 'Anmelden',
  'login.text.notRegistered': 'Noch nicht registriert ? -',
  'login.button.signUp': 'Registrieren',
  'login.button.forgotPassword': 'Passwort vergessen ?',
  'passwordChange.success': 'Ihr Passwort wurde erfolgreich geändert.',
  'passwordChange.continue': 'Klicken Sie auf "Weiter", um sich anmelden zu können.',
  'passwordChange.button.continue': 'Weiter',
  'passwordChange.emailPlaceholder': 'E-Mail-Adresse',
  'passwordChange.newPasswordPlaceholder': 'Neues Passwort',
  'passwordChange.confirmNewPasswordPlaceholder': 'Bestätigen Sie Ihr neues Passwort',
  'passwordChange.confirmButton': 'Bestätigen',
  'registration.approved': 'Ihre Anmeldung wurde genehmigt.',
  'registration.emailSent':
    'Es wurde eine E-Mail an Ihre E-Mail-Adresse gesendet. Bitte bestätigen Sie dies, indem Sie auf den Link in der E-Mail klicken, damit Sie sich anschließend anmelden können.',
  'registration.continue': 'Weiter',
  'termsOfUse.title': 'Allgemeine Nutzungsbedingungen',
  'registration.welcome':
    'Willkommen auf FribourgOPEN! Wir freuen uns, Sie auf unserer Plattform begrüßen zu dürfen, die der Erstellung von Unternehmensbesuchen gewidmet ist.',
  'useOfData.title': 'Erhebung von Nutzerdaten',
  'useOfData.description':
    'Bei Ihrer Anmeldung bei FribourgOPEN sammeln wir bestimmte persönliche Informationen wie Ihren Namen, Vornamen und Ihr Alter.',
  'dataSecurity.title': 'Sicherheit der Daten',
  'cookies.title': 'Cookies und Tracking-Technologien',
  'privacyPolicy.title': 'Änderung der Nutzungsbedingungen',
  'registration.complete': 'Durch das Erstellen Ihres Kontos akzeptieren Sie die Nutzungsbedingungen unserer Website',
  'registration.signIn': 'Melden Sie sich an, um auf alle Funktionen unserer Website zugreifen zu können',
  'registration.notRegistered': 'Noch nicht registriert ? -',
  'registration.signUp': 'Registrieren',
  'registration.alreadyRegistered': 'Berits registriert ? -',
  'registration.login': 'Anmelden',
  'dataUse.title': 'Verwendung der gesammelten Daten',
  'dataUse.details':
    'Die von uns gesammelten persönlichen Daten (Name, Vorname, Alter) werden ausschließlich für die Erstellung von Unternehmensbesuchen verwendet. Wir werden Ihre persönlichen Daten ohne Ihre ausdrückliche Zustimmung nicht an Drittpersonen weitergeben, vermieten oder verkaufen. Ihre Daten werden nicht für andere Zwecke verwendet, die nicht speziell mit Ihrer Nutzung unserer Website zusammenhängen.',
  'dataRetention.title': 'Aufbewahrung von Daten',
  'dataRetention.details':
    'Wir bewahren Ihre persönlichen Daten so lange wie nötig auf, um die Ziele zu erreichen, für die sie gesammelt wurden. Wenn Sie sich dazu entschließen, Ihr Konto auf unserer Website zu löschen, werden wir Ihre persönlichen Daten sicher und endgültig gemäß unserer Datenschutzrichtlinie löschen.',
  'dataSecurity.details':
    'Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir setzen angemessene technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre Informationen vor unbefugtem Zugriff, Missbrauch oder Offenlegung zu schützen.',
  'cookiesUse.details':
    'Wir verwenden Cookies und andere ähnliche Technologien, um Ihre Erfahrung auf unserer Website zu verbessern. Diese Cookies helfen uns dabei, die Inhalte, die Sie sehen, zu personalisieren, Sie als Nutzer wiederzuerkennen und unsere Dienstleistungen gemäß Ihrer Präferenzen zu verbessern. Sie können Ihre Cookie-Einstellungen in unserer Cookie-Richtlinie verwalten.',
  'photosVideos.title': 'Fotos und Videos',
  'photosVideos.details':
    'Während FribourgOPEN werden Fotos und/oder Videos gemacht. Diese Fotos und Videos können von der HIKF und den besuchten Unternehmen zu Marketingzwecken verwendet werden (Printpublikationen, Broschüren, ECHO-Magazin, soziale Netzwerke, App und Website usw.). Indem Sie an einem von der HIKF im Rahmen von FribourgOPEN angebotenen Besuch teilnehmen, erlauben Sie der HIKF und den besuchten Unternehmen, die Bilder (Fotografien und/oder Videos), auf denen Sie und/oder Ihre Begleitpersonen zu sehen/erscheinen, kostenlos und ohne Gegenleistung zu verwenden.',
  'privacyPolicy.update':
    'Diese Nutzungsbedingungen können jederzeit ohne Vorankündigung geändert werden. Die Änderungen werden wirksam, sobald sie auf dieser Seite veröffentlicht werden. Es liegt in Ihrer Verantwortung, diese Seite regelmäßig auf mögliche Aktualisierungen zu überprüfen. Durch die Nutzung unserer Website bestätigen Sie, dass Sie diese Nutzungsbedingungen gelesen, verstanden und akzeptiert haben.',
  'registration.minimumAgeDisclaimer':
    'Das Mindestalter für die Teilnahme an diesem Besuch beträgt {{ageLimit}} Jahre. Bei Nichteinhaltung des Mindestalters kann Ihnen der Zugang zum Besuch vom Unternehmen verweigert werden.',
  'registration.minimumAgeDisclaimer.15':
    'Das Mindestalter für die Teilnahme an diesem Besuch beträgt 15 Jahre. Bei Nichteinhaltung des Mindestalters kann Ihnen der Zugang zum Besuch vom Unternehmen verweigert werden.',
  'registration.dateOfBirthPlaceholder': 'Geburstdatum',
  'registration.dateOfBirthError': 'Bitte geben Sie Ihr Geburtsdatum an',
  'registration.firstNamePlaceholder': 'Vorname',
  'registration.lastNamePlaceholder': 'Name',
  'registration.emailPlaceholder': 'E-Mail-Addresse',
  'registration.passwordPlaceholder': 'Passwort',
  'registration.confirmPasswordPlaceholder': 'Passwort bestätigen',
  'mobilityCheck.label': 'Ich bin eine Person mit eingeschränkter Mobilität',
  'termsAcceptance.intro': 'Durch die Erstellung eines Kontos erklären Sie sich mit',
  'termsAcceptance.linkText': 'den Nutzungsbedingungen',
  'termsAcceptance.outro': 'unserer Website einverstanden.',
  'passwordReset.approved': 'Ihr Antrag zur Zurücksetzung des Passworts wurde genehmigt.',
  'passwordReset.emailSent':
    'Eine E-Mail wurde an Ihre E-Mail-Adresse gesendet. Bitte bestätigen Sie, indem Sie auf den Link in der E-Mail klicken, um Ihr Passwort ändern zu können.',
  'passwordReset.continue': 'Weiter',
  'passwordReset.emailPlaceholder': 'E-Mail-Adresse',
  'passwordReset.resetButton': 'Passwort zurücksetzen',
  'visits.registration.title': 'Sich für ein Besuch anmelden',
  'visits.registration.occupiedPlaces': 'Belegte Plätze: {{nTakenPlaces}} / {{nPlaces}}',
  'visits.registration.notSuitableForLimitedMobility':
    'Diese Visite ist nicht für Personen mit eingeschränkter Mobilität geeignet',
  'visits.registration.description': 'Beschreibung',
  'visits.registration.restrictions': 'Einschränkungen',
  'visits.registration.accompanying': 'Begleitpersonen (maximum 3)',
  'visits.registration.continue': 'Weiter',
  'popup.scheduleConflict': 'Sie haben bereits einen Besuch in demselben Zeitfenster geplant.',
  'popup.modifyVisits': 'meine Besuche ändern',
  'popup.cancel': 'Stornieren',
  'visits.register': 'Sich für ein Besuch anmelden',
  'visits.differentActivitiesNotice':
    'Dieses Unternehmen bietet verschiedene Aktivitäten an. Unter jedem Zeitfenster finden Sie eine detaillierte Beschreibung der angebotenen Aktivität.',
  'visits.lastMinuteVisitsNotice':
    'Leider kann unser Unternehmen aufgrund von Last-Minute-Ereignissen Sie nicht unterbringen.',
  'visits.closedRegistrationsInfo':
    'Info: die Anmeldungen für die Besuche am Mittwoch, Donnerstag und Freitag sind abgeschlossen und hier nicht mehr sichtbar.',
  'visits.completeVisit': 'Die Besichtigung ist ausgebucht.',
  'visits.occupiedPlaces': 'Belegte Plätze: {{nTakenPlaces}} / {{nPlaces}}',
  'visit.disclaimer':
    'Bitte rufen Sie die MediaParc-Seite auf, um eine Besichtigung in diesem Unternehmen zu reservieren.',
  'loading.visits': 'Laden der Besuche...',
  'sidebar.contact': 'Uns kontaktieren',
  'sidebar.companyInfo': 'Handels- und Industriekammer des Kantons Freiburg (HIKF)',
  'sidebar.address': 'Route du Jura 37B / PF 160',
  'sidebar.location': 'CH - 1701 Freiburg',
  'sidebar.learnMore': 'Mehr über die HIKF und ihre Aktivitäten',
  'visitsForm.includeSelf': 'Ich nehme an der Besichtigung teil',
  'visitsForm.cannotAddMore': 'Sie können keine weiteren Begleitpersonen hinzufügen. Die Besichtigung ist ausgebucht',
  'visitsForm.add': 'Hinzufügen',
  'errorMessages.maxCompanions': 'Sie können nicht mehr als 3 Begleitpersonen hinzufügen.',
  'errorMessages.emptyName': 'Name und Vorname dürfen nicht leer sein oder nur aus Leerzeichen bestehen.',
  'errorMessages.invalidBirthDate': 'Die Person kann nicht am selben Tag oder in der Zukunft geboren sein.',
  'competition.howToParticipate': 'Wie nehme ich teil?',
  'competition.selfieInstructions':
    'Machen Sie ein Selfie, allein oder mit anderen Personen, vor dem Plakat von FribourgOPEN, das sich in den Räumlichkeiten des Unternehmens befindet, das Sie besuchen, und veröffentlichen Sie das Selfie auf Facebook, Instagram oder LinkedIn. Verwenden Sie dabei den Hashtag #FribourgOPEN und erwähnen Sie @CCIF.',
  'competition.facebook': 'Facebook : @CCIF – HIKF',
  'competition.instagram': 'Instagram : @ccif.hikf',
  'competition.linkedin': 'LinkedIn : @CCIF -  Handels- und Industriekammer des Kantons Freiburg',
  'competition.drawNotice': 'Die Gewinnerin oder der Gewinner wird ausgelost und persönlich benachrichtigt.',
  'competition.goodLuck': 'Viel Glück!',
  'competition.rules': 'Wettbewerbsreglement',
  'placeholders.searchCompany': 'Ein Unternehmen suchen…',
  'placeholders.searchJob': 'Einen Beruf suchen...',
  'mapSelection.map': 'Karte',
  'alert.success': 'Ihre Anmeldung wurde erfolgreich genehmigt.',
  'alert.error': 'Ihre Anmeldung konnte nicht berücksichtigt werden. (code: {{Error}})',
  'companyType.training': 'Ausbildungsbetrieb',
  'validation.lastName.min': 'Der Nachname muss mindestens 3 Zeichen lang sein',
  'validation.lastName.max': 'Darf nicht länger als 20 Zeichen sein',
  'validation.lastName.required': 'Der Nachname ist erforderlich',
  'validation.firstName.min': 'Der Vorname muss mindestens 3 Zeichen lang sein',
  'validation.firstName.max': 'Darf nicht länger als 20 Zeichen sein',
  'validation.firstName.required': 'Der Vorname ist erforderlich',
  'validation.email.email': 'Die E-Mail-Adresse muss gültig sein',
  'validation.email.required': 'Die E-Mail-Adresse ist erforderlich',
  'validation.password.min': 'Das Passwort muss mindestens 8 Zeichen lang sein',
  'validation.password.max': 'Darf nicht länger als 20 Zeichen sein',
  'validation.password.required': 'Das Passwort ist erforderlich',
  'validation.confirmPassword.oneOf': 'Die Passwörter müssen übereinstimmen',
  'validation.confirmPassword.required': 'Die Bestätigung des Passworts ist erforderlich',
  'validation.language.required': 'Die Sprache ist erforderlich',
  'validation.isMobilityLimited.required': 'Der Wert der Mobilität ist erforderlich',
  'validation.dateOfBirth.required': 'Das Geburtsdatum ist erforderlich',
  'validation.dateOfBirth.max': 'Das Geburtsdatum kann nicht in der Zukunft liegen',
  'validation.dateOfBirth.futureDate': 'Das Geburtsdatum darf nicht dem heutigen Datum entsprechen',
  'news.readMore': 'Mehr erfahren',
  'resetPassword.title': 'Passwort zurücksetzen',
  'resetPassword.subtitle': 'Bitte geben Sie Ihr neues Passwort ein.',
  'forgotPassword.title': 'Anmelden',
  'forgotPassword.subtitle': 'Melden Sie sich an, um auf alle Funktionen unserer Website zugreifen zu können.',
  'practicalInfo.title': 'Nützliche Informationen',
  'practicalInfo.when.title': 'Wann?',
  'practicalInfo.dates': 'Vom 27. bis 30. September 2023',
  'practicalInfo.when.details': 'Mittwoch, Donnerstag und Freitag von 14.00 bis 19.00 Uhr.',
  'practicalInfo.when.saturday': 'Samstag von 8.00 bis 13.00 Uhr',
  'practicalInfo.where.title': 'Wo?',
  'practicalInfo.where.details': 'Im ganzen Kanton',
  'practicalInfo.who.title': 'Für wen?',
  'practicalInfo.who.details':
    'Allgemeines Publikum, Lehrlinge, Hochschulabsolventen, Berufstätige, Rentner, Familien, Neugierig',
  'practicalInfo.how.title': 'Wie?',
  'practicalInfo.how.details': 'Nur mit Anmeldung, kostenlos, begrenzte Anzahl an Plätzen',
  'sideBar.utils.help': 'Ich brauche Hilfe',
  'sideBar.nav.myInfo': 'Meine Informationen',
  'homepage.mainCard.title': 'FribourgOPEN 2024 est enfin annoncé!',
  'homepage.mainCard.body': 'Entdecken Sie jetzt das Programm und die Neuheiten.',
  'homepage.mainCard.button': 'Mehr erfahren',
  'footer.conf': 'Vertraulichkeit',
  'footer.cookies': 'Cookies',
  'companyPage.breadcrumb': 'Unternehmen',
  'companyPage.list': 'Liste',
  'companyPage.map': 'Karte',
  'companyPage.filter.activities': 'Tätigkeitsbereiche',
  'homepage.main.tag': 'Ankündigung',
  'registration.title': 'Kontoerstellung',
  'registration.desc': 'Treten Sie jetzt bei, um von all unseren Dienstleistungen zu profitieren',
  'form.profile.mobility.title': 'Zugang',
  'registration.terms.1': 'Durch die Erstellung Ihres Kontos akzeptieren Sie die ',
  'registration.terms.2': 'Nutzungsbedingungen der Website.',
  'registration.success': 'Konto erstellen',
  'registration.haveAcc': 'Ich habe bereits ein FribourgOPEN-Konto',
  'registration.terms.title': 'Zustimmung zur Datenverwendung',
  'registration.terms.body':
    'Durch die Erstellung eines Kontos auf unserer Plattform erklären Sie sich mit der Verwendung Ihrer Daten gemäß unserer Datenschutzrichtlinie einverstanden. Wir verpflichten uns, Ihre Informationen streng vertraulich zu behandeln und sie nur im legitimen Rahmen unserer Dienstleistungen zu verwenden. Weitere Informationen darüber, wie wir Ihre Privatsphäre schützen, finden Sie in unserer Datenschutzrichtlinie auf unserer Website.',
  'registration.terms.checkbox': 'Ich akzeptiere die Datenschutzrichtlinie',
  'registration.terms.next': 'Weiter',
  'registration.modal.success.title': 'Sie sind registriert!',
  'registration.modal.success.body': 'Bitte überprüfen Sie Ihren Posteingang, um Ihr Konto zu bestätigen.',
  'home.news.readMore': 'Lesen',
  'company.page.return': 'Zurück',
  'company.page.access': 'Zugänglich für Personen mit eingeschränkter Mobilität',
  'company.page.site': 'Website besuchen',
  'company.page.about': 'Über',
  'company.page.media': 'Bilder',
  'company.page.jobs': 'Berufe',
  'company.page.activity': 'Geschäftsfelder',
  'company.page.visit.sign': 'Für einen Besuch anmelden',
  'company.page.visit.body': 'Beschreibung',
  'company.page.visit.more': 'Fortsetzung der Anmeldung',
  'company.page.visit.contact': 'Kontakt',
  'company.page.visit.job': 'Technischer Leiter',
  'company.page.visit.progress': 'Verbleibende Plätze: {{min}} / {{max}}',
  'company.page.visit.dropdown': 'Wählen Sie eine Zeit aus',
  'company.page.media.fav': 'Zu meinen Favoriten hinzufügen',
  'company.page.media.share': 'Teilen',
  'company.page.visit.modal.body': 'Bestätigen und abschließen Ihrer Anmeldung für einen Besuch',
  'company.page.visit.modal.access': 'Barrierefreiheit',
  'company.page.visit.modal.guide': 'Geführte Tour',
  'company.page.visit.modal.step.1': 'Zusammenfassung',
  'company.page.visit.modal.step.2': 'Begleitung(en)',
  'company.page.visit.modal.step.3': 'Bestätigung',
  'company.page.visit.modal.step.2.title':
    'Sie können maximal 6 Begleiter hinzufügen, abhängig von den verfügbaren Plätzen in Ihrem Besuch!',
  'popup.readmore': 'Mehr lesen',
  'company.page.visit.table.age': 'Alter',
  'company.page.visit.table.addNew': 'Begleiter hinzufügen ({{min}}/{{max}})',
  'company.page.visit.step4.title': 'Warum besuchen Sie dieses Unternehmen?',
  'company.page.visit.step4.body': 'Wählen Sie die Kästchen aus, die Ihre Interessen widerspiegeln.',
  'company.page.visit.step4.other': 'Bitte angeben',
  'news.date': 'Veröffentlicht am {{day}} {{month}} {{year}}',
  'companiesList.search': 'Unternehmen suchen',
  'user.sidebar.questions': 'FAQ',
  'companyPage.showModal': 'Anmeldung',
  'profile.page.title': 'Profil bearbeiten',
  'profile.page.desc': 'Kontrollieren und aktualisieren Sie Ihre persönlichen Informationen',
  'profile.page.old.password': 'Altes Passwort',
  'profile.page.submit': 'Aktualisieren',
  'profile.page.delete': 'Mein Konto löschen',
  'profile.page.delete.confirm': 'Sind Sie sicher, dass Sie Ihr Konto löschen möchten?',
  'profile.page.delete.acc': 'Löschen',
  'visits.page.company': 'Unternehmen',
  'visits.page.date': 'Datum & Uhrzeit',
  'visits.page.location': 'Ort',
  'visits.page.companion': 'Begleiter',
  'companyPage.filters.datepicker.reset': 'Zurücksetzen',
  'visit.page.edit.title': 'Reservierung',
  'visit.page.edit.body': 'Bearbeiten oder stornieren Sie eine Reservierung',
  'visit.page.edit.button': 'Die Buchung aktualisieren',
  'companyPage.visit.alert.timeOverlaps':
    'Sie sind bereits für diese Tour angemeldet, bitte wählen Sie eine andere aus.',
  'passwordReset.password.confirmation': 'Passwortbestätigung',
  'passwordReset.password.confirmation.alert': 'Die Passwörter stimmen nicht überein',
  'passwordReset.title': 'Ihr Passwort zurücksetzen',
  'passwordReset.body': 'Geben Sie Ihre verwendete E-Mail-Adresse ein.',
  'form.phonenumber': 'Telefonnummer (optional)',
  'passwordReset.modal.title': 'Wiederherstellungs-E-Mail gesendet',
  'passwordReset.modal.body': 'Bitte überprüfen Sie Ihr Postfach, um Ihr Passwort zurückzusetzen.',
  'companyPage.share.alert': 'Link kopiert!',
  'favorites.list.empty': 'Favoritenliste ist leer',
  'visits.list.empty': 'Die Terminliste ist leer',
  'companies.list.empty': 'Die Liste der Unternehmen ist leer',
  'notfound.title': 'Seite nicht gefunden',
  'notfound.body': 'Die Seite, die Sie aufrufen wollen, existiert nicht.',
  'notfound.return': 'Zurück zur Startseite',
  'visit.page.delete': 'Den Besuch löschen',
  'visit.page.delete.title': 'Sind Sie sicher, dass Sie diesen Besuch löschen möchten?',
  'companiesPage.filters.noResults': 'Keine Ergebnisse',
  'companiesPage.filters.activityTypes': 'Leistungsarten',
  'companiesPage.visit.sameVisitError': 'Sie haben versucht, sich zweimal für denselben Besuch anzumelden.',
  'companyPage.visit.success': 'Erfolg! Ihre Anmeldung wurde erfolgreich registriert.',
  'news.page.notFound': 'Es gibt keinen solchen Artikel',
  'validation.old_password': 'Sie müssen das alte Passwort eingeben',
  'registration.minNameCharacters': 'Das Namensfeld muss mindestens 2 Zeichen lang sein.',
  'visit.summary.age': 'Mindestalter: {{age}} Jahre',
  'registration.readMore.policy': 'Datenschutzrichtlinie',
  'modal.login.title':
    'Melden Sie sich an oder erstellen Sie ein Konto, um online auf alle FreiburgOPEN-Dienste zugreifen zu können.',
  'error.unknown': 'Unbekannter Fehler',
  'events.disabled': 'Derzeit sind keine Veranstaltungen aktiv.',
  'companyPage.filters.label': 'Filter',
  'new.companion.title': 'Neuer Begleiter',
  'company.page.visit.noplaces': 'Derzeit sind keine Plätze verfügbar.',
  'company.page.novisits': 'Derzeit sind keine Besuche verfügbar.',
  'company.page.visit.novisits': 'Derzeit sind keine Besuche verfügbar.',
  'companyPage.registration.closed': 'Eine Anmeldung zu diesem Besuch ist derzeit nicht möglich.',
};

export default translationDE;
