import React, { useEffect, useState } from 'react';
import Flex from '../../../../Components/Flex/Flex';
import AppText from '../../../../Components/Text/AppText';
import { ActiveSelectorItem, ItemsWrapper, SelectorItem } from './styled';
import Dropdown from '../../../../Components/Dropdown';
import { useTranslation } from 'react-i18next';

function ParamsDropdown({ options, onItemClick, displayList, value, label }) {
  const [selectedTags, setSelectedTags] = useState(new Set(value));
  const { i18n } = useTranslation();

  useEffect(() => {
    setSelectedTags(new Set(value));
  }, [value]);

  const handleItemClick = (tag) => {
    const newSelectedTags = new Set(selectedTags);
    if (selectedTags.has(tag)) {
      newSelectedTags.delete(tag);
    } else {
      newSelectedTags.add(tag);
    }
    setSelectedTags(newSelectedTags);
    onItemClick(newSelectedTags);
  };

  const isItemSelected = (tag) => {
    return selectedTags.has(tag);
  };

  return displayList ? (
    <ItemsWrapper>
      {options.length === 0 && (
        <AppText
          text={'companiesPage.filters.noResults'}
          textAlign={'center'}
          fontWeight={'900'}
          color={'GrayBlue50'}
          fontSize={'xs'}
          style={{ margin: '0 0 20px 0' }}
        />
      )}
      {options.map((el) => (
        <Flex
          key={el.id}
          onClick={() => handleItemClick(el.id)}
          sx={{ margin: '0 0 20px 0', cursor: 'pointer' }}
          alignItems={'center'}
          gap={'20px'}
        >
          {isItemSelected(el.id) ? <ActiveSelectorItem /> : <SelectorItem />}
          <AppText
            text={el?.name ? el.name[i18n.language] : el.activity_type[i18n.language]}
            fontWeight={'900'}
            color={'GrayBlue50'}
            fontSize={'xs'}
          />
        </Flex>
      ))}
    </ItemsWrapper>
  ) : (
    <Dropdown label={label} margin={'30px 0 0 0'} selectedTagsSize={selectedTags.size}>
      <ItemsWrapper>
        {options.map((el) =>
          el?.name || el?.activity_type ? (
            <Flex
              key={el.id}
              onClick={() => handleItemClick(el.id)}
              sx={{ margin: '0 0 20px 0', cursor: 'pointer' }}
              alignItems={'center'}
              gap={'20px'}
            >
              {isItemSelected(el.id) ? <ActiveSelectorItem /> : <SelectorItem />}
              <AppText
                text={el?.name ? el.name[i18n.language] : el?.activity_type[i18n.language]}
                fontWeight={'900'}
                color={'GrayBlue50'}
                fontSize={'xs'}
              />
            </Flex>
          ) : null
        )}
      </ItemsWrapper>
    </Dropdown>
  );
}

export default ParamsDropdown;
