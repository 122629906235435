import React, { useContext } from 'react';
import { GridContainer } from './styled';
import CompanyCard from './components/CompanyCard';
import AppText from '../../../../Components/Text/AppText';
import { EventContext } from '../../../../context/EventContext';

const CompaniesList = ({ companies }) => {
  return (
    <GridContainer>
      {companies.length > 0 && companies.map((company) => <CompanyCard company={company} />)}
    </GridContainer>
  );
};

export default CompaniesList;
