import React from 'react';
import AppText from '../Text/AppText';

const getColor = (percentage) => {
  if (percentage <= 33) {
    return '#29CC39';
  } else if (percentage <= 66) {
    return '#D9A200';
  } else {
    return '#B20000';
  }
};

const ProgressBar = ({ min, max }) => {
  const minValid = min < 0 ? 0 : min;
  const percentage = (((max - minValid) / max) * 100).toFixed(2);

  return (
    <div style={{ marginTop: '20px' }}>
      <div style={{ width: '100%', backgroundColor: '#3361FF1A', height: '5px', borderRadius: '5px' }}>
        <div
          style={{
            width: `${percentage}%`,
            height: '100%',
            backgroundColor: getColor(percentage),
            borderRadius: '5px',
          }}
        ></div>
      </div>
      <AppText
        text={'company.page.visit.progress'}
        textParams={{ min: minValid, max }}
        color={'GrayBlue70'}
        fontWeight={'900'}
        fontSize={'as'}
        style={{ marginTop: '10px' }}
      />
    </div>
  );
};

export default ProgressBar;
