import './login.scss';
import LoginFormCard from '../../Components/LoginFormCard';

const Login = () => {
  return (
    <div className="login-container login-box">
      <div className="form-section">
        <LoginFormCard />
      </div>
    </div>
  );
};

export default Login;
