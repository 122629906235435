import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { PlayerWrapper } from './styled';
import play from '../../assets/icons/play.svg';
import pause from '../../assets/icons/pause.svg';
import muted from '../../assets/icons/volume_off.svg';
import unmuted from '../../assets/icons/volume_up.svg';
import AppButton from '../Button/AppButton';
import { shouldAutoPlay } from './utils';

const VideoPlayer = ({ mediaUrl, cropped = false }) => {
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    setIsPlaying(shouldAutoPlay());
  }, []);

  const playVideo = () => {
    setIsPlaying(true);
    if (playerRef.current && playerRef.current.getInternalPlayer()) {
      playerRef.current.getInternalPlayer().play();
    }
  };

  const pauseVideo = () => {
    setIsPlaying(false);
    if (playerRef.current && playerRef.current.getInternalPlayer()) {
      playerRef.current.getInternalPlayer().pause();
    }
  };

  const toggleMute = () => {
    setIsMuted((prevState) => !prevState);
  };

  return (
    <PlayerWrapper onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <ReactPlayer
        url={mediaUrl}
        playing={isPlaying}
        ref={playerRef}
        controls={false}
        width="100%"
        height="100%"
        muted={isMuted}
        loop
        style={{ position: 'absolute', top: 0, left: 0 }}
      />
      {!isPlaying && (
        <AppButton
          icon={play}
          styleType={'orange'}
          size={cropped ? 'm' : 'xl'}
          styles={{
            position: 'absolute',
            top: cropped ? 'calc(50% - 25px)' : 'calc(50% - 30px)',
            left: cropped ? 'calc(50% - 20px)' : 'calc(50% - 30px)',
          }}
          onClick={playVideo}
        />
      )}
      {isHover && isPlaying && (
        <AppButton
          icon={pause}
          styleType={'orange'}
          size={cropped ? 'm' : 'xl'}
          styles={{
            position: 'absolute',
            top: cropped ? 'calc(50% - 25px)' : 'calc(50% - 30px)',
            left: cropped ? 'calc(50% - 20px)' : 'calc(50% - 30px)',
          }}
          onClick={pauseVideo}
        />
      )}
      {isHover && (
        <AppButton
          icon={isMuted ? muted : unmuted}
          styleType={'orange'}
          size={'xl'}
          styles={{ position: 'absolute', bottom: '40px', right: '40px', display: cropped && 'none' }}
          onClick={toggleMute}
        />
      )}
    </PlayerWrapper>
  );
};

export default VideoPlayer;
