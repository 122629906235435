import store_mall_directory from '../../assets/icons/store_mall_directory.svg';
import timer from '../../assets/icons/timer.svg';
import { formatDate } from '../../helpers/date';
import location_on from '../../assets/icons/location_on_gray.svg';
import people from '../../assets/icons/people.svg';

export const columns = (i18n) => [
  {
    id: 'comp',
    title: 'visits.page.company',
    icon: store_mall_directory,
    dataKey: (item) => JSON.parse(item.company_name)[i18n.language],
    sorting: (sortDirection) => (a, b) => {
      const nameA = JSON.parse(a.company_name)[i18n.language].toLowerCase();
      const nameB = JSON.parse(b.company_name)[i18n.language].toLowerCase();

      if (sortDirection === 'des') {
        if (nameA < nameB) return 1;
        if (nameA > nameB) return -1;
        return 0;
      } else {
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      }
    },
  },
  {
    id: 'date',
    title: 'visits.page.date',
    icon: timer,
    dataKey: ({ visit_date, visit_startTime, visit_endTime }) => {
      const { day, month, year } = formatDate(visit_date, i18n.language);
      return `${day} ${month} ${year} ${visit_startTime}-${visit_endTime}`;
    },
    sorting: (sortDirection) => (a, b) => {
      const visitDateA = new Date(a.visit_date);
      const visitDateB = new Date(b.visit_date);

      const [hoursA, minutesA] = a.visit_startTime.split(':');
      const [hoursB, minutesB] = b.visit_endTime.split(':');

      visitDateA.setHours(hoursA);
      visitDateA.setMinutes(minutesA);
      visitDateB.setHours(hoursB);
      visitDateB.setMinutes(minutesB);

      if (sortDirection === 'des') {
        return new Date(visitDateB) - new Date(visitDateA);
      } else {
        return new Date(visitDateA) - new Date(visitDateB);
      }
    },
    dontTranslate: true,
  },
  {
    id: 'loc',
    title: 'visits.page.location',
    icon: location_on,
    dataKey: 'full_address',
    sorting: (sortDirection) => (a, b) => {
      const nameA = a.full_address.toLowerCase();
      const nameB = b.full_address.toLowerCase();

      if (sortDirection === 'des') {
        if (nameA < nameB) return 1;
        if (nameA > nameB) return -1;
        return 0;
      } else {
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      }
    },
  },
  {
    id: 'peo',
    title: 'visits.page.companion',
    icon: people,
    dataKey: (item) => item?.people_count - 1,
    sorting: (sortDirection) => (a, b) => {
      const nameA = a.people_count;
      const nameB = b.people_count;

      if (sortDirection === 'des') {
        if (nameA < nameB) return 1;
        if (nameA > nameB) return -1;
        return 0;
      } else {
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      }
    },
  },
];
