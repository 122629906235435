import React, { createContext, useState } from 'react';

const CompanyPageRedirectContext = createContext();

const MyCompanyPageRedirectProvider = ({ children }) => {
  const [companyId, setCompanyId] = useState(null);

  return (
    <CompanyPageRedirectContext.Provider value={[companyId, setCompanyId]}>
      {children}
    </CompanyPageRedirectContext.Provider>
  );
};

export { CompanyPageRedirectContext, MyCompanyPageRedirectProvider };
