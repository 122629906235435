import React, { useContext, useEffect, useState } from 'react';
import '../Login/login.scss';
import { FormCard } from '../../Components/Form/FormCard';
import AppText from '../../Components/Text/AppText';
import Input from '../../Components/Form/Input';
import FormError from '../../Components/Form/FormError';
import AppButton from '../../Components/Button/AppButton';
import { StyledForm } from '../../Components/Form/styled';
import withForm from '../../hoc/withForm';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';

const handleCreatePassword = (formData, setResponseError, authToken, passwordToken, nav, lang) => {
  const body = {
    password: formData.password,
    password_confirmation: formData.password_confirm,
    email: formData.email,
    token: passwordToken,
  };

  const requestOptions = {
    method: 'post',
    url: process.env.REACT_APP_API_URL + '/password/reset',
    headers: { 'Content-Type': 'application/json' },
    params: {
      lang,
    },
    data: JSON.stringify(body),
  };

  axios(requestOptions.url, requestOptions)
    .then(() => {
      nav('/login');
    })
    .catch((error) => {
      console.log(error);
      setResponseError(error.response.data.message);
    });
};

const validate = (formData) => {
  const errors = {};
  if (formData.password.length < 8) {
    errors.password = 'validation.password.min';
  }
  if (formData.password.length >= 20) {
    errors.password = 'validation.password.max';
  }
  if (formData.password !== formData.password_confirm) {
    errors.password_confirm = 'passwordReset.password.confirmation.alert';
  }
  if (!/^[\w-]+(\.[\w-]+)*\+?[\w-]+@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)) {
    errors.email = 'validation.email.email';
  }
  return errors;
};

const CreateNewPassword = ({ errors, handleChange, handleSubmit }) => {
  const [responseError, setResponseError] = useState('');
  const [tokenValid, setTokenValid] = useState(false);
  const { token } = useContext(AuthContext);
  const [queryParameters] = useSearchParams();
  const nav = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    const requestOptions = {
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/password/find?token=' + queryParameters.get('token'),
      headers: { 'Content-Type': 'application/json' },
    };
    axios(requestOptions.url, requestOptions)
      .then(() => {
        setTokenValid(true);
      })
      .catch((error) => {
        setTokenValid(false);
        console.log(error);
      });
  }, [token]);

  return (
    <div className="login-container login-box">
      <div className="form-section">
        <FormCard>
          <div className={'title'}>
            <AppText
              text={'resetPassword.title'}
              color={'GrayBlue50'}
              fontSize={'lg'}
              fontWeight={'900'}
              textAlign={'center'}
            />
            <AppText text={'resetPassword.subtitle'} color={'GrayBlue55'} fontSize={'sm'} textAlign={'center'} />
          </div>
          <StyledForm
            onSubmit={(e) => {
              handleSubmit(e, setResponseError, tokenValid, queryParameters.get('token'), nav, i18n.language);
            }}
            style={{ margin: '0 auto' }}
          >
            <Input title={'form.profile.email'} name={'email'} error={errors.email} onChange={handleChange} required />
            <Input
              title={'login.placeholder.password'}
              type={'password'}
              name={'password'}
              error={errors.password}
              onChange={handleChange}
              required
            />
            <Input
              title={'passwordReset.password.confirmation'}
              type={'password'}
              name={'password_confirm'}
              error={errors.password_confirm}
              onChange={handleChange}
              required
            />
            <FormError message={responseError} margin={'0 0 20px 30px'} position={'left'} />
            <AppButton type={'submit'} text={'popup.connexion.loginButton'} size={'xl'} />
          </StyledForm>
        </FormCard>
      </div>
    </div>
  );
};

export default withForm(CreateNewPassword, { email: '' }, handleCreatePassword, validate);
