import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import password from '../../../assets/icons/password.svg';
import error_icon from '../../../assets/icons/error_icon.svg';
import AppText from '../../Text/AppText';
import IconButton from '../../IconButton';
import Flex from '../../Flex/Flex';
import AppIcon from '../../Icon/AppIcon';
import { useTranslation } from 'react-i18next';
import { useDebounce } from '../../../hooks/useDebounce';

const InputContainer = styled.div`
  position: relative;
  text-align: left;
  width: 100%;
  margin: ${({ margin }) => margin || 'unset'};
`;

const getHeight = (size) => {
  switch (size) {
    case 'large':
      return '60px';
    case 'default':
      return '50px';
    case 'small':
      return '40px';
    default:
      return '50px';
  }
};

const StyledInput = styled.input`
  position: relative;
  padding: ${({ startIcon, endIcon, both }) => {
    if (both) return '20px 50px';
    if (startIcon) return '20px 20px 20px 50px';
    if (endIcon) return '20px 30px 20px 20px';
    return '20px';
  }};
  border-radius: ${({ radius }) => (radius ? '30px' : '5px')};
  box-shadow: 0 2px 5px 0 #26334d08;
  width: 100%;
  border: 2px solid ${(props) => (props.hasError ? '#B20000' : '#EBECF0')};
  outline: none;
  height: ${({ size }) => getHeight(size)};
  text-overflow: ellipsis;

  color: #8c99b3;
  font-family: 'Fopen Midi Black', sans-serif;
  font-size: 12px;

  &:focus {
    border: 4px solid #6688ff;
    outline: none;
    padding: ${({ startIcon, endIcon, both }) => {
      if (both) return '18px 48px';
      if (startIcon) return '18px 18px 18px 48px';
      if (endIcon) return '18px 28px 18px 18px';
      return '18px';
    }};
    color: #4d5e80;
  }
  &::placeholder {
    color: #8c99b3;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const StyledTextField = styled.textarea`
  position: relative;
  padding: ${({ startIcon, endIcon, both }) => {
    if (both) return '20px 30px';
    if (startIcon) return '20px 20px 20px 30px';
    if (endIcon) return '20px 30px 20px 20px';
    return '20px';
  }};
  border-radius: 15px;
  box-shadow: 0 2px 5px 0 #26334d08;
  width: 100%;
  border: 2px solid ${(props) => (props.hasError ? '#B20000' : '#EBECF0')};
  outline: none;
  height: 130px;

  color: #8c99b3;
  font-family: 'Fopen Midi Black', sans-serif;
  font-size: 12px;

  &:focus {
    border: 4px solid #6688ff;
    outline: none;
    padding: ${({ startIcon, endIcon }) => {
      if (startIcon) return '18px 18px 18px 28px';
      if (endIcon) return '18px 28px 18px 18px';
      return '18px';
    }};
    color: #4d5e80;
  }
  &::placeholder {
    color: #8c99b3;
  }
`;

const StyledIcon = styled.span`
  content: '';
  width: 30px;
  height: 30px;
  background-image: url(${password});
  background-size: cover;
  position: absolute;
  right: 25px;
  top: calc(50% - 15px);
`;

const StartStyledIcon = styled.span`
  content: '';
  width: 30px;
  height: 30px;
  background-image: url(${({ icon }) => icon});
  background-size: cover;
  position: absolute;
  z-index: 1;
  left: 16px;
  top: ${({ topStartIcon }) => topStartIcon};
`;

const EndStyledIcon = styled.span`
  content: '';
  width: 30px;
  height: 30px;
  background-image: url(${({ icon }) => icon});
  background-size: cover;
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 7px;
`;

const Input = ({
  title,
  value,
  name,
  type = 'text',
  placeholder,
  onChange,
  error,
  size,
  margin = '0 0 20px 0',
  radius = true,
  topStartIcon = '10px',
  startIcon,
  endIcon,
  onClickEndIcon = () => {},
  debounced = false,
  textField,
  required,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [typeState, setTypeState] = useState(type);
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    handleDebouncedChange(e);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleDebouncedChange = useDebounce(onChange, debounced ? 500 : 0);

  const togglePass = () => {
    setTypeState((prevState) => (prevState === 'text' ? 'password' : 'text'));
  };

  return (
    <InputContainer margin={margin}>
      <AppText
        text={title}
        fontWeight={'900'}
        color={'GrayBlue50'}
        fontSize={'xs'}
        style={{ margin: '0 0 15px 30px ' }}
      />
      <div style={{ width: '100%', position: 'relative' }}>
        {startIcon && (
          <IconButton type={'button'}>
            <StartStyledIcon topStartIcon={topStartIcon} icon={startIcon} />
          </IconButton>
        )}
        {endIcon && <EndStyledIcon icon={endIcon} onClick={onClickEndIcon} />}
        {textField ? (
          <StyledTextField
            type={typeState}
            name={name}
            value={value}
            placeholder={t(placeholder)}
            onChange={onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            hasError={!!error && !isFocused}
            startIcon={!!startIcon}
            endIcon={!!endIcon}
            size={size}
            radius={radius}
            required={required}
            {...rest}
          />
        ) : (
          <StyledInput
            type={typeState}
            name={name}
            value={inputValue}
            placeholder={t(placeholder)}
            onChange={handleInputChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            hasError={!!error && !isFocused}
            startIcon={!!startIcon}
            endIcon={!!endIcon}
            both={!!endIcon && !!startIcon}
            size={size}
            radius={radius}
            required={required}
            {...rest}
          />
        )}
        {type === 'password' && (
          <IconButton onClick={togglePass} type={'button'}>
            <StyledIcon />
          </IconButton>
        )}
      </div>
      <div>
        {error && !isFocused && (
          <Flex gap={'5px'} alignItems={'center'} sx={{ margin: '15px 0 0 30px' }}>
            <AppIcon icon={error_icon} />
            <AppText color={'DarkRed2'} fontSize={'xs'} text={error} />
          </Flex>
        )}
      </div>
    </InputContainer>
  );
};

export default Input;
