import React from 'react';
import styled from 'styled-components';
import { colors } from '../../colors/colors';

const getColor = (color) => {
  return colors[color] || colors.primary;
};

const getBackgroundColor = (color) => {
  const rgbaString = colors[color] || colors.primary;
  const rgbaValues = rgbaString.match(/\d+/g);
  const alpha = 0.1;
  return `rgba(${rgbaValues[0]}, ${rgbaValues[1]}, ${rgbaValues[2]}, ${alpha})`;
};

const getSize = (size) => {
  switch (size) {
    case 'xl':
      return 55;
    case 'l':
      return 45;
    case 'm':
      return 35;
    case 's':
      return 25;
    case 'xs':
      return 15;
    default:
      return 35;
  }
};

const StyledLoader = styled.div`
  border: 4px solid ${({ color }) => getColor(color)};
  border-radius: 50%;
  border-top: 4px solid ${({ color }) => getBackgroundColor(color)};
  width: ${({ size }) => getSize(size)}px;
  height: ${({ size }) => getSize(size)}px;
  animation: spin 1s linear infinite;
  margin: 0 auto;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoaderWrapper = styled.div`
  padding: 90px 45px 60px 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
`;

const Loader = ({ color = '', size, wrapper = true }) => {
  return wrapper ? (
    <LoaderWrapper>
      <StyledLoader color={color} size={size} />
    </LoaderWrapper>
  ) : (
    <StyledLoader color={color} size={size} />
  );
};

export default Loader;
