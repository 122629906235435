const translationFR = {
  'main.header.title':
    "FribourgOPEN sera de retour en 2024, du 18 au 21 septembre (Glâne, Gruyère, Veveyse) et du 25 au 28 septembre (Broye, Lac, Sarine, Singine). Plus d'informations prochainement !",
  'dashboard.cards.title.1': 'FribourgOPEN, des portes ouvertes XXL sur tout le territoire fribourgeois',
  'dashboard.cards.map': 'Carte interactive',
  'nav.visitors': 'Visiteurs',
  'nav.about': 'À propos',
  'nav.discoverCompanies': 'Découvrir les entreprises',
  'welcome.title': 'Bienvenue !',
  'signup.info': 'Fournissez les informations nécessaires pour la création de votre compte',
  'dashboard.cards.map.text': 'Découvrez les entreprises FribourgOPEN',
  'dashboard.cards.text.2': 'Retrouvez les dernières actualités',
  'download.mobile.title': 'Mobile et tablette',
  'download.android.version': 'Nécessite Android OS 7.0 ou supérieur',
  'download.ios.version': 'Nécessite iOS 12.0 ou supérieur',
  'download.android.title': 'Android',
  'download.ios.title': 'IOS',
  'competition.topbar.title': 'Concours',
  'competition.title': "Participez à FribourgOPEN et gagnez un eBike Scott d'une valeur de CHF 4'899.- !",
  'competition.subtitle': 'Grand concours FribourgOPEN',
  'news.title': 'Actualités',
  'home.topbar.title': 'Accueil',
  'home.title': 'Bienvenue sur FribourgOPEN',
  'home.subtitle': 'Du 18 au 21 et du 25 au 28 septembre 2024, découvrez les entreprises du canton !',
  'login.title': 'Connexion',
  'login.text': 'Connectez-vous pour accéder à tous les services FribourgOPEN en ligne',
  'login.dontHaveAcc': 'Je n’ai pas encore de compte FribourgOPEN',
  'login.passwordChange': 'J’ai oublié mon mot de passe',
  'company.title': 'Entreprises',
  'news.text.title': 'Toutes les informations importantes',
  'news.text.subtitle': 'Retrouvez les dernières actualités',
  'favorites.title': 'Mes favoris',
  'favorites.text.title': 'Vue d’ensemble de vos favoris',
  'favorites.text.subtitle': 'Retrouvez toutes les entreprises qui ont attiré votre attention',
  'dl.title': 'Téléchargement',
  'dl.text.title': "L'application FribourgOPEN est disponible !",
  'aide.topBarText': 'FàQ',
  'aide.title': 'Questions posées fréquemment',
  'aide.subtitle':
    'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
  'profile.topBarText': 'Mon compte',
  'profile.text.title': 'Informations personnelles',
  'profile.text.subtitle': 'Vous pouvez voir et modifier vos informations personnelles ici',
  'mesVisits.topBarText': 'Mes visites',
  'mesVisits.title': 'Mes visites',
  'mesVisits.subtitle': 'Liste des visites auxquelles vous êtes inscrit(e)',
  'favorites.page.title': 'Liste des Favoris',
  'favorites.empty.text': 'Aucune entreprise enregistrée dans vos favoris.',
  'favorites.list.title': 'Liste des entreprises',
  'download.first.title': "L'application FribourgOPEN bientôt disponible !",
  'download.guide.text': 'Visitez FribourgOPEN.ch/dl sur votre smartphone pour lancer l’installation !',
  'landing.discovery': 'À la découverte des entreprises du canton',
  'landing.date': 'Du 27 au 30 septembre 2023',
  'landing.description':
    'FribourgOPEN est le plus grand événement de portes ouvertes jamais organisé dans le canton de Fribourg. Venez découvrir les entreprises du canton ainsi que leur savoir-faire !',
  'landing.register': 'Inscription',
  'landing.openDoors': 'FribourgOPEN',
  'landing.openDoorsDescription': 'des portes ouvertes XXL sur tout le territoire fribourgeois',
  'landing.registrationsOpen': 'Les inscriptions sont ouvertes.',
  'landing.howToRegister': 'Découvrez ici comment vous inscrire',
  'landing.allCompanies': 'Toutes les entreprises en ',
  'landing.allCompaniesAtGlance': "un coup d'œil",
  'landing.mapDescription':
    'Retrouvez sur la carte interactive les 100 entreprises qui vous ouvriront leurs portes du 27 au 30 septembre 2023',
  'landing.variousCompanies': 'Des entreprises variées ',
  'landing.discover': 'à découvrir',
  'landing.learnMore':
    "Apprenez-en plus sur les entreprises grâce à leur description, galerie photos et vidéos et créez votre liste de favoris avec votre compte personnel en utilisant des filtres par secteur d'activité ou par métier",
  'landing.activitiesToDo': 'Une multitude ',
  'landing.activitiesToDo2': "d'activités à faire",
  'landing.beInspired':
    "Laissez-vous inspirer par les activités à faire durant les quatre jours de l'événement et choisissez autant de visites que vous le souhaitez",
  'landing.visitsBasedOn': 'Des visites en fonction de ',
  'landing.yourWishes': 'vos envies',
  'landing.selectYourVisit': 'Sélectionnez votre visite et inscrivez-vous avec vos accompagnant.es',
  'footer.projectOf': 'Un projet de',
  'footer.contactUs': 'Nous contacter',
  'footer.ccifAddress': "Chambre de commerce et d'industrie du canton de Fribourg (CCIF)",
  'footer.ccifAddressDetails': 'Rte du Jura 37B / CP 160',
  'footer.ccifCity': 'CH - 1701 Fribourg',
  'footer.learnMore': 'En savoir plus sur la CCIF et ses activités',
  'footer.telephone': 'Tel. ',
  'footer.phoneNumber': '+41 26 347 12 20',
  'footer.email': 'Mail.',
  'footer.poweredBy': 'Powered by',
  'userdata.set.success': 'Vos informations ont été modifiées avec succès !',
  'userdata.set.error': "Un problème est survenu lors de l'enregistrement de vos informations.",
  'form.profile.deleteAccountConfirmation': 'Êtes-vous sûr de vouloir supprimer votre compte ?',
  'form.profile.deleteAccountDetail':
    'Cette action entraînera la suppression complète de votre compte et toutes vos visites seront annulées. Cliquez sur continuer pour supprimer votre compte.',
  'form.profile.continue': 'Continuer',
  'form.profile.cancel': 'Annuler',
  'form.profile.firstName': 'Prénom',
  'form.profile.lastName': 'Nom',
  'form.profile.language': 'Langue:',
  'language.select': 'Langue',
  'form.profile.birthDate': 'Année de naissance',
  'form.profile.email': 'Adresse e-mail',
  'form.profile.mobility': 'Ma mobilité/mon accessibilité est réduite',
  'form.profile.saveInfo': 'enregistrer les informations',
  'form.profile.deleteYourAccount': 'Supprimer votre compte',
  'form.profile.french': 'Français',
  'form.profile.german': 'Allemand',
  'form.profile.english': 'Anglais',
  'visit.success.alert': 'Votre visite a été modifiée avec succès.',
  'visit.error.alert': 'Il y a eu une erreur lors de la modification de votre visite. (code :',
  'visits.companions': 'Accompagnant(s) (3 maximum)',
  'visits.description': 'Description de la visite',
  'visits.restrictions': 'Restrictions',
  'visits.info': 'Informations pratiques',
  'visits.visit.completed': 'La visite est complète.',
  'visits.booking': 'Places occupées:  ',
  'visits.empty': 'Aucun événement prévu pour le moment',
  'visits.popup.cancel': 'Êtes-vous sûr de vouloir annuler cette visite ?',
  'visits.popup.cancel.info':
    'Si vous acceptez de supprimer cette visite, elle disparaîtra de la liste des visites et votre inscription sera annulée.',
  'visits.popup.button.apply': 'Confirmer',
  'visits.popup.button.annuler': 'Annuler',
  'section-entreprises.title': 'Les entreprises ouvrent leurs portes',
  'section-entreprises.dates': 'Du 27 au 30 septembre 2023',
  'section-entreprises.paragraph1':
    'FribourgOPEN est une occasion unique, ouverte à toutes les entreprises du canton, de la plus petite à la plus grande, de la plus traditionnelle à la plus technologique, offrant une belle mise en valeur auprès du grand public.',
  'section-entreprises.paragraph2':
    'La CCIF offre à ses entreprises membres la possibilité d’embellir l’image de leur entreprise, de mieux se faire connaître, de présenter leur savoir-faire, leurs différentes professions, de créer des contacts avec les futurs professionnels et d’attirer des talents, en ouvrant leurs portes du 27 au 30 septembre 2023, à choix.',
  'section-entreprises.paragraph3':
    'Les entreprises participantes proposeront un accueil en activité, avec des circuits de visite (pour les plus grandes) ou des présentations au moyen de démonstrations. Si ce n’est pas possible pour des questions de taille ou de place disponible, elle pourra proposer une conférence thématique, un petit atelier pratique, un cours, une activité ludique, une exposition, etc. Ouvrir ses portes, c’est organiser les activités de son choix, en lien avec son cœur de métier.',
  'section-entreprises.paragraph4':
    'Toutes les propositions d’expériences à réaliser par le visiteur, jeunes et adultes, sont à privilégier. Elles suscitent toujours un grand intérêt et la mise en situation concrète valorisera les métiers. La visite de chantiers et/ou projets emblématiques est aussi une aubaine pour mettre en valeur les savoir-faire.',
  'popup.connexion.content': 'Contenu réservé aux utilisateurs connectés',
  'popup.connexion.detail':
    'Si vous désirez participer aux visites proposées, merci de bien vouloir vous connecter ou de vous créer un compte.',
  'popup.connexion.loginButton': 'Se connecter',
  'popup.connexion.cancelButton': 'Annuler',
  'c-presentation.visitWebsite': 'Visiter le site web',
  'c-presentation.inImages': 'En images',
  'c-presentation.professions': 'Corps de métiers',
  'c-filters.deleteFilters': 'Effacer les filtres',
  'c-filters.byCompanyName': "Par nom d'entreprises",
  'c-filters.specificJobs': 'Métiers Spécifiques',
  'c-filters.activitySectors': "Secteurs d'activités",
  'c-filters.trainingCompanies': 'Entreprises formatrices',
  'menu.logout': 'Se déconnecter',
  'menu.login': 'Se connecter',
  'contact.title': 'Nous contacter',
  'contact.address': "Chambre de commerce et d'industrie du canton de Fribourg (CCIF)",
  'contact.addressLine': 'Rte du Jura 37B / CP 160',
  'contact.city': 'CH - 1701 Fribourg',
  'contact.learnMore': 'En savoir plus sur la CCIF et ses activités',
  'contact.tel': 'Tel.',
  'contact.email': 'Mail.',
  'visits.confirmation.title': 'Confirmation',
  'visits.confirmation.subtitle': 'Veuillez confirmer',
  'visits.confirmation.summaryWith': `Résumé de votre inscription pour une visite chez {{companyName}} avec {{inscriptionDataLength}} accompagnants`,
  'visits.confirmation.summaryWithout': `Résumé de votre inscription pour une visite chez {{companyName}} sans accompagnant`,
  'visits.confirmation.date': 'Date',
  'visits.confirmation.time': 'Heure',
  'visits.confirmation.address': 'Adresse',
  'visits.confirmation.cancel': 'Annuler',
  'visits.confirmation.confirm': "Confirmer l'inscription",
  'news.card.subtitle': 'Informations',
  'news.card.title': 'FribourgOpen, les entreprises du canton ouvertes au public',
  'bookmark.remove': 'Retirer des favoris',
  'bookmark.add': 'Ajouter aux favoris',
  'login.error.badCredentials': 'Les informations ne sont pas correctes.',
  'login.placeholder.email': 'Adresse mail',
  'login.placeholder.password': 'Mot de passe',
  'login.button.signIn': 'Se connecter',
  'login.text.notRegistered': 'Pas encore inscrit ? -',
  'login.button.signUp': 'S’inscrire',
  'login.button.forgotPassword': 'Mot de passe oublié ?',
  'passwordChange.success': 'Votre mot de passe a bien été modifié',
  'passwordChange.continue': 'Cliquez sur continuer pour pouvoir vous connecter.',
  'passwordChange.button.continue': 'Continuer',
  'passwordChange.emailPlaceholder': 'Adresse e-mail',
  'passwordChange.newPasswordPlaceholder': 'Nouveau mot de passe',
  'passwordChange.confirmNewPasswordPlaceholder': 'Confirmez votre nouveau mot de passe',
  'passwordChange.confirmButton': 'Confirmer',
  'registration.approved': 'Votre inscription a été approuvée.',
  'registration.emailSent':
    "Un e-mail a été envoyé à votre adresse mail. Merci de confirmer en cliquant sur le lien dans l'e-mail pour pouvoir vous connecter ensuite",
  'registration.continue': 'Continuer',
  'termsOfUse.title': "Termes d'utilisations",
  'registration.welcome':
    'Bienvenue sur FribourgOPEN ! Nous sommes heureux de vous accueillir sur notre plateforme dédiée à la création de visites dans des entreprises.',
  'useOfData.title': "Collecte de données d'utilisateur",
  'useOfData.description':
    'Lors de votre inscription sur FribourgOPEN, nous collectons certaines informations personnelles telles que votre nom, prénom et âge.',
  'dataSecurity.title': 'Sécurité des données',
  'cookies.title': 'Cookies et technologies de suivi',
  'privacyPolicy.title': "Modification des termes d'utilisation",
  'registration.complete': "En créant votre compte vous acceptez les termes d'utilisations de notre site web",
  'registration.signIn': 'Connectez-vous pour accéder à toutes les fonctionnalités de notre site web',
  'registration.notRegistered': 'Pas encore inscrit ? -',
  'registration.signUp': 'S’inscrire',
  'registration.alreadyRegistered': 'Déjà inscrit ? -',
  'registration.login': 'Se connecter',
  'dataUse.title': 'Utilisation des données collectées',
  'dataUse.details':
    "Les données personnelles que nous recueillons (nom, prénom, âge) sont utilisées exclusivement dans le cadre de la création de visites dans les entreprises. Nous ne partagerons, ne louerons, ni ne vendrons vos informations personnelles à des tiers sans votre consentement explicite. Vos données ne seront pas utilisées à d'autres fins que celles spécifiquement liées à votre utilisation de notre site web.",
  'dataRetention.title': 'Conservation des données',
  'dataRetention.details':
    'Nous conservons vos données personnelles aussi longtemps que nécessaire pour atteindre les objectifs pour lesquels elles ont été collectées. Si vous décidez de supprimer votre compte sur notre site, nous supprimerons vos données personnelles de manière sécurisée et définitive, conformément à notre politique de confidentialité.',
  'dataSecurity.details':
    'Nous prenons la sécurité de vos données personnelles très au sérieux. Nous mettons en place des mesures de sécurité techniques et organisationnelles appropriées pour protéger vos informations contre tout accès non autorisé, utilisation abusive ou divulgation.',
  'cookiesUse.details':
    "Nous utilisons des cookies et d'autres technologies similaires pour améliorer votre expérience sur notre site web. Ces cookies nous aident à personnaliser le contenu que vous voyez, à vous reconnaître en tant qu'utilisateur récurrent et à améliorer nos services en fonction de vos préférences. Vous pouvez gérer vos préférences en matière de cookies en consultant notre politique de cookies.",
  'photosVideos.title': 'Photos et vidéos',
  'photosVideos.details':
    'Durant FribourgOPEN, des photos et/ou des vidéos seront réalisées. Ces photos et ces vidéos peuvent être utilisées par la CCIF et les entreprises visitées à des fins marketing (publications papier, brochures, magazine ECHO, réseaux sociaux, application et site internet, etc.). En prenant part à une visite proposée par la CCIF dans le cadre de FribourgOPEN, vous autorisez la CCIF et les entreprises visitées à utiliser gratuitement et sans contrepartie les images (photographies et/ou vidéos) sur lesquelles vous et/ou vos accompagnants apparaissez/apparaissent.',
  'privacyPolicy.update':
    "Nous nous réservons le droit de modifier ces termes d'utilisation à tout moment, sans préavis. Les modifications seront effectives dès leur publication sur cette page. Il est de votre responsabilité de consulter régulièrement cette page pour prendre connaissance des éventuelles mises à jour.",
  'registration.minimumAgeDisclaimer':
    "L'âge minimum pour participer à cette visite est de {{ageLimit}} ans. L'accès à la visite peut vous être refusé par l'entreprise en cas de non-respect de l'âge minimum.",
  'registration.minimumAgeDisclaimer.15':
    "L'âge minimum pour participer à cette visite est de 15 ans. L'accès à la visite peut vous être refusé par l'entreprise en cas de non-respect de l'âge minimum.",
  'registration.dateOfBirthPlaceholder': 'Date de naissance:',
  'registration.dateOfBirthError': 'Veuillez indiquer votre date de naissance',
  'registration.firstNamePlaceholder': 'Prénom',
  'registration.lastNamePlaceholder': 'Nom',
  'registration.emailPlaceholder': 'Adresse mail',
  'registration.passwordPlaceholder': 'Mot de passe',
  'registration.confirmPasswordPlaceholder': 'Confirmez le mot de passe',
  'mobilityCheck.label': 'Je suis une personne à mobilité réduite',
  'termsAcceptance.intro': 'En créant votre compte vous acceptez',
  'termsAcceptance.linkText': "les termes d'utilisations",
  'termsAcceptance.outro': 'de notre site',
  'passwordReset.approved': 'Votre demande de réinitialisation de mot de passe a été approuvée.',
  'passwordReset.emailSent':
    "Un e-mail a été envoyé à votre adresse mail. Merci de confirmer en cliquant sur le lien dans l'e-mail pour pouvoir changer votre mot de passe.",
  'passwordReset.continue': 'Continuer',
  'passwordReset.emailPlaceholder': 'Adresse e-mail',
  'passwordReset.resetButton': 'Réinitialiser le mot de passe',
  'visits.registration.title': "S'inscrire à une visite",
  'visits.registration.occupiedPlaces': 'Places occupées: {{nTakenPlaces}} / {{nPlaces}}',
  'visits.registration.notSuitableForLimitedMobility': 'Cette visite ne convient pas aux personnes à mobilité réduite',
  'visits.registration.description': 'Description',
  'visits.registration.restrictions': 'Restrictions',
  'visits.registration.accompanying': 'Accompagnant(s) (maximum 3)',
  'visits.registration.continue': 'Continuer',
  'popup.scheduleConflict': 'Vous avez déjà planifié une visite dans le même créneau horaire.',
  'popup.modifyVisits': 'Modifier mes visites',
  'popup.cancel': 'Annuler',
  'visits.register': "S'inscrire à une visite",
  'visits.differentActivitiesNotice':
    "Cette entreprise propose différentes activités. Vous trouverez sous chaque créneau la description détaillée de l'activité proposée",
  'visits.lastMinuteVisitsNotice':
    'Notre entreprise ne peut malheureusement pas vous accueillir suite à des évènements de dernière minute.',
  'visits.closedRegistrationsInfo':
    'Information: les inscriptions aux visites prévues les mercredi, jeudi et vendredi sont désormais closes et ne sont plus visibles ici.',
  'visits.completeVisit': 'La visite est complète.',
  'visits.occupiedPlaces': 'Places occupées: {{nTakenPlaces}} / {{nPlaces}}',
  'visit.disclaimer': 'Veuillez vous rendre sur la page de MediaParc pour réserver une visite dans cette entreprise.',
  'loading.visits': 'Chargement des visites...',
  'sidebar.contact': 'Nous contacter',
  'sidebar.companyInfo': "Chambre de commerce et d'industrie du canton de Fribourg (CCIF)",
  'sidebar.address': 'Rte du Jura 37B / CP 160',
  'sidebar.location': 'CH - 1701 Fribourg',
  'sidebar.learnMore': 'En savoir plus sur la CCIF et ses activités',
  'visitsForm.includeSelf': "Je m'inclus dans la visite",
  'visitsForm.cannotAddMore': "Vous ne pouvez pas ajouter plus d'accompagnants. La visite est complète.",
  'visitsForm.add': 'Ajouter',
  'errorMessages.maxCompanions': 'Vous ne pouvez pas ajouter plus de 3 accompagnants.',
  'errorMessages.emptyName': "Le nom et prénom ne peuvent pas être vides ou composés uniquement d'espaces.",
  'errorMessages.invalidBirthDate': 'La personne ne peut pas être née le jour même ou dans le futur.',
  'competition.howToParticipate': 'Comment participer?',
  'competition.selfieInstructions':
    "Prenez un selfie, seul(e) ou à plusieurs, devant l'affiche FribourgOPEN présente dans les locaux de l'entreprise visitée et publiez-le sur Facebook, Instagram ou LinkedIn. Utilisez le hashtag #FribourgOPEN et mentionnez la @CCIF.",
  'competition.facebook': 'Facebook : @CCIF – HIKF',
  'competition.instagram': 'Instagram : @ccif.hikf',
  'competition.linkedin': "LinkedIn : @CCIF - Chambre de commerce et d'industrie du canton de Fribourg",
  'competition.drawNotice': 'Un tirage au sort déterminera le gagnant qui sera averti personnellement.',
  'competition.goodLuck': 'Bonne chance !',
  'competition.rules': 'Règlement du concours',
  'placeholders.searchCompany': 'Rechercher une entreprise...',
  'placeholders.searchJob': 'Rechercher un métier...',
  'mapSelection.map': 'Carte',
  'alert.success': 'Votre inscription à été approuvée avec succès',
  'alert.error': "Votre inscription n'a pas pu être prise en compte. (code: {{Error}})",
  'companyType.training': 'Entreprise formatrice',
  'validation.lastName.min': 'Le nom doit comporter au moins 3 caractères',
  'validation.lastName.max': 'Ne peut pas dépasser 20 caractères',
  'validation.lastName.required': 'Le nom est requis',
  'validation.firstName.min': 'Le prénom doit comporter au moins 3 caractères',
  'validation.firstName.max': 'Ne peut pas dépasser 20 caractères',
  'validation.firstName.required': 'Le prénom est requis',
  'validation.email.email': "L'adresse e-mail doit être valide",
  'validation.email.required': "L'adresse e-mail est requise",
  'validation.password.min': 'Doit comporter au moins 8 caractères',
  'validation.password.max': 'Ne peut pas dépasser 20 caractères',
  'validation.password.required': 'Le mot de passe est requis',
  'validation.confirmPassword.oneOf': 'Les mots de passe doivent être similaires',
  'validation.confirmPassword.required': 'La confirmation du mot de passe est requise',
  'validation.language.required': 'La langue est requise',
  'validation.isMobilityLimited.required': 'La valeur de mobilité est requise',
  'validation.dateOfBirth.required': 'La date de naissance est requise',
  'validation.dateOfBirth.max': "La date de naissance ne peut pas être postérieure à aujourd'hui",
  'validation.dateOfBirth.futureDate': "La date de naissance ne peut pas être égale à aujourd'hui",
  'news.readMore': 'En savoir plus',
  'resetPassword.title': 'Réinitialisation de mot de passe',
  'resetPassword.subtitle': 'Veuillez indiquer votre nouveau mot de passe.',
  'forgotPassword.title': 'Mot de passe oublié',
  'forgotPassword.subtitle':
    'Nous allons vous envoyer un lien de réinitialisation de mot de passe sur votre boîte mail.',
  'practicalInfo.title': 'Informations pratiques',
  'practicalInfo.when.title': 'Quand ?',
  'practicalInfo.dates': 'Du 27 au 30 septembre 2023',
  'practicalInfo.when.details': 'Mercredi, jeudi et vendredi de 14h00 à 19h00',
  'practicalInfo.when.saturday': 'Samedi de 8h00 à 13h00',
  'practicalInfo.where.title': 'Où ?',
  'practicalInfo.where.details': 'Dans tout le canton',
  'practicalInfo.who.title': 'Pour qui ?',
  'practicalInfo.who.details':
    'Grand public, apprenti.es, jeunes diplômé.es, professionnel.les, retraité.es, familles, curieux.ses',
  'practicalInfo.how.title': 'Comment ?',
  'practicalInfo.how.details': 'Sur inscription uniquement, gratuit, nombre de places limité',
  'sideBar.nav.myInfo': 'Mes informations',
  'sideBar.utils.help': 'J’ai besoin d’aide',
  'homepage.mainCard.title': 'FribourgOPEN 2024 est enfin annoncé!',
  'homepage.mainCard.body': 'Découvrez dès maintenant le programme et les nouveautés.',
  'homepage.mainCard.button': 'En savoir plus',
  'footer.conf': 'Confidentialité',
  'footer.cookies': 'Cookies',
  'companyPage.breadcrumb': 'Entreprises',
  'companyPage.list': 'Liste',
  'companyPage.map': 'Carte',
  'companyPage.filter.activities': "Secteurs d'activités",
  'homepage.main.tag': 'Annonce',
  'registration.title': 'Création de compte',
  'registration.desc': 'Rejoignez-nous dès maintenant pour bénéficier de tous nos services',
  'form.profile.mobility.title': 'Accès',
  'registration.terms.1': 'En créant votre compte, vous acceptez les ',
  'registration.terms.2': 'termes d’utilisation du site.',
  'registration.success': 'Créer le compte',
  'registration.haveAcc': 'J’ai déjà un compte FribourgOPEN',
  'registration.terms.title': "Consentement à l'utilisation des données",
  'registration.terms.body':
    "En créant un compte sur notre plateforme, vous consentez à l'utilisation de vos données conformément à notre politique de confidentialité. Nous nous engageons à traiter vos informations avec la plus grande confidentialité et à les utiliser uniquement dans le cadre légitime de nos services. Pour plus de détails sur la manière dont nous protégeons votre vie privée, veuillez consulter notre politique de confidentialité disponible sur notre site.",
  'registration.terms.checkbox': 'J’accepte la potilique de confidentialité',
  'registration.terms.next': 'Suivant',
  'registration.modal.success.title': 'Vous êtes inscrit!',
  'registration.modal.success.body': 'Veuillez consulter votre boîte de réception pour confirmer votre compte.',
  'home.news.readMore': 'Lire',
  'company.page.return': 'Retour',
  'company.page.access': 'Accessible aux personnes à mobilité réduite',
  'company.page.site': 'Visiter le site web',
  'company.page.about': 'A propos',
  'company.page.media': 'En images',
  'company.page.jobs': 'Métiers',
  'company.page.activity': "Secteurs d'activités",
  'company.page.visit.sign': 'S’inscrire à une visite',
  'company.page.visit.body': 'Description',
  'company.page.visit.more': 'Continuer l’inscription',
  'company.page.visit.contact': 'Contact',
  'company.page.visit.job': 'Responsable technique',
  'company.page.visit.progress': 'Places restantes: {{min}} / {{max}}',
  'company.page.visit.dropdown': 'Choisir un horaire',
  'company.page.media.fav': 'Ajouter à mes favoris',
  'company.page.media.share': 'Partager',
  'company.page.visit.modal.body': 'Validez et finalisez votre inscription à une visite',
  'company.page.visit.modal.access': 'Accès handicapé',
  'company.page.visit.modal.guide': 'Visite guidée',
  'company.page.visit.modal.step.1': 'Récapitulatif',
  'company.page.visit.modal.step.2': 'Accompagnant(s)',
  'company.page.visit.modal.step.3': 'Validation',
  'company.page.visit.modal.step.2.title':
    'Vous pouvez ajouter un maximum de 6 accompagnants, dans la limite des places restantes disponibles dans votre visite!',
  'popup.readmore': 'Lire plus',
  'company.page.visit.table.age': 'Âge',
  'company.page.visit.table.addNew': 'Ajouter un accompagnant ({{min}}/{{max}})',
  'company.page.visit.step4.title': 'Dans quel(s) but(s) visitez-vous cette entreprise?',
  'company.page.visit.step4.body': "Sélectionnez les cases qui reflètent vos centres d'intérêt.",
  'company.page.visit.step4.other': 'Précisez',
  'news.date': 'Publié le {{day}} {{month}} {{year}}',
  'companiesList.search': 'Rechercher une entreprise',
  'user.sidebar.questions': 'Foire aux questions',
  'companyPage.showModal': 'Inscription',
  'profile.page.title': 'Modification du compte',
  'profile.page.desc': 'Contrôlez et mettez à jour vos informations personnelles',
  'profile.page.old.password': 'Ancien mot de passe',
  'profile.page.submit': 'Mettre à jour',
  'profile.page.delete': 'Supprimer mon compte',
  'profile.page.delete.confirm': 'Êtes-vous sur de vouloir supprimer votre',
  'profile.page.delete.acc': 'Supprimer',
  'visits.page.company': 'Entreprise',
  'visits.page.date': 'Date & horaire',
  'visits.page.location': 'Lieu',
  'visits.page.companion': 'Accompagnant(s)',
  'companyPage.filters.datepicker.reset': 'Réinitialiser',
  'visit.page.edit.title': 'Réservation',
  'visit.page.edit.body': 'Modifiez ou  annulez une réservation',
  'visit.page.edit.button': 'Mettre à jour la réservation',
  'companyPage.visit.alert.timeOverlaps': 'Vous êtes déjà inscrit à cette visite, veuillez en sélectionner une autre.',
  'passwordReset.password.confirmation': 'Confirmation du mot de passe',
  'passwordReset.password.confirmation.alert': 'Les mots de passe ne correspondent pas',
  'passwordReset.title': 'Trouver votre mot de passe',
  'passwordReset.body': 'Saisissez votre adresse e-mail.',
  'form.phonenumber': 'Numéro de téléphone (facultatif)',
  'passwordReset.modal.title': 'E-mail de récupération envoyé',
  'passwordReset.modal.body':
    'Veuillez consulter votre boîte de réception pour pouvoir réinitialiser votre mot de passe.',
  'companyPage.share.alert': 'Lien copié!',
  'favorites.list.empty': 'La liste des favoris est vide',
  'visits.list.empty': 'La liste de rendez-vous est vide',
  'companies.list.empty': 'La liste des entreprises est vide',
  'notfound.title': 'Page non trouvée',
  'notfound.body': "La page à laquelle vous essayez d'accéder n'existe pas.",
  'notfound.return': "Retourner à la page d'accueil",
  'visit.page.delete': 'Supprimer la visite',
  'visit.page.delete.title': 'Êtes-vous sûr de vouloir supprimer cette visite ?',
  'companiesPage.filters.noResults': 'Pas de résultats',
  'companiesPage.filters.activityTypes': "Types d'activités",
  'companiesPage.visit.sameVisitError': 'Vous avez essayé de vous enregistrer deux fois pour la même visite.',
  'companyPage.visit.success': 'Succès! Votre inscription a bien été enregistrée.',
  'news.page.notFound': "Il n'existe pas d'article de ce type",
  'validation.old_password': "Vous devez saisir l'ancien mot de passe",
  'registration.minNameCharacters': 'Le champ nom doit comporter au moins 2 caractères.',
  'visit.summary.age': 'Âge minimum requis: {{age}} ans',
  'registration.readMore.policy': 'Politique de confidentialité',
  'modal.login.title': 'Connectez-vous ou créez un compte pour accéder à tous les services FribourgOPEN en ligne.',
  'error.unknown': 'Erreur inconnue',
  'events.disabled': "Aucun événement n'est actif pour l'instant.",
  'companyPage.filters.label': 'Filtres',
  'new.companion.title': 'Nouveau compagnon',
  'company.page.visit.noplaces': "Aucune place disponible pour l'instant.",
  'company.page.novisits': 'Aucune visite disponible pour l’instant.',
  'company.page.visit.novisits': 'Aucune visite disponible pour l’instant.',
  'companyPage.registration.closed': "L'inscription à cette visite n'est actuellement pas possible.",
};

export default translationFR;
