import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  position: absolute;
  background: white;
  margin-top: -70px;
  height: 100vh;
  width: ${({ isExpanded }) => (isExpanded ? '370px' : '0px')};
  right: 0;
  top: 80px;
  padding: ${({ isExpanded }) => (isExpanded ? '35px 50px 0 55px' : '35px 0 0 0')};
  z-index: 1000;
  overflow-x: hidden;
  transition:
    width 250ms ease-in-out,
    margin-right 250ms ease-in-out,
    padding-left 250ms ease-in-out,
    padding-right 250ms ease-in-out;
  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const getRadius = (position) => {
  switch (position) {
    case 1:
      return '10px 10px 0 0';
    case 2:
      return '0 0 0 0';
    case 3:
      return '0 0 10px 10px';
    default:
      return 'unset';
  }
};

const getBorder = (position) => {
  switch (position) {
    case 1:
      return '2px 2px 2px 2px';
    case 2:
      return '0 2px 0 2px';
    case 3:
      return '2px 2px 2px 2px';
    default:
      return 'unset';
  }
};

export const NavItem = styled.div`
  padding: 15px;
  display: flex;
  gap: 26px;
  border-radius: ${({ position }) => getRadius(position)};
  border: solid rgba(235, 236, 240, 1);
  border-width: ${({ position }) => getBorder(position)};
`;

export const MobileWrapper = styled.div`
  display: none;
  margin-top: 30px;

  > div:last-child {
    margin-top: 20px;
  }

  @media (max-width: 500px) {
    display: block;
  }
`;

export const UtilsWrapper = styled.div`
  margin-top: 120px;

  & > a:not(:last-child) {
    display: block;
    padding-bottom: 40px;
  }
`;
