import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Language from '../../assets/icons/Language.svg';
import AppText from '../Text/AppText';
import AppIcon from '../Icon/AppIcon';
import styled from 'styled-components';
import de from '../../assets/icons/Germany.svg';
import fr from '../../assets/icons/France.svg';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const MenuWrapper = styled.div`
  position: absolute;
  background: white;
  top: ${({ menuTop }) => menuTop};
  left: ${({ menuLeft }) => menuLeft};
`;

const MenuButton = styled.button`
  position: relative;
  padding: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  background: white;
  z-index: 1000;
  &:first-child {
    border-radius: 5px 5px 0 0;
    border: solid #ebecf0;
    border-width: 2px 2px 0 2px;
  }
  &:last-child {
    border-radius: 0 0 5px 5px;
    border: 2px solid #ebecf0;
  }
`;

const LanguageButtons = ({ className, menuTop = '-30px', menuLeft = '-15px' }) => {
  const { i18n, t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguageText, setSelectedLanguageText] = useState('');

  useEffect(() => {
    const text = t(i18n.language === 'fr' ? 'form.profile.french' : 'form.profile.german');
    if (window.innerWidth < 400) {
      setSelectedLanguageText(text.substring(0, 2));
    } else {
      setSelectedLanguageText(text);
    }
  }, [i18n.language]);

  const handleMenu = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleLanguageChangeFr = () => {
    i18n.changeLanguage('fr');
    localStorage.setItem('language', 'fr');
    setIsOpen((prevState) => !prevState);
  };

  const handleLanguageChangeDe = () => {
    i18n.changeLanguage('de');
    localStorage.setItem('language', 'de');
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Wrapper className={className}>
      <Button onClick={handleMenu}>
        <AppIcon icon={Language} />
        <AppText fontWeight={'900'} text={selectedLanguageText} color={'GrayBlue70'} fontSize={'as'} />
      </Button>
      {isOpen && (
        <MenuWrapper menuTop={menuTop} menuLeft={menuLeft}>
          <MenuButton onClick={handleLanguageChangeFr}>
            <AppIcon icon={fr} />
            <AppText fontWeight={'900'} text={'form.profile.french'} color={'GrayBlue70'} fontSize={'xs'} />
          </MenuButton>
          <MenuButton onClick={handleLanguageChangeDe}>
            <AppIcon icon={de} />
            <AppText fontWeight={'900'} text={'form.profile.german'} color={'GrayBlue70'} fontSize={'xs'} />
          </MenuButton>
        </MenuWrapper>
      )}
    </Wrapper>
  );
};

export default LanguageButtons;
