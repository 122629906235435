import Select from '../../../Select';
import React, { useState } from 'react';
import Flex from '../../../Flex/Flex';
import AppText from '../../../Text/AppText';

const MIN_AGE = 15;

const getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

const getCurrentYear = () => {
  return new Date().getFullYear();
};

const DateSelector = ({
  onFormChange,
  title,
  margin = '0 0 20px 0',
  initialSelectedDay,
  initialSelectedMonth,
  initialSelectedYear,
  minAge = null,
}) => {
  const [selectedDay, setSelectedDay] = useState(initialSelectedDay || 1);
  const [selectedMonth, setSelectedMonth] = useState(initialSelectedMonth || 1);
  const [selectedYear, setSelectedYear] = useState(initialSelectedYear || getCurrentYear());
  const [daysInMonth, setDaysInMonth] = useState(31);

  const handleDayChange = (value) => {
    setSelectedDay(value.target.value);
    onFormChange(value);
  };

  const handleMonthChange = (value) => {
    setSelectedMonth(value.target.value);
    const days = getDaysInMonth(value.target.value, selectedYear);
    if (selectedDay > days) {
      setSelectedDay(days);
    }
    setDaysInMonth(days);
    onFormChange(value);
  };

  const handleYearChange = (value) => {
    setSelectedYear(value.target.value);
    const days = getDaysInMonth(selectedMonth, value.target.value);
    if (selectedDay > days) {
      setSelectedDay(days);
    }
    setDaysInMonth(days);
    onFormChange(value);
  };

  const generateDays = (daysCount) => {
    const days = [];
    for (let i = 1; i <= daysCount; i++) {
      days.push({ name: i.toString(), value: i });
    }
    return days;
  };

  const generateMonths = () => {
    return [
      { name: { fr: 'Janvier', de: 'Januar' }, value: 1 },
      { name: { fr: 'Février', de: 'Februar' }, value: 2 },
      { name: { fr: 'Mars', de: 'März' }, value: 3 },
      { name: { fr: 'Avril', de: 'April' }, value: 4 },
      { name: { fr: 'Mai', de: 'Mai' }, value: 5 },
      { name: { fr: 'Juin', de: 'Juni' }, value: 6 },
      { name: { fr: 'Juillet', de: 'Juli' }, value: 7 },
      { name: { fr: 'Août', de: 'August' }, value: 8 },
      { name: { fr: 'Septembre', de: 'September' }, value: 9 },
      { name: { fr: 'Octobre', de: 'Oktober' }, value: 10 },
      { name: { fr: 'Novembre', de: 'November' }, value: 11 },
      { name: { fr: 'Décembre', de: 'Dezember' }, value: 12 },
    ];
  };

  const generateYears = () => {
    const currentYear = getCurrentYear();
    const years = [];
    const maxYears = 100;
    const age = minAge === null ? MIN_AGE : minAge;
    for (let i = currentYear - age; i >= currentYear - maxYears; i--) {
      years.push({ name: i.toString(), value: i });
    }
    return years;
  };

  return (
    <div style={{ margin: margin, width: '100%' }}>
      <AppText
        text={title}
        fontWeight={'900'}
        color={'GrayBlue50'}
        fontSize={'xs'}
        style={{ margin: '0 0 15px 30px ', textAlign: 'left' }}
      />
      <Flex gap={'30px'}>
        {/*<Select
          label="Day"
          name={'day'}
          options={generateDays(daysInMonth)}
          value={selectedDay}
          onChange={handleDayChange}
        />
        <Select
          label="Month"
          name={'month'}
          options={generateMonths()}
          value={selectedMonth}
          onChange={handleMonthChange}
          multilang
        />*/}
        <Select
          label="Year"
          name={'year'}
          options={generateYears()}
          value={selectedYear}
          onChange={handleYearChange}
          style={{ width: '100%', minWidth: '117px' }}
        />
      </Flex>
    </div>
  );
};

export default DateSelector;
