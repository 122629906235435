import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import AppText from '../../../../../Components/Text/AppText';
import AppIcon from '../../../../../Components/Icon/AppIcon';
import { useTranslation } from 'react-i18next';
import location_on from '../../../../../assets/icons/location_on.svg';
import favorite from '../../../../../assets/icons/favorite.svg';
import Flex from '../../../../../Components/Flex/Flex';
import AppButton from '../../../../../Components/Button/AppButton';
import no_photo from '../../../../../assets/icons/no_photo.svg';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../../context/AuthContext';
import { useMutateFavorites } from '../../../../../hooks/api/favorites/useMutateFavorites';

const CardWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 10px;
  border: 2px solid #ebecf0;
  background: white;
  cursor: pointer;
`;

export const CompanyLogo = styled.div`
  background-image: url('${({ logo }) => logo}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0.5rem;
  border-radius: 10px;
  min-height: 160px;
  width: 100%;
`;

const CardContent = styled.div`
  padding: 25px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const LogoWrapper = styled.div`
  padding: 33px 51px;
  background: white;
  border-bottom: 1px solid #e4e6eb;
  border-radius: 8px 8px 0 0;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const CompanyCard = ({ company, coordinates = null, onClick = null, maxWidth = 'unset', favPage = false }) => {
  const { i18n } = useTranslation();
  const [token] = useContext(AuthContext);
  const [isFavorite, setIsFavorite] = useState(company.is_favorite);
  const mutate = useMutateFavorites();
  const nav = useNavigate();

  useEffect(() => {
    setIsFavorite(company.is_favorite === undefined ? favPage : company.is_favorite);
  }, [company.is_favorite]);

  const handleAddFavorites = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsFavorite((prevState) => !prevState);
    mutate.mutateAsync({ isActive: isFavorite, companyId: company.id });
  };

  const handleCardClick = () => {
    if (onClick && coordinates) {
      onClick(company, coordinates);
    } else {
      nav(`/company/${company.id}`);
    }
  };

  return (
    <CardWrapper onClick={handleCardClick} style={{ maxWidth }}>
      {token &&
        (isFavorite ? (
          <ButtonWrapper>
            <AppButton icon={favorite} onClick={handleAddFavorites} styleType={'dark_pink'} />
          </ButtonWrapper>
        ) : (
          <ButtonWrapper>
            <AppButton icon={favorite} onClick={handleAddFavorites} styleType={'black'} />
          </ButtonWrapper>
        ))}
      <LogoWrapper>
        <CompanyLogo
          logo={
            favPage ? 'https://staging.fribourgopen.ccif-hikf.ch/storage/' + company.logo : company.logo || no_photo
          }
        />
      </LogoWrapper>
      <CardContent>
        <AppText
          text={company.name[i18n.language]}
          color={'GrayBlue50'}
          fontSize={'md'}
          fontWeight={'900'}
          style={{ textAlign: 'left' }}
        />
        <Flex gap={'15px'}>
          <AppIcon icon={location_on} />
          <AppText
            text={company.npa_locality}
            color={'GrayBlue75'}
            fontSize={'sm'}
            fontWeight={'900'}
            style={{ textAlign: 'left' }}
          />
        </Flex>
      </CardContent>
    </CardWrapper>
  );
};

export default CompanyCard;
