import { colors } from '../../colors/colors';

function transformHoverRgba(rgbaString) {
  const rgbaValues = rgbaString.match(/\d+/g);
  const red = parseInt(rgbaValues[0]) + 19;
  const green = parseInt(rgbaValues[1]) + 19;
  const blue = parseInt(rgbaValues[2]) + 26;
  const alpha = parseFloat(rgbaValues[3]);
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

function transformFocusRgba(rgbaString) {
  const rgbaValues = rgbaString.match(/\d+/g);
  const red = parseInt(rgbaValues[0]);
  const green = parseInt(rgbaValues[1]) + 39;
  const blue = parseInt(rgbaValues[2]) + 51;
  const alpha = parseFloat(rgbaValues[3]);
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

function transformActiveRgba(rgbaString) {
  const rgbaValues = rgbaString.match(/\d+/g);
  const red = parseInt(rgbaValues[0]) - 26;
  const green = parseInt(rgbaValues[1]) - 20;
  const blue = parseInt(rgbaValues[2]);
  const alpha = parseFloat(rgbaValues[3]);
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

const ButtonTypes = {
  primary: {
    color: 'White',
    background: 'rgba(51, 97, 255, 1)',
    boxShadow: '0px 2px 5px 0px rgba(51, 97, 255, 0.1)',
  },
  black: {
    color: 'White',
    background: 'rgba(0, 0, 0, 0.3)',
    boxShadow: 'unset',
  },
  dark: {
    color: 'White',
    background: 'rgba(19, 25, 38, 1)',
    boxShadow: '0px 2px 5px 0px rgba(38, 51, 77, 0.1)',
  },
  default: {
    color: 'GrayBlue70',
    background: 'rgba(255, 255, 255, 1)',
    border: '2px solid #EBECF0',
    boxShadow: '0px 2px 5px 0px rgba(38, 51, 77, 0.03)',
  },
  ghost: {
    color: 'GrayBlue70',
    background: 'none',
    boxShadow: 'unset',
  },
  secondary: {
    color: 'DarkBlue2',
    background: 'rgba(51, 97, 255, 0.1)',
    boxShadow: 'unset',
  },
  colored: (color) => {
    return {
      color: 'White',
      background: colors[color],
      boxShadow: `0px 2px 5px 0px ${colors[color]}1A`,
    };
  },
};

const ButtonTypesHover = {
  primary: {
    background: 'rgba(77, 116, 255, 1)',
  },
  black: {
    background: 'rgba(0, 0, 0, 0.4)',
  },
  dark: {
    background: 'rgba(38, 51, 77, 1)',
  },
  default: {
    background: 'rgba(250, 251, 252, 1)',
  },
  ghost: {
    background: 'rgba(250, 251, 252, 1)',
  },
  secondary: {
    background: 'rgba(51, 97, 255, 0.15)',
  },
  colored: (color) => {
    return {
      background: transformHoverRgba(colors[color]),
    };
  },
};

const ButtonTypesFocused = {
  primary: {
    border: '4px solid rgba(102, 136, 255, 1)',
  },
  default: {
    border: '4px solid rgba(51, 97, 255, 1)',
  },
  black: {
    border: '4px solid rgba(0, 0, 0, 1)',
  },
  dark: {
    border: '4px solid rgba(77, 94, 128, 1)',
  },
  ghost: {
    border: '4px solid rgba(51, 97, 255, 1)',
  },
  secondary: {
    border: '4px solid rgba(102, 136, 255, 1)',
  },
  colored: (color) => {
    return {
      border: `4px solid ${transformFocusRgba(colors[color])}`,
    };
  },
};

const ButtonTypesActive = {
  primary: {
    background: 'rgba(25, 77, 255, 1)',
  },
  default: {
    background: 'rgba(245, 246, 247, 1)',
  },
  black: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  dark: {
    background: 'rgba(13, 17, 26, 1)',
  },
  ghost: {
    background: 'rgba(245, 246, 247, 1)',
  },
  secondary: {
    background: 'rgba(51, 97, 255, 0.2)',
  },
  colored: (color) => {
    return {
      background: transformActiveRgba(colors[color]),
    };
  },
};

const getPadding = (size) => {
  switch (size) {
    case 'xl':
      return '15px 25px 15px 25px';
    case 'l':
      return '10px 20px 10px 20px';
    case 'm':
      return '6px 15px 6px 15px';
    case 's':
      return '3px 10px 3px 10px';
    case 'xs':
      return '3px 5px 3px 5px';
    default:
      return '6px 15px 6px 15px';
  }
};

const getHeight = (size) => {
  switch (size) {
    case 'xl':
      return '60px';
    case 'l':
      return '50px';
    case 'm':
      return '40px';
    case 's':
      return '30px';
    case 'xs':
      return '20px';
    default:
      return '40px';
  }
};

const getIconButtonSize = (size) => {
  switch (size) {
    case 'xs':
      return '20px';
    case 's':
      return '30px';
    case 'm':
      return '40px';
    case 'l':
      return '50px';
    case 'xl':
      return '60px';
    default:
      return '40px';
  }
};

export {
  ButtonTypes,
  getPadding,
  getHeight,
  getIconButtonSize,
  ButtonTypesHover,
  ButtonTypesFocused,
  ButtonTypesActive,
};
