import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 100000;
  max-width: 80%;
  width: ${({ width }) => width || 'unset'};
  padding: ${({ padding }) => padding};
  background: white;
  border-radius: 20px;
  box-shadow: 0px 60px 120px 0px #26334d08;
  border: 2px solid #ebecf0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  max-height: calc(100vh - 80px);

  @media (max-width: 400px) {
    max-width: unset;
    width: 100%;
  }
`;

export const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.2);
`;
