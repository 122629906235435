import React, { useEffect, useState } from 'react';
import Flex from '../Flex/Flex';
import AppText from '../Text/AppText';
import AppIcon from '../Icon/AppIcon';
import keyboard_arrow_down from '../../assets/icons/keyboard_arrow_down.svg';
import keyboard_arrow_up from '../../assets/icons/keyboard_arrow_up_light.svg';
import edit_dark from '../../assets/icons/edit_dark.svg';
import delete_dark from '../../assets/icons/delete_dark.svg';
import { IconSpace, StyledTable, StyledTbody, StyledTd, StyledTh, StyledThead, StyledTr, Wrapper } from './styled';
import AppButton from '../Button/AppButton';

const TableComponent = ({ columns, editable = false, editClick, deleteClick, data }) => {
  const [sortParam, setSortParam] = useState('');
  const [initialTableData, setInitialTableData] = useState([]);
  const [_, forceRerender] = useState('');
  const [tableData, setTableData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(Array(columns.length).fill(0));

  const toggleSort = (id, index) => {
    const selectedSortDirectionIndex = selectedItem.findIndex((el) => el > 0);
    const updatedItems = selectedSortDirectionIndex === index ? [...selectedItem] : Array(columns.length).fill(0);
    updatedItems[index] = (updatedItems[index] + 1) % 3;
    const selectedSortDirection = updatedItems[index];

    setSelectedItem(updatedItems);
    setSortParam(selectedSortDirection > 0 ? `${id}_${selectedSortDirection === 1 ? 'asc' : 'des'}` : null);
  };

  useEffect(() => {
    if (data?.length) {
      setInitialTableData(data);
    }
  }, [data]);

  useEffect(() => {
    if (data?.length) {
      if (sortParam) {
        const [sortId, sortDirection] = sortParam.split('_');
        const sortedData = data.sort(columns.find((column) => column.id === sortId).sorting(sortDirection));
        setTableData(sortedData);
        forceRerender(sortParam); //change to proper solution
      } else {
        setTableData(initialTableData);
      }
    }
  }, [data, sortParam, initialTableData]);

  const handleEdit = (index) => {
    editClick(tableData[index]);
  };

  const handleDelete = (index) => {
    deleteClick(tableData[index]);
  };

  return (
    <StyledTable>
      <StyledThead>
        <StyledTr columns={editable ? columns.length + 1 : columns.length}>
          {columns?.map(({ title, icon, id }, index) => (
            <StyledTh key={id} onClick={() => toggleSort(id, index)}>
              <Flex alignItems={'center'} justifyContent={'center'} sx={{ cursor: 'pointer', width: 'fit-content' }}>
                <AppIcon icon={icon} />
                <Wrapper>
                  <AppText
                    text={title}
                    color={'GrayBlue70'}
                    fontWeight={'900'}
                    fontSize={'sm'}
                    noSelect
                    style={{ textWrap: 'nowrap' }}
                  />
                </Wrapper>
                {selectedItem[index] === 0 && <IconSpace />}
                {selectedItem[index] === 1 && <AppIcon icon={keyboard_arrow_down} />}
                {selectedItem[index] === 2 && <AppIcon icon={keyboard_arrow_up} />}
              </Flex>
            </StyledTh>
          ))}
          {editable && <StyledTh />}
        </StyledTr>
      </StyledThead>
      <StyledTbody>
        {tableData.length > 0 &&
          tableData.map((content, index) => (
            <StyledTr key={content.id} columns={editable ? columns.length + 1 : columns.length}>
              {columns.map((column) => (
                <StyledTd key={column.id}>
                  {
                    <AppText
                      text={typeof column.dataKey === 'function' ? column.dataKey(content) : content[column.dataKey]}
                      color={'GrayBlue70'}
                      fontWeight={'900'}
                      fontSize={'sm'}
                      isTranslate={!column.dontTranslate}
                    />
                  }
                </StyledTd>
              ))}
              {editable && (
                <Flex justifyContent={'flex-end'}>
                  <AppButton icon={edit_dark} styleType={'ghost'} onClick={() => handleEdit(index)} />
                  <AppButton icon={delete_dark} styleType={'ghost'} onClick={() => handleDelete(index)} />
                </Flex>
              )}
            </StyledTr>
          ))}
      </StyledTbody>
    </StyledTable>
  );
};

export default TableComponent;
