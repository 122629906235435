import React, { useContext, useEffect, useState } from 'react';
import { AlertWrapper, StyledAlert } from './styled';
import AppText from '../Text/AppText';
import { AlertContext } from '../../context/AlertContext';

const Alert = () => {
  const [show, setShow] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [alert, setAlert] = useContext(AlertContext);

  const { message, type } = alert;

  useEffect(() => {
    if (message && message?.length > 0) {
      setShow(true);
      setAnimate(false);
      setTimeout(() => {
        setAnimate(true);
        setTimeout(() => {
          setShow(false);
          setAlert({});
        }, 1000);
      }, 3000);
    }
  }, [message]);

  if (!show) return null;

  return (
    <AlertWrapper>
      <StyledAlert animate={animate} type={type}>
        <AppText text={message} fontSize={'md'} fontWeight={'900'} textAlign={'center'} color={'white'} />
      </StyledAlert>
    </AlertWrapper>
  );
};

export default Alert;
