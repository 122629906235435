import styled from 'styled-components';
import Flex from '../../Components/Flex/Flex';

export const Card1 = styled.div`
  border-radius: 5px 0 0 0;
  background: white;
  border: 2px solid #ebecf0;
  box-shadow: 0 2px 5px 0 #26334d08;
  padding: 30px;
  width: 100%;
  @media {
  }
`;

export const TitleImageWrapper = styled(Flex)`
  justify-content: space-between;
  @media (max-width: 1000px) {
    display: block;
  }
`;

export const Card2 = styled.div`
  border-radius: ${({ mobileHide }) => (mobileHide === 2 ? '0 5px 0 0' : '5px 5px 0 0')};
  background: white;
  border: solid #ebecf0;
  border-width: ${({ mobileHide }) => (mobileHide === 2 ? '2px 2px 2px 0' : '2px 2px 0 2px')};
  box-shadow: 0 2px 5px 0 #26334d08;
  padding: 30px;
  display: ${({ mobileHide }) => (mobileHide === 1 ? 'none' : 'block')};

  @media (max-width: 1000px) {
    display: ${({ mobileHide }) => (mobileHide === 1 ? 'block' : 'none')};
  }
`;

export const Card3 = styled.div`
  border-radius: 0 0 5px 5px;
  background: white;
  border: solid #ebecf0;
  border-width: 0 2px 2px 2px;
  box-shadow: 0 2px 5px 0 #26334d08;
  z-index: 2;
  padding: 30px;
`;

export const CardSingle1 = styled.div`
  border-radius: ${({ isLast }) => (isLast ? '5px' : '5px 5px 0 0')};
  background: white;
  border: 2px solid #ebecf0;
  box-shadow: 0 2px 5px 0 #26334d08;
  padding: 30px;
  margin-top: 30px;
  z-index: 10;
`;

export const CardSingle2 = styled.div`
  border-radius: 0 0 5px 5px;
  background: white;
  position: relative;
  border: solid #ebecf0;
  border-width: 0 2px 2px 2px;
  box-shadow: 0 2px 5px 0 #26334d08;
  z-index: 11;
  padding: 30px;
`;

export const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(33.33% - 30px), 1fr));
  grid-gap: 30px;
  align-items: stretch;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - 30px), 1fr));
  }

  @media (max-width: 700px) {
    grid-template-columns: repeat(auto-fill, minmax(calc(100% - 30px), 1fr));
  }

  & img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    min-height: 300px;
    cursor: pointer;

    @media (max-width: 900px) {
      min-height: 200px;
    }

    @media (max-width: 700px) {
      min-height: 100px;
    }
  }
`;

export const SideCard = styled.div`
  border-radius: 5px;
  background: white;
  border: 2px solid #ebecf0;
  box-shadow: 0 2px 5px 0 #26334d08;
  padding: 30px;
  margin: ${({ margin }) => margin || 'unset'};

  @media (max-width: 1000px) {
    display: ${({ mobileHide }) => (mobileHide ? 'none' : 'block')};
  }
`;

export const ContentWrapper = styled(Flex)`
  gap: 30px;
  margin-top: 30px;
  @media (max-width: 1000px) {
    display: block;
  }
`;

export const ReturnWrapper = styled.div`
  @media (max-width: 700px) {
    display: none;
  }
`;

export const MobilityAndTrainingWrapper = styled(Flex)`
  @media (max-width: 700px) {
    display: block;
  }
`;

export const VisitsDropDownWrapper = styled(Flex)`
  display: none;
  margin-top: 30px;
  @media (max-width: 1000px) {
    display: block;
  }
`;

export const ContactIconWrapper = styled.div`
  border-radius: 50%;
  padding: 10px;
  border: 2px #ebecf0 solid;
  box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  width: 50px;
  height: 50px;
  background: white;
  position: relative;
`;

export const Logo = styled.img`
  max-width: 300px;
  width: 100%;
  height: auto;
`;

export const LogoWrapper = styled.div`
  padding: 42px 30px;
  @media (max-width: 1100px) {
    padding: 0;
  }
`;
