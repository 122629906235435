import React, { useState } from 'react';
import styled from 'styled-components';
import AppIcon from '../Icon/AppIcon';
import AppText from '../Text/AppText';

const GroupButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  flex: 1;
  border: 2px solid #ebecf0;
  background: ${({ isActive }) => (isActive ? '#F5F6F7' : 'white')};

  @media (max-width: 500px) {
    > p {
      display: none;
    }
  }

  &:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-width: 2px 0 2px 2px;
  }

  &:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }
`;

function GroupButtons({ children, defaultActiveButtonIndex }) {
  const [activeButtonIndex, setActiveButtonIndex] = useState(defaultActiveButtonIndex);

  const handleClick = (index) => {
    setActiveButtonIndex(index);
  };

  return (
    <GroupButtonWrapper>
      {React.Children.map(children, (child, index) => (
        <ButtonWrapper
          key={index}
          isActive={index === activeButtonIndex}
          onClick={() => {
            handleClick(index);
            child.props.onClick();
          }}
        >
          {child.props.icon && <AppIcon icon={child.props.icon} />}
          {child.props.children && (
            <AppText text={child.props.children} color={'GrayBlue70'} fontWeight={'900'} fontSize={'xs'} />
          )}
        </ButtonWrapper>
      ))}
    </GroupButtonWrapper>
  );
}

export default GroupButtons;
