import React, { useRef, useState } from 'react';
import AppText from '../../Text/AppText';
import Flex from '../../Flex/Flex';
import { CustomCheckbox, CheckboxInput, ActiveCustomCheckbox } from './styled';

const Checkbox = ({ title, label, onChange, value = false, ...rest }) => {
  const [isChecked, setIsChecked] = useState(value);
  const checkboxRef = useRef(null);

  const handleCheck = (e) => {
    const newValue = e.target.checked;
    setIsChecked(newValue);
    onChange(e);
  };

  const handleClick = () => {
    if (checkboxRef.current) {
      checkboxRef.current.click();
    }
  };

  return (
    <div style={{ width: '100%' }}>
      {title && (
        <AppText
          text={title}
          fontWeight={'900'}
          color={'GrayBlue50'}
          fontSize={'xs'}
          style={{ margin: '0 0 15px 30px', textAlign: 'left' }}
        />
      )}
      <Flex alignItems={'center'} gap={'20px'} onClick={handleClick} sx={{ cursor: 'pointer', width: 'fit-content' }}>
        <CheckboxInput ref={checkboxRef} onChange={handleCheck} onClick={handleClick} checked={isChecked} {...rest} />
        {isChecked ? <ActiveCustomCheckbox /> : <CustomCheckbox />}
        <AppText fontWeight={'900'} text={label} color={'GrayBlue70'} fontSize={'xs'} />
      </Flex>
    </div>
  );
};

export default Checkbox;
