import { useQuery } from 'react-query';
import { configUseQuery } from '../../../helpers/queryConfig';
import api from '../../../api/api';

const fetchEventsList = async () => {
  return await api
    .get('/events')
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

export function useGetEventList() {
  const { data, isLoading } = useQuery('events', fetchEventsList, configUseQuery);

  return { events: data?.events, isLoading };
}
