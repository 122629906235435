import React, { useContext, useState } from 'react';
import AppText from '../Text/AppText';
import { StyledForm } from '../Form/styled';
import Input from '../Form/Input';
import FormError from '../Form/FormError';
import AppButton from '../Button/AppButton';
import { Link } from 'react-router-dom';
import Flex from '../Flex/Flex';
import AppIcon from '../Icon/AppIcon';
import launch from '../../assets/icons/launch.svg';
import { FormCard } from '../Form/FormCard';
import withForm from '../../hoc/withForm';
import axios from 'axios';
import { CompanyPageRedirectContext } from '../../context/CompanyPageRedirectContext';
import { useTranslation } from 'react-i18next';
import { convertUTCToLocalWithTimezone } from '../../helpers/helpers';

const handleLogin = (formData, setResponseError, companyId, setCompanyId, onSuccess, lang) => {
  const body = {
    email: formData.email,
    password: formData.password,
  };

  const requestOptions = {
    method: 'post',
    url: process.env.REACT_APP_API_URL + '/login',
    headers: { 'Content-Type': 'application/json' },
    params: {
      lang,
    },
    data: JSON.stringify(body),
    withCredentials: true,
  };

  axios(requestOptions.url, requestOptions)
    .then((response) => {
      localStorage.setItem(
        'userdata',
        JSON.stringify({
          ...response?.data.user,
          date_of_birth: convertUTCToLocalWithTimezone(response?.data.user.date_of_birth),
        })
      );
      let token = response?.data.token;
      let bearerToken = token.split('|')[1];
      localStorage.setItem('token', bearerToken);

      if (navigator.userAgent.includes('Unity')) {
        if (companyId) {
          window.location.href = 'uniwebview://userLoggedIn';
          setTimeout(() => {
            window.location.href = '/companies/' + companyId;
            setCompanyId(null);
            onSuccess(false);
          }, 800);
        } else {
          window.location.href = 'uniwebview://userLoggedIn';
          setTimeout(() => {
            window.location.href = '/';
          }, 800);
        }
      } else {
        if (companyId) {
          window.location.reload();
          setCompanyId(null);
          onSuccess(false);
        } else {
          window.location.href = '/#/';
        }
      }
    })
    .catch((error) => {
      setResponseError(error?.response?.data?.message || 'Unknown error occurred');
      if (error?.status >= 500) {
        setResponseError(error?.response?.data?.message || 'Server error occurred');
      }
      console.log(error);
    });
};

const validate = (formData) => {
  const errors = {};
  if (!/^[\w-]+(\.[\w-]+)*\+?[\w-]+@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)) {
    errors.email = 'validation.email.email';
  }
  if (formData.password.length < 8) {
    errors.password = 'validation.password.min';
  }
  if (formData.password.length >= 20) {
    errors.password = 'validation.password.max';
  }
  return errors;
};

const LoginFormCard = ({ errors, handleChange, handleSubmit, subTitle = null, onSuccess = () => {} }) => {
  const [responseError, setResponseError] = useState('');
  const [companyId, setCompanyId] = useContext(CompanyPageRedirectContext);
  const { i18n } = useTranslation();

  return (
    <FormCard>
      <div className={'title'}>
        <AppText text={'login.title'} color={'GrayBlue50'} fontSize={'lg'} fontWeight={'900'} textAlign={'center'} />
        <AppText text={subTitle || 'login.text'} color={'GrayBlue55'} fontSize={'sm'} textAlign={'center'} />
      </div>
      <StyledForm
        onSubmit={(e) => {
          handleSubmit(e, setResponseError, companyId, setCompanyId, onSuccess, i18n.language);
        }}
        style={{ margin: '0 auto' }}
      >
        <Input
          title={'login.placeholder.email'}
          margin={'0 0 20px 0'}
          size={'large'}
          name={'email'}
          error={errors.email}
          onChange={handleChange}
        />
        <Input
          title={'login.placeholder.password'}
          margin={'0 0 20px 0'}
          size={'large'}
          type={'password'}
          name={'password'}
          error={errors.password}
          onChange={handleChange}
        />
        <FormError message={responseError} margin={'0 0 20px 0'} />
        <AppButton
          type={'submit'}
          text={'popup.connexion.loginButton'}
          size={'xl'}
          textAlign={'center'}
          styles={{ width: '100%' }}
        />
      </StyledForm>
      <div style={{ marginTop: '40px' }}>
        <Link to={'/register'}>
          <Flex alignItems={'center'} gap={'10px'} justifyContent={'center'}>
            <AppText text={'login.dontHaveAcc'} color={'GrayBlue55'} fontSize={'sm'} textAlign={'center'} />
            <AppIcon icon={launch} />
          </Flex>
        </Link>
        <Link to={'/password-reset'}>
          <Flex alignItems={'center'} gap={'10px'} justifyContent={'center'}>
            <AppText text={'login.passwordChange'} color={'GrayBlue55'} fontSize={'sm'} />
            <AppIcon icon={launch} />
          </Flex>
        </Link>
      </div>
    </FormCard>
  );
};

export default withForm(LoginFormCard, { email: '', password: '' }, handleLogin, validate);
