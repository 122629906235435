import React from 'react';
import Flex from '../../Flex/Flex';
import AppIcon from '../../Icon/AppIcon';
import error_icon from '../../../assets/icons/error_icon.svg';
import AppText from '../../Text/AppText';

const FormError = ({ message, params, margin = '15px 0 0 30px', position = 'center' }) => {
  return (
    message && (
      <div style={{ width: position === 'center' ? 'unset' : '100%' }}>
        <Flex gap={'5px'} alignItems={'center'} sx={{ margin: margin }}>
          <AppIcon icon={error_icon} />
          <AppText color={'DarkRed2'} textParams={params} fontSize={'xs'} text={message} style={{ textWrap: 'wrap' }} />
        </Flex>
      </div>
    )
  );
};

export default FormError;
