import { Text, TitleH1, TitleH2, TitleH3 } from './styled';
import { useTranslation } from 'react-i18next';

const AppText = ({
  text,
  isTranslate = true,
  color,
  fontSize,
  fontWeight,
  titleSize,
  style,
  onClick,
  textParams = {},
  children,
  noSelect = false,
  textAlign,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!titleSize && (
        <Text
          color={color}
          fontSize={fontSize}
          fontWeight={fontWeight}
          style={style}
          onClick={onClick}
          textAlign={textAlign}
          noSelect={noSelect}
          {...rest}
        >
          {isTranslate ? t(text || children, textParams) : text}
        </Text>
      )}
      {titleSize === 'h1' && <TitleH1 fontWeight={fontWeight}>{t(text)}</TitleH1>}
      {titleSize === 'h2' && <TitleH2 fontWeight={fontWeight}>{t(text)}</TitleH2>}
      {titleSize === 'h3' && <TitleH3 fontWeight={fontWeight}>{t(text)}</TitleH3>}
    </>
  );
};

export default AppText;
