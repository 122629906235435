import React, { useContext, useEffect, useState } from 'react';
import Flex from '../../Components/Flex/Flex';
import AppIcon from '../../Components/Icon/AppIcon';
import AppText from '../../Components/Text/AppText';
import store from '../../assets/icons/store.svg';
import company_map_active from '../../assets/icons/company_map_nactive.svg';
import company_list_active from '../../assets/icons/company_list_nactive.svg';
import GroupButtons from '../../Components/GroupButtons';
import CompanyFilters from './components/CompanyFilters';
import { useGetCompanies } from '../../hooks/api/useGetCompanies';
import CompaniesList from './components/CompaniesList';
import Loader from '../../Components/Loader';
import CompaniesMap from './components/CompaniesMap';
import { shuffleArray } from '../../helpers/helpers';
import { FiltersButtonWrapper, FiltersWrapper, MobileWrapper } from './styled';
import Dropdown from '../../Components/Dropdown';
import { EventContext } from '../../context/EventContext';

const CompaniesPage = () => {
  const [filters, setFilters] = useState({
    onlyTraining: false,
    activitySectors: [],
    jobs: [],
    favorites: false,
    activityTypes: [],
    date: '',
  });
  const [display, setDisplay] = useState('list');
  const [randomizedCompanies, setRandomizedCompanies] = useState(null);
  const { companies, points, isCompaniesLoading } = useGetCompanies(filters);
  const [event] = useContext(EventContext);

  useEffect(() => {
    setRandomizedCompanies(shuffleArray(companies));
  }, [companies?.length, companies]);

  const padding = window.innerWidth > 600 ? '45px' : '40px';

  return (
    <>
      <Flex justifyContent={'space-between'} sx={{ position: 'relative', 'min-height': '100vh' }}>
        <div style={{ width: '100%' }}>
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ width: '100%', padding: `20px ${padding} 0 ${padding}`, 'align-self': 'flex-start' }}
          >
            <Flex gap={'20px'} alignItems={'center'}>
              <AppIcon icon={store} />
              <MobileWrapper>
                <AppText text={'companyPage.breadcrumb'} color={'GrayBlue50'} fontWeight={'900'} />
              </MobileWrapper>
            </Flex>
            <GroupButtons defaultActiveButtonIndex={0}>
              <button
                icon={company_list_active}
                onClick={() => {
                  setDisplay('list');
                }}
              >
                companyPage.list
              </button>
              <button
                icon={company_map_active}
                onClick={() => {
                  setDisplay('map');
                }}
              >
                companyPage.map
              </button>
            </GroupButtons>
          </Flex>
          {window.innerWidth < 900 && (
            <FiltersButtonWrapper>
              <Dropdown label={'companyPage.filters.label'}>
                <CompanyFilters mobile={true} filters={filters} setFilters={setFilters} />
              </Dropdown>
            </FiltersButtonWrapper>
          )}
          {isCompaniesLoading && <Loader size={'xl'} />}
          {event?.disabled && (
            <AppText
              text={'events.disabled'}
              color={'GrayBlue70'}
              fontWeight={'900'}
              textAlign={'center'}
              style={{ margin: '60px' }}
            />
          )}
          {!isCompaniesLoading && display === 'list' && randomizedCompanies?.length > 0 && (
            <div style={{ padding: `80px 0 0 0` }}>
              <CompaniesList companies={randomizedCompanies} />
            </div>
          )}
          {randomizedCompanies?.length === 0 && !isCompaniesLoading && display === 'list' && (
            <AppText
              text={'companies.list.empty'}
              color={'GrayBlue70'}
              fontWeight={'900'}
              textAlign={'center'}
              style={{ margin: '60px' }}
            />
          )}
          {!isCompaniesLoading && display === 'map' && (
            <div style={{ padding: '45px 0 0 0' }}>
              <CompaniesMap points={points} />
            </div>
          )}
        </div>
        {!isCompaniesLoading && window.innerWidth > 900 && (
          <FiltersWrapper>
            <CompanyFilters filters={filters} setFilters={setFilters} />
          </FiltersWrapper>
        )}
      </Flex>
    </>
  );
};

export default CompaniesPage;
