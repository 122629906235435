import React, { useEffect, useState } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import Supercluster from 'supercluster';
import pin from '../../../../../assets/icons/pinMap.svg';
import Arrow from '../../../../../assets/icons/ArrowToRight.svg';
import { useNavigate } from 'react-router-dom';
import AppText from '../../../../../Components/Text/AppText';
import Flex from '../../../../../Components/Flex/Flex';
import { useTranslation } from 'react-i18next';
import { ToolTip, ToolTipImage } from '../styled';
import AppButton from '../../../../../Components/Button/AppButton';

const supercluster = new Supercluster({
  radius: 150,
  maxZoom: 13,
  minZoom: 12,
});

const MAPBOX_TOKEN = 'pk.eyJ1IjoiYS1sbC1sYWIiLCJhIjoiY2xqd3R2ZjUxMG9jYTNmcDQ3MHlpeTY2cSJ9.ek5xtDtVVwH6iogYFam5Cg';

const Map = ({ points }) => {
  const [clusters, setClusters] = useState([]);
  const [bounds, setBounds] = useState([-180, -85, 180, 85]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [zoom, setZoom] = useState(12);
  const { i18n } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    supercluster.load(points);
    setClusters(supercluster.getClusters(bounds, zoom));
  }, [points, zoom, bounds]);

  const mapRef = React.useRef(null);
  useEffect(() => {
    if (mapRef.current) {
      mapRef?.current.resize();
    }
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      setBounds(mapRef.current.getMap().getBounds().toArray().flat());
    }
  }, [mapRef?.current]);

  return (
    <ReactMapGL
      initialViewState={{
        latitude: 46.702149,
        longitude: 7.092685,
        minZoom: 9,
        zoom: 8,
      }}
      mapboxAccessToken={MAPBOX_TOKEN}
      mapStyle="mapbox://styles/a-ll-lab/cljwuv6di024401qyeajyaorn"
      ref={mapRef}
      onZoomEnd={(e) => {
        setZoom(Math.round(e.viewState.zoom));
      }}
      style={{ flex: '1 0 0', height: '80vh', borderRadius: '20px' }}
    >
      {clusters.map((cluster) => {
        const { cluster: isCluster, point_count } = cluster.properties;
        const [longitude, latitude] = cluster.geometry.coordinates;

        if (isCluster) {
          return (
            <Marker key={`cluster-${cluster.id}`} longitude={longitude} latitude={latitude}>
              <div
                className="cluster-marker"
                style={{
                  width: `${25 + (point_count / points.length) * 50}px`,
                  height: `${25 + (point_count / points.length) * 50}px`,
                  borderRadius: '50%',
                  background: 'white',
                  display: 'flex',
                  padding: '16px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => {
                  const zoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 25);
                  mapRef.current.flyTo({
                    center: [longitude, latitude],
                    zoom,
                    speed: 0.5,
                  });
                }}
              >
                <AppText text={point_count} fontWeight={'900'} fontSize={'xs'} color={'GrayBlue50'} />
              </div>
            </Marker>
          );
        }
        return selectedCompany?.properties?.companyId === cluster.properties.companyId ? (
          <Marker
            key={`company-${cluster.properties.companyId}`}
            anchor="bottom"
            longitude={selectedCompany.geometry.coordinates[0]}
            latitude={selectedCompany.geometry.coordinates[1]}
          >
            <ToolTip>
              <Flex gap={'20px'} alignItems={'center'}>
                <ToolTipImage src={selectedCompany.properties.logo} alt="" />
                <div>
                  <AppText
                    text={selectedCompany.properties.npa_locality}
                    color={'GrayBlue45'}
                    fontWeight={'900'}
                    fontSize={'sm'}
                  />
                  <AppText
                    text={selectedCompany.properties.name[i18n.language]}
                    color={'GrayBlue70'}
                    fontWeight={'900'}
                    fontSize={'sm'}
                  />
                </div>
                <AppButton
                  icon={Arrow}
                  styleType={'default'}
                  styles={{ flexShrink: 0 }}
                  onClick={() => navigate('/company/' + selectedCompany.properties.companyId)}
                />
              </Flex>
            </ToolTip>
            <img src={pin} alt="marker" />
          </Marker>
        ) : (
          <Marker
            key={`company-${cluster.properties.companyId}`}
            anchor="bottom"
            longitude={longitude}
            latitude={latitude}
            onClick={() => {
              mapRef.current.flyTo({
                center: [longitude, latitude],
                zoom,
                speed: 0.5,
              });
              setSelectedCompany(points.find((point) => point.properties.companyId === cluster.properties.companyId));
            }}
          >
            <img src={pin} alt="marker" />
          </Marker>
        );
      })}
    </ReactMapGL>
  );
};

export default Map;
