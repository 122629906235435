import React from 'react';
import { CardSingle1, CardSingle2 } from '../../../styled';
import Flex from '../../../../../Components/Flex/Flex';
import { ColumnWrapper } from '../styled';
import TextWithIcon from '../../TextWithIcon';
import store from '../../../../../assets/icons/store.svg';
import cake from '../../../../../assets/icons/cake.svg';
import date_range from '../../../../../assets/icons/date_range.svg';
import location_on_gray from '../../../../../assets/icons/location_on_gray.svg';
import language_globe from '../../../../../assets/icons/language_globe.svg';
import accessible_forward from '../../../../../assets/icons/accessible_forward.svg';
import school from '../../../../../assets/icons/school_dark.svg';
import warning from '../../../../../assets/icons/warning.svg';
import local_activity from '../../../../../assets/icons/local_activity.svg';
import AppText from '../../../../../Components/Text/AppText';
import { TextToParagraph } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../helpers/date';
import styled from 'styled-components';

const InfoWrapper = styled(Flex)`
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const StepOne = ({ companyData, timeAndPlaces, activityTypesNames, activityRestriction, visit, selectedTimeLanguage }) => {
  const { i18n } = useTranslation();
  const { day, month, year } = formatDate(visit.visit_date, i18n.language);
  const visitDescriptionEnabled = visit?.description && visit?.description?.de && visit?.description?.fr;

  return (
    <div>
      <CardSingle1>
        <InfoWrapper>
          <ColumnWrapper>
            <TextWithIcon text={companyData.name[i18n.language]} icon={store} />
            {!!companyData.is_trainer_company && (
              <TextWithIcon text={'companyType.training'} icon={school} margin={'20px 0 0 0'} />
            )}
            <TextWithIcon text={companyData.full_address} icon={location_on_gray} margin={'20px 0 0 0'} />
            {!!companyData.has_support_for_limited_mobility && (
              <TextWithIcon text={'company.page.visit.modal.access'} icon={accessible_forward} margin={'20px 0 0 0'} />
            )}
            {activityRestriction.length > 0 && (
              <TextWithIcon text={activityRestriction.join(', ')} icon={warning} margin={'20px 0 0 0'} />
            )}
          </ColumnWrapper>
          <ColumnWrapper>
            <TextWithIcon
              text={`${day} ${month} ${year}, ${timeAndPlaces.startTime}-${timeAndPlaces.endTime}`}
              icon={date_range}
            />
            {activityTypesNames?.length > 0 && (
              <TextWithIcon text={activityTypesNames.join(', ')} icon={local_activity} margin={'20px 0 0 0'} />
            )}
            <TextWithIcon
              text={
                selectedTimeLanguage === 'DE'
                    ? 'form.profile.german'
                    : selectedTimeLanguage === 'EN'
                    ? 'form.profile.english'
                    : 'form.profile.french'
            }
              icon={language_globe}
              margin={activityTypesNames?.length > 0 ? '20px 0 0 0' : ''}
            />
            {typeof visit?.age_restriction === 'number' && visit?.age_restriction > 0 && (
              <TextWithIcon
                text={'visit.summary.age'}
                textParams={{ age: visit?.age_restriction }}
                icon={cake}
                margin={'20px 0 0 0'}
              />
            )}
          </ColumnWrapper>
        </InfoWrapper>
      </CardSingle1>
      {visitDescriptionEnabled && (
        <CardSingle2>
          <AppText text={'company.page.visit.body'} color={'GrayBlue50'} fontWeight={'900'} fontSize={'as'} />
          {TextToParagraph(visit.description[i18n.language], 2)}
        </CardSingle2>
      )}
    </div>
  );
};

export default StepOne;
