import Layout from '../Components/Layout';

export const RouteWrapper = ({ component: Component, layoutProps, wrapper: Wrapper, clean = false }) => {
  if (clean) {
    return <Component />;
  }
  if (Wrapper) {
    return (
      <Wrapper>
        <Layout {...layoutProps}>
          <Component />
        </Layout>
      </Wrapper>
    );
  }
  return (
    <Layout {...layoutProps}>
      <Component />
    </Layout>
  );
};
