import styled from 'styled-components';

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 30px;
  padding: 0 45px;
  align-items: stretch;
  ${({ style }) => style};

  @media (max-width: 600px) {
    padding: 0 40px;
  }
`;

export { GridContainer };
