import React, { createContext, useState } from 'react';

const EventContext = createContext();

const MyEventProvider = ({ children }) => {
  const [event, setEvent] = useState({ id: null });

  return <EventContext.Provider value={[event, setEvent]}>{children}</EventContext.Provider>;
};

export { EventContext, MyEventProvider };
