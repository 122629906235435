import styled from 'styled-components';

export const StyledVideoWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 50px;
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  margin-top: 50px;
  width: 100%;
  max-height: 540px;
  overflow: hidden;
  background: rgba(1, 1, 1, 0.2);
`;

export const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  max-height: 540px;
`;

export const TrainingWrapper = styled.div`
  display: flex;
  padding: 5px 10px;
  background: #3361ff;
  border-radius: 30px;
  position: absolute;
  gap: 5px;
  top: 40px;
  left: 40px;
  @media (max-width: 425px) {
    top: 10px;
    left: 10px;
  }
`;

export const MediaButtonsWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  left: 40px;
  @media (max-width: 425px) {
    bottom: 10px;
    left: 10px;
  }
`;
