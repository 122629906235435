import React from 'react';
import styled from 'styled-components';
import Flex from '../../../Components/Flex/Flex';
import AppText from '../../../Components/Text/AppText';

const SelectorItem = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #3361ff;
`;

const ActivitySectorItem = ({ text }) => {
  return (
    <Flex sx={{ margin: '20px 0 0 0' }} alignItems={'center'} gap={'20px'}>
      <SelectorItem />
      <AppText text={text} fontWeight={'900'} color={'GrayBlue50'} fontSize={'xs'} />
    </Flex>
  );
};

export default ActivitySectorItem;
