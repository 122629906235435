import { useQuery } from 'react-query';
import { configUseQuery } from '../../helpers/queryConfig';
import api from '../../api/api';

const getInterests = async () => {
  const response = await api
    .get('/interests')
    .then((response) => response)
    .catch((error) => console.log(error));
  return response.data;
};

export function useGetInterests() {
  const { data, isLoading } = useQuery('interests', getInterests, configUseQuery);

  return { interests: data?.interests, isLoading };
}
