import React, { useState } from 'react';
import styled from 'styled-components';
import AppButton from '../Button/AppButton';
import arrow_left from '../../assets/icons/arrow_left-sm.svg';
import arrow_right from '../../assets/icons/arrow_right-sm.svg';
import close from '../../assets/icons/CloseIcon.svg';
import Flex from '../Flex/Flex';
import VideoPlayer from '../VideoPlayer';

const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.2);
`;

const ModalWrapper = styled.div`
  position: fixed;
  z-index: 100000;
  max-width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 80px);

  @media (max-width: 400px) {
    max-width: unset;
    width: 95%;
  }
`;

const ModalContent = styled.div`
  padding: 20px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 60px 120px 0px #26334d08;
  border: 2px solid #ebecf0;
  height: 100%;
`;

const ModalImage = styled.img`
  width: 100%;
  border-radius: 5px;
  height: auto;
  max-height: 68vh;
`;

const Gallery = ({ media, setIsOpen, startIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(startIndex);

  const closeModal = () => {
    setIsOpen(false);
  };

  const showNext = () => {
    if (currentIndex < media.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const showPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <Modal>
      <ModalWrapper>
        <Flex justifyContent={'flex-end'} style={{ margin: '0 0 20px 0' }}>
          <AppButton onClick={closeModal} icon={close} styleType={'default'} />
        </Flex>
        <ModalContent>
          {media[currentIndex].original_url.endsWith('.mp4') ? (
            <div style={{ width: '70vw' }}>
              <VideoPlayer mediaUrl={media[currentIndex].original_url} />
            </div>
          ) : (
            <ModalImage src={media[currentIndex].original_url} alt={`Image ${currentIndex + 1}`} />
          )}
        </ModalContent>
        <Flex justifyContent={'space-between'} style={{ margin: '20px 0 0 0' }}>
          <AppButton onClick={showPrev} disabled={currentIndex === 0} icon={arrow_left} styleType={'default'} />
          <AppButton
            onClick={showNext}
            disabled={currentIndex === media.length - 1}
            icon={arrow_right}
            styleType={'default'}
          />
        </Flex>
      </ModalWrapper>
    </Modal>
  );
};

export default Gallery;
