import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationDE from './labels/labels.de';
import translationFR from './labels/labels.fr';

const resources = {
  de: {
    translation: translationDE,
  },
  fr: {
    translation: translationFR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'fr',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
