import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

const MyAuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const navigate = useNavigate();

  window.setToken = (newToken) => {
    setToken(newToken);
    localStorage.setItem('token', newToken);
    console.log('setToken was called');
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setToken(localStorage.getItem('token'));
    } else {
      setToken(null);
    }
  }, [localStorage.getItem('token')]);

  useEffect(() => {
    const handleCookieChange = (event) => {
      if (event.key === 'token' && event.newValue === null) {
        setToken(null);
        navigate('/');
      }
    };
    window.addEventListener('storage', handleCookieChange);
    return () => {
      window.removeEventListener('storage', handleCookieChange);
    };
  }, []);

  return <AuthContext.Provider value={[token, setToken]}>{children}</AuthContext.Provider>;
};

export { AuthContext, MyAuthProvider };
