import { fontColors } from '../../colors/colors';

const getFontSize = (size) => {
  switch (size) {
    case 'xxs':
      return '9px';
    case 'xs':
      return '12px';
    case 'as':
      return '13px';
    case 'sm':
      return '14px';
    case 'md':
      return '16px';
    case 'slg':
      return '22px';
    case 'lg':
      return '30px';
    case 'xl':
      return '36px';
    case 'xxl':
      return '48px';
    default:
      return '16px';
  }
};

const getMobileFontSize = (size) => {
  switch (size) {
    case 'xxs':
      return '9px';
    case 'xs':
      return '12px';
    case 'as':
      return '13px';
    case 'sm':
      return '14px';
    case 'md':
      return '16px';
    case 'slg':
      return '22px';
    case 'lg':
      return '22px';
    case 'xl':
      return '22px';
    case 'xxl':
      return '22px';
    default:
      return '14px';
  }
};

const getLineHeight = (size) => {
  switch (size) {
    case 'xxs':
      return '20px';
    case 'xs':
      return '20px';
    case 'as':
      return '25px';
    case 'sm':
      return '30px';
    case 'md':
      return '30px';
    case 'slg':
      return '30px';
    case 'lg':
      return '50px';
    case 'xl':
      return '60px';
    case 'xxl':
      return '70px';
    default:
      return '30px';
  }
};

const getMobileLineHeight = (size) => {
  switch (size) {
    case 'xxs':
      return '20px';
    case 'xs':
      return '20px';
    case 'as':
      return '25px';
    case 'sm':
      return '30px';
    case 'md':
      return '30px';
    case 'slg':
      return '30px';
    case 'lg':
      return '30px';
    case 'xl':
      return '50px';
    case 'xxl':
      return '50px';
    default:
      return '20px';
  }
};

const getColor = (color) => {
  return fontColors[color] || fontColors.White;
};

const getFontWeight = (fontWeight) => {
  switch (fontWeight) {
    case '900':
      return `'Fopen Midi Black', sans-serif`;
    case '700':
      return `'Fopen Midi Bold', sans-serif`;
    case '350':
      return `'Fopen Midi Regular', sans-serif`;
    default:
      return `'Fopen Midi Regular', sans-serif`;
  }
};

export { getFontSize, getLineHeight, getColor, getFontWeight, getMobileFontSize };
