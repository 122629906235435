import AppText from '../../Components/Text/AppText';
import React from 'react';

export const TextToParagraph = (text, numberOfParagraphs = null) => {
  if (!text) {
    return null;
  }
  if (!numberOfParagraphs) {
    return text
      .split('\r\n\r\n')
      .map((paragraph, index) => (
        <AppText text={paragraph} key={index} color={'GrayBlue50'} fontSize={'as'} style={{ marginTop: '20px' }} />
      ));
  }
  return text
    .split('\r\n\r\n')
    .map((paragraph, index) => (
      <AppText text={paragraph} key={index} color={'GrayBlue50'} fontSize={'as'} style={{ marginTop: '20px' }} />
    ))
    .slice(0, numberOfParagraphs);
};

export const mediaList = (array) => {
  if (array.length === 0) {
    return [];
  }

  return array.filter((element) => !element.original_url.endsWith('mp4'));
};

export function sortVisits(visits) {
  const sortedVisits = {};

  visits.forEach((visit) => {
    const date = visit.visit_date;

    if (!sortedVisits[date]) {
      sortedVisits[date] = {
        ids: [visit.id],
        company_id: visit.company_id,
        visit_date: visit.visit_date,
        age_restriction: visit.age_restriction,
        times: [
          {
            startTime: visit.startTime,
            endTime: visit.endTime,
            nPlaces: visit.nPlaces,
            nTakenPlaces: visit.nTakenPlaces,
          },
        ],
        created_at: [visit.created_at],
        updated_at: [visit.updated_at],
        description: [visit.description],
        has_parking_spots: visit.has_parking_spots,
        has_public_transport: visit.has_public_transport,
        restrictions: visit.restrictions,
        event_id: visit.event_id,
        languages: [visit.language],
        registrations: visit.registrations ? [visit.registrations] : [[]],
        registration_open: visit.registration_open,
      };
    } else {
      sortedVisits[date].ids.push(visit.id);

      sortedVisits[date].times.push({
        startTime: visit.startTime,
        endTime: visit.endTime,
        nPlaces: visit.nPlaces,
        nTakenPlaces: visit.nTakenPlaces,
      });

      sortedVisits[date].description.push(visit.description);
      sortedVisits[date].created_at.push(visit.created_at);
      sortedVisits[date].updated_at.push(visit.updated_at);
      sortedVisits[date].languages.push(visit.language);
      sortedVisits[date].registrations.push(visit.registrations ? visit.registrations : []);
    }
  });

  return Object.values(sortedVisits);
}
