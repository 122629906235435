import styled from 'styled-components';

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  z-index: 100;
`;

export const TopbarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 45px 10px 45px;
  background-color: white;
  border-bottom: 1px solid #ebecf0;
  max-height: 100px;

  @media (max-width: 1100px) {
    gap: 10px;
  }
`;

export const UserButtonWrapper = styled.button`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 2px #ebecf0 solid;
  box-shadow: 0 2px 5px rgba(38, 51, 77, 0.03);
  width: 50px;
  height: 50px;
  background: white;
`;

export const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 500px) {
    display: none;
  }
`;

export const DiscoverCompaniesButton = styled.div`
  display: block;
  @media (max-width: 680px) {
    display: none;
  }
`;
