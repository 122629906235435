import AppText from '../../Components/Text/AppText';
import '../Login/login.scss';
import Flex from '../../Components/Flex/Flex';
import Input from '../../Components/Form/Input';
import withForm from '../../hoc/withForm';
import { FormCard } from '../../Components/Form/FormCard';
import SelectDatepicker from '../../Components/Form/Datepicker/SelectDatepicker';
import { StyledForm } from '../../Components/Form/styled';
import Checkbox from '../../Components/Form/Checkbox';
import AppButton from '../../Components/Button/AppButton';
import React, { useContext, useState } from 'react';
import Modal from '../../Components/Modal';
import AppIcon from '../../Components/Icon/AppIcon';
import delete_forever from '../../assets/icons/delete_forever.svg';
import keyboard_arrow_left from '../../assets/icons/keyboard_arrow_left.svg';
import FormError from '../../Components/Form/FormError';
import axios from 'axios';
import { calculateAge } from '../../helpers/form';
import { useNavigate } from 'react-router-dom';
import { BackButtonWrapper, LanguageButtonsContainer } from './styled';
import { AuthContext } from '../../context/AuthContext';
import { useDeleteUser } from '../../hooks/api/useDeleteUser';
import LanguageButtons from '../../Components/LanguageButtons';
import Select from '../../Components/Select';
import { useTranslation } from 'react-i18next';
import { AlertContext } from '../../context/AlertContext';

const handleUpdate = (formData, setResponseError, token, langParam, setAlert) => {
  const { id, company_id, created_at, email_verified_at, lang, last_logged_in, role } = JSON.parse(
    localStorage.getItem('userdata')
  );
  const body = {
    name: `${formData.firstName} ${formData.lastName}`,
    email: formData.email,
    lang: formData.lang,
    has_limited_mobility: !!formData.has_limited_mobility,
    date_of_birth: `${formData.year}-01-01`,
  };

  if (formData.password && formData.old_password) {
    body.password = formData.password;
    body.old_password = formData.old_password;
  }

  const requestOptions = {
    method: 'put',
    url: process.env.REACT_APP_API_URL + `/users/${id}`,
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token },
    params: {
      lang: langParam,
    },
    data: JSON.stringify(body),
  };

  axios(requestOptions.url, requestOptions)
    .then((res) => {
      localStorage.removeItem('userdata');
      localStorage.setItem(
        'userdata',
        JSON.stringify({
          id,
          email: body.email,
          name: body.name,
          has_limited_mobility: body.has_limited_mobility,
          date_of_birth: body.date_of_birth,
          company_id,
          created_at,
          email_verified_at,
          lang: body.lang,
          last_logged_in,
          role,
        })
      );
      setAlert({ type: 'success', message: res?.data?.message });
      setResponseError('');
    })
    .catch((error) => {
      console.log('Error occurred:', error);
      setResponseError(error.response.data.message);

      const logBody = {
        type: 'register error',
        log: error,
      };
      const logRequestOptions = {
        method: 'post',
        url: process.env.REACT_APP_API_URL + '/log',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(logBody),
      };

      axios(logRequestOptions.url, logRequestOptions);
    });
};

function getUserInitial() {
  const userData = JSON.parse(localStorage.getItem('userdata'));
  if (!userData) {
    return null;
  }
  return {
    firstName: userData.name.split(' ')[0],
    lastName: userData.name.split(' ')[1],
    email: userData.email,
    lang: userData.lang,
    password: '',
    old_password: '',
    has_limited_mobility: !!userData.has_limited_mobility,
    year: userData?.date_of_birth?.split('-')[0] || '2024',
  };
}

const validate = (formData) => {
  const errors = {};
  const dateOfBirth = `${formData.year}-${formData.month}-${formData.day}`;
  if (calculateAge(dateOfBirth) < 15) {
    errors.day = 'registration.minimumAgeDisclaimer.15';
  }
  if (formData.firstName.length <= 1) {
    errors.firstName = 'validation.firstName.min';
  }
  if (formData.lastName.length <= 1) {
    errors.lastName = 'validation.firstName.min';
  }
  if (formData.firstName.length >= 20) {
    errors.firstName = 'validation.firstName.max';
  }
  if (formData.lastName.length >= 20) {
    errors.lastName = 'validation.firstName.max';
  }
  if (!/^[\w-]+(\.[\w-]+)*\+?[\w-]+@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.email)) {
    errors.email = 'validation.email.email';
  }
  if (formData.old_password) {
    if (formData.old_password.length < 8) {
      errors.old_password = 'validation.password.min';
    }
    if (formData.old_password.length >= 20) {
      errors.old_password = 'validation.password.max';
    }
  }
  if (formData.password) {
    if (formData.password.length < 8) {
      errors.password = 'validation.password.min';
    }
    if (formData.password.length >= 20) {
      errors.password = 'validation.password.max';
    }
  }
  if (formData.password && !formData.old_password) {
    errors.old_password = 'validation.old_password';
  }

  return errors;
};

const Profile = ({ formData, errors, handleChange, handleSubmit }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [responseError, setResponseError] = useState('');
  const [token] = useContext(AuthContext);
  const navigate = useNavigate();
  const mutation = useDeleteUser();
  const [_, setAlert] = useContext(AlertContext);
  const { i18n } = useTranslation();

  const handleModalDeleteAcc = () => {
    setShowDeleteConfirmation((prevState) => !prevState);
  };

  const handleDeleteAcc = async () => {
    const userData = JSON.parse(localStorage.getItem('userdata'));
    
    await mutation.mutateAsync(
      { id: userData.id },
      {
        onSuccess: () => {
          localStorage.removeItem('userdata');
          localStorage.removeItem('token');
          navigate('/');
        },
        onError: (error) => {
          console.error('Failed to delete account:', error);
        },
      }
    );
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="login-container  login-box">
      <div className="form-section">
        <FormCard margin={'170px 0'}>
          <div className={'title'}>
            <AppText
              text={'profile.page.title'}
              color={'GrayBlue50'}
              fontSize={'lg'}
              fontWeight={'900'}
              textAlign={'center'}
            />
            <AppText text={'profile.page.desc'} color={'GrayBlue55'} fontSize={'sm'} textAlign={'center'} />
          </div>
          <StyledForm
            onSubmit={(e) => {
              handleSubmit(e, setResponseError, token, i18n.language, setAlert);
            }}
          >
            <Flex gap={'20px'}>
              <Input
                title={'form.profile.firstName'}
                name={'firstName'}
                value={formData.firstName}
                error={errors.firstName}
                onChange={handleChange}
              />
              <Input
                title={'form.profile.lastName'}
                name={'lastName'}
                value={formData.lastName}
                error={errors.lastName}
                onChange={handleChange}
              />
            </Flex>
            <Input
              title={'login.placeholder.email'}
              name={'email'}
              value={formData.email}
              error={errors.email}
              onChange={handleChange}
            />
            <Select
              name={'lang'}
              title={'language.select'}
              options={[
                { name: 'form.profile.french', value: 'fr' },
                { name: 'form.profile.german', value: 'de' },
              ]}
              value={formData.lang}
              onChange={handleChange}
              style={{ width: '100%' }}
            />
            <Input
              title={'passwordChange.newPasswordPlaceholder'}
              type={'password'}
              name={'password'}
              error={errors.password}
              onChange={handleChange}
              margin={'20px 0'}
            />
            <Input
              title={'profile.page.old.password'}
              type={'password'}
              name={'old_password'}
              error={errors.old_password}
              onChange={handleChange}
            />
            <SelectDatepicker
              title={'form.profile.birthDate'}
              onFormChange={handleChange}
              initialSelectedYear={formData.year}
            />
            <FormError message={errors.day} margin={'0 0 20px 30px'} />
            <Checkbox
              title={'form.profile.mobility.title'}
              label={'form.profile.mobility'}
              onChange={handleChange}
              name={'has_limited_mobility'}
            />
            <FormError message={responseError} margin={'40px 0 0 0'} />
            <AppButton
              type={'submit'}
              text={'profile.page.submit'}
              size={'xl'}
              styles={{ margin: responseError ? '20px 0 0 0' : '40px 0 0 0' }}
            />
            <Flex gap={'10px'} sx={{ margin: '40px 0 0 0', cursor: 'pointer' }} onClick={handleModalDeleteAcc}>
              <AppText text={'profile.page.delete'} color={'Red'} />
              <AppIcon icon={delete_forever} />
            </Flex>
          </StyledForm>
        </FormCard>
        {showDeleteConfirmation && (
          <Modal width={'80%'}>
            <AppText
              text={'profile.page.delete.confirm'}
              fontSize={'xl'}
              color={'GrayBlue50'}
              fontWeight={'900'}
              textAlign={'center'}
            />
            <Flex gap={'85px'} justifyContent={'center'} sx={{ margin: '40px 0 0 0' }}>
              <AppButton text={'form.profile.cancel'} size={'xl'} onClick={handleModalDeleteAcc} />
              <AppButton text={'profile.page.delete.acc'} size={'xl'} styleType={'red'} onClick={handleDeleteAcc} />
            </Flex>
          </Modal>
        )}
      </div>
      <BackButtonWrapper onClick={handleGoBack}>
        <AppIcon icon={keyboard_arrow_left} />
      </BackButtonWrapper>
      <LanguageButtonsContainer>
        <LanguageButtons menuTop={'-20px'} menuLeft={'-25px'} />
      </LanguageButtonsContainer>
    </div>
  );
};
export default withForm(Profile, getUserInitial(), handleUpdate, validate);
