import React from 'react';
import { MobileWrapper, NavItem, SidebarWrapper, UtilsWrapper } from './styled';
import CloseIcon from '../../assets/icons/CloseIcon.svg';
import MyVisits from '../../assets/icons/MyVisits.svg';
import MyFavorites from '../../assets/icons/MyFavorites.svg';
import MyInfo from '../../assets/icons/MyInfo.svg';
import chat_bubble from '../../assets/icons/chat_bubble.svg';
import help_icon from '../../assets/icons/help_icon.svg';
import arrow_forward from '../../assets/icons/arrow_forward.svg';
import logout from '../../assets/icons/logout.svg';
import Flex from '../Flex/Flex';
import AppText from '../Text/AppText';
import IconButton from '../IconButton';
import AppIcon from '../Icon/AppIcon';
import { Link } from 'react-router-dom';
import EventSelector from '../Topbar/components/EventSelector';

const UserSidebar = ({ showSidebar, setShowSidebar, isCompanyPage, setToken }) => {
  const userName = JSON.parse(localStorage.getItem('userdata'))?.name || null;

  const handleLogout = () => {
    localStorage.removeItem('token');
    setShowSidebar(false);
    setToken(null);
  };

  const handleClose = () => {
    setShowSidebar(false);
  };

  return (
    <SidebarWrapper isExpanded={showSidebar}>
      <Flex justifyContent={'space-between'} alignt-items={'center'} sx={{ 'margin-bottom': '35px' }}>
        <AppText text={userName} fontWeight={'900'} color={'GrayBlue70'} />
        <IconButton onClick={handleClose}>
          <AppIcon icon={CloseIcon} />
        </IconButton>
      </Flex>
      <nav>
        <Link to={'/my-visits'} onClick={handleClose}>
          <NavItem position={1}>
            <AppIcon dimensions={'30px'} icon={MyVisits} />
            <AppText text={'mesVisits.topBarText'} fontWeight={'900'} color={'GrayBlue70'} />
          </NavItem>
        </Link>
        <Link to={'/favorites'} onClick={handleClose}>
          <NavItem position={2}>
            <AppIcon dimensions={'30px'} icon={MyFavorites} />
            <AppText text={'favorites.title'} fontWeight={'900'} color={'GrayBlue70'} />
          </NavItem>
        </Link>
        <Link to={'/profile'} onClick={handleClose}>
          <NavItem position={3}>
            <AppIcon dimensions={'30px'} icon={MyInfo} />
            <AppText text={'sideBar.nav.myInfo'} fontWeight={'900'} color={'GrayBlue70'} />
          </NavItem>
        </Link>
      </nav>
      <MobileWrapper>{isCompanyPage && <EventSelector alwaysShowSelectedName={true} />}</MobileWrapper>
      <UtilsWrapper>
        <Link to={'/news/10'} onClick={handleClose}>
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Flex gap={'15px'} alignItems={'center'}>
              <AppIcon dimensions={'30px'} icon={chat_bubble} />
              <AppText text={'sideBar.utils.help'} fontWeight={'900'} color={'GrayBlue50'} />
            </Flex>
            <AppIcon dimensions={'30px'} icon={arrow_forward} />
          </Flex>
        </Link>
        <Link to={'/news/11'} onClick={handleClose}>
          <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Flex gap={'15px'} alignItems={'center'}>
              <AppIcon dimensions={'30px'} icon={help_icon} />
              <AppText text={'user.sidebar.questions'} fontWeight={'900'} color={'GrayBlue50'} />
            </Flex>
            <AppIcon dimensions={'30px'} icon={arrow_forward} />
          </Flex>
        </Link>
        <button style={{ width: '100%' }} onClick={handleLogout}>
          <Flex gap={'15px'} alignItems={'center'}>
            <AppIcon icon={logout} />
            <AppText text={'menu.logout'} fontWeight={'900'} color={'DarkRed2'} />
          </Flex>
        </button>
      </UtilsWrapper>
    </SidebarWrapper>
  );
};

export default UserSidebar;
