import styled from 'styled-components';

const ItemsWrapper = styled.div`
  padding: 0 0 0 15px;
`;

const ActiveSelectorItem = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #3361ff;
  flex-shrink: 0;
`;

const SelectorItem = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #dde0e6;
  flex-shrink: 0;
`;

export { ItemsWrapper, SelectorItem, ActiveSelectorItem };
