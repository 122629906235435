import React from 'react';
import './dashboard.scss';
import AppCardsDashboard from '../../Components/Cards/AppCardsDashboard';
import ColoredCard from './compnents/ColoredCard';
import ImageWithOverlay from '../../Components/ImageWithOverlay/ImageWithOverlay';
import { DashboardContentWrapper } from '../../Components/Cards/appCardsStyle';
import AppText from '../../Components/Text/AppText';
import AppButton from '../../Components/Button/AppButton';
import ArrowRight from '../../assets/icons/ArrowRight.svg';
import { useGetPages } from '../../hooks/api/useGetPages';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Components/Loader';
import { ColoredCards, GridContainer, Wrapper } from './styled';

const Dashboard = () => {
  const { isLoading, news, headline, articles } = useGetPages();
  const { i18n } = useTranslation();
  const nav = useNavigate();

  const handleArticleClick = (id) => {
    nav(`/news/${id}`);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {headline?.length > 0 &&
        headline.map((el) => <AppCardsDashboard content={el} key={el.id} button={'news.readMore'} />)}
      <Wrapper articles={articles.length}>
        {articles?.length > 0 &&
          articles.map((article, index) => (
            <ColoredCard
              onClick={() => {
                handleArticleClick(article.id);
              }}
              title={article.title}
              key={article.id}
              bgcolor={ColoredCards[index % ColoredCards.length]}
            />
          ))}
      </Wrapper>
      <GridContainer newsLength={news?.length}>
        {news?.length > 0 &&
          news.map(({ title, id, thumbnail }) => (
            <ImageWithOverlay
              style={{ cursor: 'pointer' }}
              image={thumbnail}
              key={id}
              height={'350px'}
              onClick={() => handleArticleClick(id)}
            >
              <DashboardContentWrapper>
                <div />
                <AppText text={title[i18n.language]} fontWeight={'900'} fontSize={'md'} />
                <AppButton
                  text={'home.news.readMore'}
                  icon={ArrowRight}
                  styleType={'white'}
                  onClick={() => handleArticleClick(id)}
                  styles={{ alignSelf: 'flex-end' }}
                />
              </DashboardContentWrapper>
            </ImageWithOverlay>
          ))}
      </GridContainer>
    </div>
  );
};

export default Dashboard;
