import React from 'react';
import AppText from '../../../Components/Text/AppText';
import ImageWithOverlay from '../../../Components/ImageWithOverlay/ImageWithOverlay';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatDate } from '../../../helpers/date';

export const ArticleContentWrapper = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  padding: 60px;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 48px;
  height: 100%;

  @media (max-width: 450px) {
    justify-content: space-between;
    padding: 20px;
  }
`;

const ArticleHeader = ({ backgroundImage, title, body, date }) => {
  const { i18n } = useTranslation();
  const background = backgroundImage === 'https://api-preprod.fribourgopen.ccif-hikf.ch/storage';

  const { day, month, year } = formatDate(date, i18n.language);

  return (
    <div>
      <ImageWithOverlay image={background ? '' : backgroundImage} height={'540px'}>
        <ArticleContentWrapper>
          <div>
            <AppText text={title[i18n.language]} fontWeight={'900'} fontSize={'xl'} />
            <AppText text={body[i18n.language]} fontWeight={'900'} fontSize={'sm'} />
          </div>
          <AppText
            text={'news.date'}
            textParams={{ day, month, year }}
            fontSize={'sm'}
            color={'GrayBlue80'}
            fontWeight={'900'}
          />
        </ArticleContentWrapper>
      </ImageWithOverlay>
    </div>
  );
};

export default ArticleHeader;
