import React, { useEffect, useState } from 'react';
import selectArrows from '../../assets/icons/selectArrows.svg';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AppIcon from '../Icon/AppIcon';
import AppText from '../Text/AppText';

const DropdownWrapper = styled.div`
  position: relative;

  margin: ${({ margin }) => margin || 'unset'};
`;

const StyledDropdown = styled.div`
  cursor: pointer;
  padding: ${({ icon }) => (icon ? '13px 50px 13px 45px' : '13px 50px 13px 20px')};
  border-radius: 5px;
  width: 100%;
  border: 2px solid #ebecf0;
  box-shadow: 0 2px 5px 0 #26334d08;
  background: url('${selectArrows}') no-repeat right 10px center;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 9px;
  left: 10px;
`;

const ItemWrapper = styled.div`
  border: solid #ebecf0;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  padding: ${({ icon }) => (icon ? '13px 50px 13px 45px' : '13px 50px 13px 20px')};

  ${({ position }) => getBorder(position)}
`;

const getBorder = (position) => {
  switch (position) {
    case 'single':
      return { borderWidth: '2px', borderRadius: '5px', margin: '10px 0 0 0' };
    case 'start':
      return { borderWidth: '2px 2px 2px 2px', borderRadius: '5px 5px 0 0', margin: '10px 0 0 0' };
    case 'end':
      return { borderWidth: '0 2px 2px 2px', borderRadius: '0 0 5px 5px' };
    case 'middle':
      return { borderWidth: '0 2px 2px 2px', borderRadius: '0' };
  }
};

const getPosition = (index, options) => {
  if (options.length === 1) {
    return 'single';
  } else if (index === 0) {
    return 'start';
  } else if (index === options.length - 1) {
    return 'end';
  }
  return 'middle';
};

const ContainedDropdown = ({
  options,
  margin,
  placeholder,
  onFieldUpdate,
  icon,
  multilang = false,
  onClick,
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setSelectedOption(null);
    setIsOpen(false);
  }, [onFieldUpdate]);

  const selectOption = (index) => {
    setSelectedOption(options[index]);
    setIsOpen(false);
    onClick(options[index], index);
  };
  const handleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <DropdownWrapper margin={margin}>
      <StyledDropdown icon={!!icon} value={placeholder ? null : selectedOption} onClick={handleOpen} {...rest}>
        <AppText
          text={selectedOption?.name || t(placeholder)}
          fontWeight={'900'}
          fontSize={'xs'}
          color={'GrayBlue70'}
          isTranslate={!selectedOption?.name}
        />
      </StyledDropdown>
      {isOpen &&
        options.map((option, index) => (
          <ItemWrapper
            icon={icon}
            position={getPosition(index, options)}
            key={index}
            onClick={() => selectOption(index)}
          >
            <AppText
              text={multilang ? option.name[i18n.language] : option.name}
              fontWeight={'900'}
              fontSize={'xs'}
              color={'GrayBlue50'}
              isTranslate={false}
            />
            {icon && (
              <IconWrapper>
                <AppIcon icon={icon} />
              </IconWrapper>
            )}
          </ItemWrapper>
        ))}
      {icon && (
        <IconWrapper>
          <AppIcon icon={icon} />
        </IconWrapper>
      )}
    </DropdownWrapper>
  );
};

export default ContainedDropdown;
