import styled from 'styled-components';

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  height: 20px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 20px;
  margin: unset;
  cursor: pointer;
  z-index: 2;
`;

const CustomCheckbox = styled.span`
  display: inline-block;
  height: 20px;
  width: 20px;
  background: #fff;
  border: 2px solid #dde0e6;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 #26334d08;
  &:focus {
    border: 2px solid #3361ff;
  }
`;

const ActiveCustomCheckbox = styled.span`
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  background: #3361ff;
  border: 2px solid #3361ff;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 #3361ff33;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 5px;
    width: 3px;
    height: 5px;
    border: solid white;
    border-width: 0 2px 2px 0;
    rotate: 45deg;
  }
`;

export { CheckboxInput, CustomCheckbox, ActiveCustomCheckbox };
