import styled from 'styled-components';

const SelectedItems = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #3361ff;
  text-align: center;
`;

const DropdownWrapper = styled.div`
  margin: ${({ margin }) => margin || 'unset'};
`;

export { SelectedItems, DropdownWrapper };
