import styled from 'styled-components';

export const ArticleCard = styled.div`
  padding: 40px 0 50px;
  background: white;
  border: 2px solid #ebecf0;
  box-shadow: 0px 2px 5px 0px #26334d08;
  border-radius: 10px;
`;

export const ArticleFooterCard = styled.div`
  padding: 40px;
  background: white;
  border: 2px solid #ebecf0;
  box-shadow: 0px 2px 5px 0px #26334d08;
  border-radius: 10px;
`;

export const Line = styled.div`
  background: #c3cad9;
  width: 20px;
  max-width: 20px;
  height: 2px;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 1px;
  flex: 1 0 auto;
`;

export const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(33.33% - 10px), 1fr));
  grid-gap: 10px;
  align-items: stretch;
  margin-bottom: 60px;

  & img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
`;

export const ArticleFooterImage = styled.img`
  width: 100%;
  border-radius: 10px;
`;
