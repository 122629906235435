import React from 'react';
import styled from 'styled-components';

const StyledFlex = styled.div`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  gap: ${({ gap }) => gap || '0'};
  flex: ${({ flex }) => flex || '0 1 auto'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'unset'};

  ${({ sx }) =>
    sx &&
    Object.keys(sx)
      .map((key) => `${key}: ${sx[key]};`)
      .join('\n')}
`;

const Flex = (props) => {
  return <StyledFlex {...props} />;
};

export default Flex;
