import styled from 'styled-components';
import {
  ButtonTypes,
  getPadding,
  getHeight,
  getIconButtonSize,
  ButtonTypesHover,
  ButtonTypesFocused,
  ButtonTypesActive,
} from './utils';

const StyledIconButton = styled.button`
  border-radius: ${({ rectangle }) => (rectangle ? '5px' : '50%')};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  min-height: unset;
  height: ${({ size }) => getIconButtonSize(size)};
  width: ${({ size }) => getIconButtonSize(size)};

  ${({ styleType }) => ButtonTypes[styleType] || ButtonTypes.colored(styleType) || ButtonTypes.primary};

  &:hover {
    height: ${({ size }) => getIconButtonSize(size)};
    width: ${({ size }) => getIconButtonSize(size)};
    ${({ styleType }) =>
      ButtonTypesHover[styleType] || ButtonTypesHover.colored(styleType) || ButtonTypesHover.primary};
  }

  &:focus {
    ${({ styleType }) =>
      ButtonTypesFocused[styleType] || ButtonTypesFocused.colored(styleType) || ButtonTypesFocused.primary};
  }

  &:active {
    ${({ styleType }) =>
      ButtonTypesActive[styleType] || ButtonTypesActive.colored(styleType) || ButtonTypesActive.primary}
  }

  ${({ styles }) => styles};

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const StyledButton = styled.button`
  border-radius: ${({ rectangle }) => (rectangle ? '5px' : '30px')};
  padding: ${({ size }) => getPadding(size)};
  display: flex;
  align-items: center;
  justify-content: ${({ textAlign }) => textAlign};
  width: fit-content;
  gap: 6px;
  height: ${({ size }) => getHeight(size)};
  border: 4px solid transparent;

  ${({ styleType }) => ButtonTypes[styleType] || ButtonTypes.colored(styleType) || ButtonTypes.primary};


  &:hover {
    ${({ styleType }) => ButtonTypesHover[styleType] || ButtonTypesHover.colored(styleType) || ButtonTypesHover.primary};
  }
  
  &:focus {
    ${({ styleType }) => ButtonTypesFocused[styleType] || ButtonTypesFocused.colored(styleType) || ButtonTypesFocused.primary};
  }

  &:active{
    ${({ styleType }) => ButtonTypesActive[styleType] || ButtonTypesActive.colored(styleType) || ButtonTypesActive.primary}
  }
  
  ${({ styles }) => styles};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
  };
`;

export { StyledButton, StyledIconButton };
