import { useQuery } from 'react-query';
import { useContext, useEffect, useState } from 'react';
import { SearchContext } from '../../context/SearchContext';
import { useTranslation } from 'react-i18next';
import { EventContext } from '../../context/EventContext';
import api from '../../api/api';

const getCompanies = async ({ queryKey }) => {
  const eventId = queryKey[1];
  const response = await api
    .get(`/companies?eventID=${eventId}`)
    .then((response) => response)
    .catch((error) => console.log(error));
  return response.data;
};

const filterCompanies = (company, filters, lang) => {
  return (
    (filters.favorites ? company.is_favorite : true) &&
    (filters.onlyTraining ? company.is_trainer_company === 1 : true) &&
    (filters.name
      ? company.name[lang].toLowerCase().includes(filters.name.toLowerCase()) ||
        company.abbreviation?.toLowerCase().includes(filters.name.toLowerCase())
      : true) &&
    (filters.jobs.length > 0 ? filters.jobs.every((jobId) => company.jobs.some((job) => job.id === jobId)) : true) &&
    (filters.activityTypes.length > 0
      ? company.visits.length > 0 &&
        filters.activityTypes.every((typeId) =>
          company.visits.some((visit) => visit.activity_types.some((type) => type.id === typeId))
        )
      : true) &&
    (filters.activitySectors.length > 0
      ? filters.activitySectors.every((sectorId) =>
          company.activity_sectors.some((activity) => activity.id === sectorId)
        )
      : true) &&
    (filters.date ? company.visits.some((visit) => visit?.visit_date.includes(filters.date)) : true)
  );
};

export function useGetCompanies(filters) {
  const [searchValue] = useContext(SearchContext);
  const [event] = useContext(EventContext);
  const [companies, setCompanies] = useState(null);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const { data, isLoading } = useQuery(['companies', event.id], getCompanies, {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    enabled: event.id !== null && event.enabled === true,
  });
  const { i18n } = useTranslation();
  const [points, setPoints] = useState([]);

  useEffect(() => {
    const fetchGeocodedData = async () => {
      if (!companies || companies.length === 0) {
        return setPoints([]);
      }
      try {
        const geocodedPoints = await Promise.all(
          companies.map(async (company) => {
            if (company.logo && company.logo.endsWith('/storage')) {
              company.logo = null;
            }

            if (company.gps_location === null) {
              try {
                const response = await fetch(
                  `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(company.address)}.json?access_token=pk.eyJ1IjoiYS1sbC1sYWIiLCJhIjoiY2xqd3R2ZjUxMG9jYTNmcDQ3MHlpeTY2cSJ9.ek5xtDtVVwH6iogYFam5Cg`
                );
                const data = await response.json();
                if (data.features && data.features.length > 0) {
                  const [lng, lat] = data.features[0].center;
                  company.gps_location = `${lng},${lat}`;
                }
              } catch (error) {
                console.error('Error fetching geocoding data:', error);
              }
            }
            if (company.gps_location) {
              const [lng, lat] = company.gps_location.split(',');
              return {
                properties: {
                  cluster: false,
                  name: company.name,
                  companyId: company.id,
                  npa_locality: company.npa_locality,
                  logo: company.logo,
                  address: company.address,
                  description: company.description,
                  jobs: company.jobs,
                  full_address: company.full_address,
                  website: company.website,
                  activity_sectors: company.activity_sectors,
                  visits: company.visits,
                  media_collection: company.media_collection,
                  is_trainer_company: company.is_trainer_company,
                  has_limited_mobility: company.has_support_for_limited_mobility,
                  has_multiple_visits: company.has_multiple_visits,
                },
                geometry: {
                  coordinates: [parseFloat(lng), parseFloat(lat)],
                },
              };
            } else {
              return null;
            }
          })
        );

        const filteredPoints = geocodedPoints.filter((point) => point !== null);
        setPoints(filteredPoints);
      } catch (error) {
        console.log(error);
      }
    };
    fetchGeocodedData();
  }, [companies]);

  useEffect(() => {
    if (data?.companies) {
      setFiltersLoading(true);
      const filteredCompanies = data?.companies.filter((company) =>
        filterCompanies(company, { ...filters, name: searchValue }, i18n.language)
      );
      setCompanies(filteredCompanies);
      setFiltersLoading(false);
    }
  }, [data?.companies, filters, searchValue, filters.date]);

  return { companies: companies, setCompanies, isCompaniesLoading: isLoading || filtersLoading, points, setPoints };
}
