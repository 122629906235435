import React from 'react';
import visit_time from '../../../assets/icons/visit_time.svg';
import ContainedDropdown from '../../../Components/ContainedDropdown';

const VisitDropdown = ({ visit, onClick }) => {
  return (
    <div>
      <ContainedDropdown
        options={visit.times.map((el) => ({
          name: `${el.startTime}-${el.endTime}`,
          value: { startTime: el.startTime, endTime: el.endTime, nPlaces: el.nPlaces, nTakenPlaces: el.nTakenPlaces },
        }))}
        onFieldUpdate={visit}
        rectangle={true}
        margin={'20px 0 0 0'}
        icon={visit_time}
        placeholder={'company.page.visit.dropdown'}
        onClick={onClick}
      />
    </div>
  );
};

export default VisitDropdown;
