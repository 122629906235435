import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { configUseQuery } from '../../../helpers/queryConfig';
import { EventContext } from '../../../context/EventContext';
import api from '../../../api/api';

const getFavorites = async ({ queryKey }) => {
  const token = queryKey[1];
  const eventId = queryKey[2];
  if (token) {
    return api
      .get(`/favorites?eventID=${eventId}`)
      .then((response) => response.data?.favorites)
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
};

export function useGetFavorites(token) {
  const [event] = useContext(EventContext);
  const { data: fav, isLoading } = useQuery(['favorites', token, event.id], getFavorites, {
    ...configUseQuery,
    enabled: event.id !== null && event.enabled === true,
  });
  const [favorites, setFavorites] = useState(null);

  useEffect(() => {
    if (fav) {
      setFavorites(fav);
    }
  }, [fav]);

  return { favorites, isLoading: isLoading || favorites === null };
}
