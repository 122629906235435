import React, { useContext, useEffect, useState } from 'react';
import { useGetEventList } from '../../../hooks/api/event/useGetEventList';
import styled from 'styled-components';
import Flex from '../../Flex/Flex';
import date from '../../../assets/icons/event.svg';
import closed from '../../../assets/icons/keyboard_arrow_down.svg';
import open from '../../../assets/icons/keyboard_arrow_up.svg';
import AppIcon from '../../Icon/AppIcon';
import AppText from '../../Text/AppText';
import Loader from '../../Loader';
import { EventContext } from '../../../context/EventContext';

const EventSelectorWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 235px;
  @media (max-width: 1100px) {
    min-width: unset;
  }
`;

const OptionsContainer = styled.div`
  position: absolute;
  top: 40px;
  padding: 10px;
  background: white;
  left: 20px;
  border-radius: 5px;
  border: 2px solid rgba(235, 236, 240, 1);
  @media (max-width: 1100px) {
    left: -20px;
    width: 190px;
  }
`;

const EventNameWrapper = styled.div`
  display: block;
  ${({ alwaysShowSelectedName }) => !alwaysShowSelectedName && '@media (max-width: 1100px) {\n    display: none;\n  }'}
`;

const EventSelector = ({ alwaysShowSelectedName = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [event, setEvent] = useContext(EventContext);

  const { events, isLoading } = useGetEventList();

  useEffect(() => {
    if (events?.length > 0 && event.id !== null && event?.disabled !== true) {
      const isEventsDisabled = events.every((event) => event.enabled === false);
      if (isEventsDisabled) {
        setEvent({ disabled: true });
      }
    }
  }, [events, event]);

  useEffect(() => {
    if (events?.length > 0) {
      function getDaysDifference(date1, date2) {
        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.round(differenceInMilliseconds / millisecondsPerDay);
      }

      const today = new Date();
      const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());

      let closestDifference = Infinity;

      events.forEach((event) => {
        const eventDate = new Date(event.start_date);
        const difference = getDaysDifference(todayWithoutTime, eventDate);
        if (difference < closestDifference) {
          closestDifference = difference;
          setSelectedEvent(event.name);
          setEvent(event);
        }
      });
    }
  }, [events]);

  const handleSelectEvent = (event) => {
    setSelectedEvent(event?.name);
    setEvent(event);
    setIsOpen(false);
  };

  if (isLoading) {
    return (
      <div>
        <Loader wrapper={false} size={'s'} />
      </div>
    );
  }

  if (events.every((event) => event.enabled === false)) {
    return null;
  }

  return (
    <EventSelectorWrapper>
      <Flex
        gap={'10px'}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        style={{ cursor: 'pointer' }}
      >
        <AppIcon icon={date} />
        <EventNameWrapper alwaysShowSelectedName={alwaysShowSelectedName}>
          <AppText text={selectedEvent || events[0].name} fontWeight={'900'} fontSize={'sm'} color={'GrayBlue60'} />
        </EventNameWrapper>
        <AppIcon icon={isOpen ? open : closed} />
      </Flex>
      {isOpen && (
        <OptionsContainer>
          {events.map(
            (event) =>
              event.enabled && (
                <AppText
                  onClick={() => {
                    handleSelectEvent(event);
                  }}
                  style={{ cursor: 'pointer' }}
                  fontSize={'sm'}
                  text={event.name}
                  fontWeight={'900'}
                  color={'GrayBlue60'}
                />
              )
          )}
        </OptionsContainer>
      )}
    </EventSelectorWrapper>
  );
};

export default EventSelector;
