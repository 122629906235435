import React, { useState } from 'react';
import selectArrows from '../../assets/icons/selectArrows.svg';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import AppIcon from '../Icon/AppIcon';
import AppText from '../Text/AppText';

const SelectWrapper = styled.div`
  position: relative;

  margin: ${({ margin }) => margin || 'unset'};
`;

const StyledSelect = styled.select`
  appearance: none;
  cursor: pointer;
  padding: ${({ icon }) => (icon ? '13px 50px 13px 45px' : '13px 50px 13px 20px')};
  border-radius: ${({ rectangle }) => (rectangle ? '5px' : '30px')};
  border: 2px solid #ebecf0;
  box-shadow: 0px 2px 5px 0px #26334d08;
  background: url('${selectArrows}') no-repeat right 10px center;
  width: 100%;
  font-family: 'Fopen Midi Black', sans-serif;
  color: #8c99b3;
  &:focus {
    border: 4px solid #6688ff;
    outline: none;
    color: #4d5e80;
    padding: ${({ icon }) => (icon ? '11px 48px 11px 43px' : '11px 48px 11px 18px')};
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 8px;
  left: 10px;
`;

const CustomSelect = ({
  options,
  title,
  margin,
  placeholder = '',
  value,
  rectangle = false,
  icon,
  multilang = false,
  onChange = () => {},
  style,
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState(
    options.find((option) => option.value.toString() === value) || options[0]
  );
  const { t, i18n } = useTranslation();

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
    onChange(e);
  };

  return (
    <SelectWrapper margin={margin} style={style}>
      {title && (
        <AppText
          text={title}
          fontWeight={'900'}
          color={'GrayBlue50'}
          fontSize={'xs'}
          style={{ margin: '0 0 15px 30px ' }}
        />
      )}
      <StyledSelect rectangle={rectangle} icon={!!icon} value={selectedOption.value} onChange={handleChange} {...rest}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {multilang ? option.name[i18n.language] : t(option.name)}
          </option>
        ))}
      </StyledSelect>
      {icon && (
        <IconWrapper>
          <AppIcon icon={icon} />
        </IconWrapper>
      )}
    </SelectWrapper>
  );
};

export default CustomSelect;
