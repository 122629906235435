import React, { useEffect } from 'react';
import { ModalBackground, ModalWrapper } from './styled';

const Modal = ({ setShowModal, children, width, padding = '40px 30px' }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <ModalBackground>
      <ModalWrapper width={width} padding={padding}>
        {children}
      </ModalWrapper>
    </ModalBackground>
  );
};

export default Modal;
