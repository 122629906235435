import { useQuery } from 'react-query';
import { configUseQuery } from '../../helpers/queryConfig';
import api from '../../api/api';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { sortByNameField } from '../../helpers/helpers';

const fetchActivityTypes = async () => {
  const response = await api
    .get('/activitytypes')
    .then((response) => response)
    .catch((error) => console.log(error));
  return response.data;
};

export function useGetActivityTypes() {
  const [activityTypes, setActivityTypes] = useState(null);
  const { data, isLoading } = useQuery('activity_types', fetchActivityTypes, configUseQuery);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (data?.activityTypes.length > 0) {
      setActivityTypes(sortByNameField(data?.activityTypes, i18n.language, 'activity_type'));
    }
  }, [data, i18n.language]);

  return { activityTypes: activityTypes, isLoading };
}
