import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { nonPrivateRoutes, privateRoutes, publicRoutes } from './routes';
import { RouteWrapper } from './RouteWrapper';
import NotFoundPage from '../Pages/NotFoundPage';

const AppRouter = () => {
  const [token] = useContext(AuthContext);
  return (
    <>
      <Routes>
        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <RouteWrapper
                component={route.component}
                layoutProps={route.layoutProps}
                wrapper={route?.wrapper}
                clean={route?.clean}
              />
            }
          />
        ))}
        {(token ? privateRoutes : nonPrivateRoutes).map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <RouteWrapper
                component={route.component}
                layoutProps={route.layoutProps}
                wrapper={route?.wrapper}
                clean={route?.clean}
              />
            }
          />
        ))}
        {(token ? nonPrivateRoutes : privateRoutes).map((route) => (
          <Route key={route.path} path={route.path} element={<Navigate to={'/'} />} />
        ))}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default AppRouter;
