export function shuffleArray(array) {
  if (array === null) {
    return null;
  }
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const sortByNameField = (array, field, sortField = 'name') => {
  return array.sort((a, b) => {
    if (a[sortField][field] < b[sortField][field]) {
      return -1;
    }
    if (a[sortField][field] > b[sortField][field]) {
      return 1;
    }
    return 0;
  });
};

export function convertUTCToLocalWithTimezone(utcDateStr) {
  const utcDate = new Date(utcDateStr);
  const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
  const localDate = new Date(utcDate - userTimezoneOffset);

  const localISOString = localDate.toISOString();

  return localISOString.slice(0, -5) + 'Z';
}
