import styled from 'styled-components';

export const BackButtonWrapper = styled.button`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
`;

export const LanguageButtonsContainer = styled.button`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  padding: 30px 40px;
`;
