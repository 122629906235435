import { useQuery } from 'react-query';
import { configUseQuery } from '../../../helpers/queryConfig';
import api from '../../../api/api';

const fetchVisit = async ({ queryKey }) => {
  const id = queryKey[1];
  return await api
    .get('/visits/' + id)
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export function useGetVisit(id) {
  const { data, isLoading } = useQuery(['visit', id], fetchVisit, configUseQuery);

  return { visitsData: data?.visit, isLoading };
}
