import React, { useContext, useState } from 'react';
import AppText from '../../../../Components/Text/AppText';
import Modal from '../../../../Components/Modal';
import Steps from './components/Steps';
import AppButton from '../../../../Components/Button/AppButton';
import Input from '../../../../Components/Form/Input';
import Checkbox from '../../../../Components/Form/Checkbox';
import styled from 'styled-components';
import { usePostVisits } from '../../../../hooks/api/visits/usePostVisits';
import { useGetInterests } from '../../../../hooks/api/useGetInterests';
import StepOne from './components/StepOne';
import StepTwo from './components/StepTwo';
import { useTranslation } from 'react-i18next';
import { useGetRegistrations } from '../../../../hooks/api/registrations/useRegistrations';
import FormError from '../../../../Components/Form/FormError';
import { EventContext } from '../../../../context/EventContext';

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 30px;
  padding: 0 80px;
  align-items: flex-start;
  ${({ style }) => style};

  @media (max-width: 500px) {
    padding: 0;
  }
`;

function checkTimeOverlap(existingEvents, newEvent) {
  for (const existingEvent of existingEvents) {
    if (existingEvent.visit_date === newEvent.visit_date) {
      if (
        (existingEvent.visit_startTime <= newEvent.visit_startTime &&
          newEvent.visit_startTime <= existingEvent.visit_endTime) ||
        (existingEvent.visit_endTime >= newEvent.visit_startTime &&
          newEvent.visit_endTime >= existingEvent.visit_endTime) ||
        (newEvent.visit_startTime <= existingEvent.visit_startTime &&
          newEvent.visit_endTime >= existingEvent.visit_endTime)
      ) {
        return true;
      }
    }
  }
  return false;
}

const VisitModal = ({ visit, visitIndex, timeAndPlaces, companyData, setOpenVisit, selectedTimeLanguage }) => {
  const [step, setStep] = useState(1);
  const [companions, setCompanions] = useState([]);
  const [newRow, setNewRow] = useState({ firstName: '', lastName: '', date_of_birth: '' });
  const [selectedInterests, setSelectedInterests] = useState([]);
  const { i18n } = useTranslation();
  const [other, setOther] = useState('');
  const mutation = usePostVisits();
  const { interests } = useGetInterests();
  const { registrationsData } = useGetRegistrations();
  const [event] = useContext(EventContext);

  const continueDisabled = Boolean(newRow.firstName || newRow.lastName);
  const timeOverlap =
    registrationsData &&
    checkTimeOverlap(registrationsData, {
      visit_date: visit.visit_date,
      visit_startTime: timeAndPlaces.startTime,
      visit_endTime: timeAndPlaces.endTime,
    });
  const activityTypes = companyData.visits?.find((v) => v.id === visit?.ids[visitIndex])?.activity_types;
  const activityTypesNames = activityTypes?.map((at) => at?.activity_type[i18n.language]);
  const activityRestriction = companyData.visits?.find((v) => v.id === visit?.ids[visitIndex])?.activity_restrictions;
  const activityRestrictionNames = activityRestriction?.map((res) =>
    res?.activity_restriction?.fr ? res?.activity_restriction[i18n.language] : null
  );

  const goBack = () => {
    setStep((prevState) => prevState - 1);
  };

  const handleCheckbox = (sectorName) => {
    setSelectedInterests((prevSelected) => {
      if (prevSelected.includes(sectorName)) {
        return prevSelected.filter((name) => name !== sectorName);
      } else {
        return [...prevSelected, sectorName];
      }
    });
  };

  const handleSubmit = () => {
    mutation.mutate({
      selectedVisits: { id: visit.ids[visitIndex] },
      isCheck: true,
      inscriptionData: companions,
      interests: selectedInterests,
      custom_interest: other,
    });
    setOpenVisit(false);
  };

  return (
    <Modal width={window.innerWidth > 850 ? (step === 4 ? '80%' : 'unset') : '80%'}>
      <AppText
        text={step !== 4 ? 'landing.register' : 'company.page.visit.step4.title'}
        color={'GrayBlue50'}
        fontSize={'xl'}
        fontWeight={'900'}
        textAlign={'center'}
      />
      <AppText
        text={step !== 4 ? 'company.page.visit.modal.body' : 'company.page.visit.step4.body'}
        color={'GrayBlue55'}
        fontSize={'md'}
        textAlign={'center'}
      />
      {step !== 4 && (
        <Steps
          steps={[
            { number: 1, name: 'company.page.visit.modal.step.1' },
            { number: 2, name: 'company.page.visit.modal.step.2' },
            { number: 3, name: 'company.page.visit.modal.step.3' },
          ]}
          currentStep={step}
        />
      )}
      <div style={{ marginTop: '40px' }}>
        {step === 1 && (
          <>
            {timeOverlap && <FormError message={'companyPage.visit.alert.timeOverlaps'} margin={'30px 0 0 30px'} />}
            <StepOne
              companyData={companyData}
              activityTypesNames={activityTypesNames}
              activityRestriction={activityRestrictionNames}
              timeAndPlaces={timeAndPlaces}
              visit={visit}
              selectedTimeLanguage={selectedTimeLanguage}
            />
            <AppButton
              text={'form.profile.continue'}
              size={'xl'}
              disabled={timeOverlap}
              textAlign={'center'}
              onClick={() => setStep(2)}
              styles={{ width: '50%', margin: '40px auto' }}
            />
            <AppButton
              text={'form.profile.cancel'}
              size={'xl'}
              textAlign={'center'}
              styleType={'ghost'}
              onClick={() => setOpenVisit(false)}
              styles={{ margin: '40px auto 0' }}
            />
          </>
        )}
        {step === 2 && (
          <>
            <StepTwo
              visit={visit}
              companions={companions}
              setCompanions={setCompanions}
              timeAndPlaces={timeAndPlaces}
              showPlacesGauge={event?.show_filling_gauge}
              setNewRow={setNewRow}
            />
            <AppButton
              text={'form.profile.continue'}
              size={'xl'}
              textAlign={'center'}
              disabled={continueDisabled}
              onClick={() => setStep(3)}
              styles={{ width: '50%', margin: '40px auto' }}
            />
            <AppButton
              text={'company.page.return'}
              size={'xl'}
              textAlign={'center'}
              styleType={'ghost'}
              onClick={() => goBack()}
              styles={{ margin: '40px auto 0' }}
            />
          </>
        )}
        {step === 3 && (
          <>
            <div style={{ marginBottom: '20px' }}>
              <StepOne
                companyData={companyData}
                activityTypesNames={activityTypesNames}
                activityRestriction={activityRestrictionNames}
                visit={visit}
                timeAndPlaces={timeAndPlaces}
                selectedTimeLanguage={selectedTimeLanguage}
              />
            </div>
            <div>
              <StepTwo
                visit={visit}
                companions={companions}
                setCompanions={setCompanions}
                timeAndPlaces={timeAndPlaces}
                setNewRow={setNewRow}
                isTableReadOnly={true}
              />
              <AppButton
                text={'form.profile.continue'}
                size={'xl'}
                textAlign={'center'}
                disabled={continueDisabled}
                onClick={() => setStep(4)}
                styles={{ width: '50%', margin: '40px auto' }}
              />
              <AppButton
                text={'company.page.return'}
                size={'xl'}
                textAlign={'center'}
                styleType={'ghost'}
                onClick={() => goBack()}
                styles={{ margin: '40px auto 0' }}
              />
            </div>
          </>
        )}
        {step === 4 &&
          (interests.length ? (
            <>
              <GridContainer>
                {interests.map(({ id, interest }) => (
                  <Checkbox
                    key={id}
                    label={interest[i18n.language]}
                    value={selectedInterests.includes(id)}
                    onChange={() => {
                      handleCheckbox(id);
                    }}
                  />
                ))}
              </GridContainer>
              <Input
                textField={true}
                value={other}
                onChange={(e) => setOther(e.target.value)}
                title={'company.page.visit.step4.other'}
                margin={'52px 0 0 0'}
              />
              <AppButton
                text={'form.profile.continue'}
                size={'xl'}
                textAlign={'center'}
                onClick={handleSubmit}
                disabled={!(selectedInterests.length || other.length > 0)}
                styles={{ width: '50%', margin: '40px auto' }}
              />
              <AppButton
                text={'company.page.return'}
                size={'xl'}
                textAlign={'center'}
                styleType={'ghost'}
                onClick={() => goBack()}
                styles={{ margin: '40px auto 0' }}
              />
            </>
          ) : null)}
      </div>
    </Modal>
  );
};

export default VisitModal;
