import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Input from '../../../Components/Form/Input';
import search from '../../../assets/icons/search.svg';
import Switch from '../../../Components/Switch';
import ParamsDropdown from './ParamsDropdown';
import CalendarDatepicker from '../../../Components/Form/Datepicker/CalendarDatepicker';
import Dropdown from '../../../Components/Dropdown';
import { AuthContext } from '../../../context/AuthContext';
import { useGetActivitySectors } from '../../../hooks/api/useGetActivitySectors';
import Loader from '../../../Components/Loader';
import { formatDate } from '../../../helpers/date';
import { SearchContext } from '../../../context/SearchContext';
import AppButton from '../../../Components/Button/AppButton';
import { useTranslation } from 'react-i18next';
import { useGetActivityTypes } from '../../../hooks/api/useGetActivityTypes';
import { useGetJobs } from '../../../hooks/api/useGetJobs';
import { EventContext } from '../../../context/EventContext';

const FiltersSidebar = styled.div`
  ${({ mobile }) =>
    mobile
      ? ''
      : 'padding: 30px 20px;\n  border: solid #ebecf0;\n  border-width: 0 0 0 2px;\n  background: #ffffff;\n  width: 326px;\n  height: 100%;'}
  padding: 30px;
  background: rgba(250, 251, 252, 1);
  border: rgba(237, 239, 242, 1) 2px solid;
  border-radius: 5px;
`;

const InputWrapper = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
`;

const CompanyFilters = ({ mobile = false, filters, setFilters }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [showClearButton, setShowClearButton] = useState(false);
  const [_, refresh] = useState(false);
  const [jobSearch, setJobSearch] = useState('');
  const { activitySectors, isLoading } = useGetActivitySectors();
  const { activityTypes, isLoading: isActivityTypesLoading } = useGetActivityTypes();
  const { jobs, isJobsLoading } = useGetJobs();
  const [token] = useContext(AuthContext);
  const [searchValue, setSearchValue] = useContext(SearchContext);
  const [event] = useContext(EventContext);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (activityTypes?.length > 0 && activitySectors?.length > 0) {
      refresh((prevState) => !prevState);
    }
  }, [activityTypes, activitySectors, i18n.language]);

  useEffect(() => {
    if (filters) {
      setShowClearButton(
        Object.values(filters).some((param) => (Array.isArray(param) ? param?.length > 0 : !!param)) ||
          searchValue.length > 0
      );
    }
  }, [filters]);

  useEffect(() => {
    if (selectedDate !== null) {
      const { day, monthNumber, year } = formatDate(selectedDate);
      setFilters((prevState) => ({ ...prevState, date: `${year}-${monthNumber}-${day - 1}` }));
    } else {
      setFilters((prevState) => ({ ...prevState, date: '' }));
    }
  }, [selectedDate]);

  useEffect(() => {
    if (jobSearch.length === 0 && filters.jobs.length > 0) {
      setFilters((prevState) => ({ ...prevState, jobs: [] }));
    }
  }, [jobSearch, filters]);

  const handleSearch = (e) => {
    setJobSearch(e.target.value);
  };
  const handleSwitchFav = () => {
    setFilters((prevState) => ({ ...prevState, favorites: !prevState.favorites }));
  };
  const handleSwitch = () => {
    setFilters((prevState) => ({ ...prevState, onlyTraining: !prevState.onlyTraining }));
  };

  const handleClickActivities = (newSelectedTags) => {
    setFilters((prevState) => ({ ...prevState, activitySectors: Array.from(newSelectedTags) }));
  };

  const handleClickActivityTypes = (newSelectedTags) => {
    setFilters((prevState) => ({ ...prevState, activityTypes: Array.from(newSelectedTags) }));
  };

  const handleClickJobs = (newSelectedTags) => {
    setFilters((prevState) => ({ ...prevState, jobs: Array.from(newSelectedTags) }));
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const clearFilters = () => {
    setFilters({
      jobs: [],
      selectedJobs: '',
      onlyTraining: false,
      activitySectors: [],
      favorites: false,
      activityTypes: [],
      date: '',
    });
    setJobSearch('');
    setSearchValue('');
  };

  return (
    <>
      <FiltersSidebar mobile={mobile}>
        {showClearButton && (
          <AppButton
            text={'c-filters.deleteFilters'}
            styles={{ width: '100%', margin: '5px 0 0 0' }}
            textAlign={'center'}
            onClick={clearFilters}
          />
        )}
        <InputWrapper>
          <Input
            placeholder={'companiesList.search'}
            startIcon={search}
            onChange={handleChange}
            value={searchValue}
            debounced
            topStartIcon={'8px'}
          />
        </InputWrapper>
        <Input
          placeholder={'placeholders.searchJob'}
          onChange={handleSearch}
          value={jobSearch}
          debounced
          startIcon={search}
          margin={'0 0 15px 0'}
        />
        {jobSearch.length > 0 && (
          <ParamsDropdown
            displayList
            options={jobs.filter((job) => job?.name[i18n.language].toLowerCase().includes(jobSearch.toLowerCase()))}
            value={filters?.jobs}
            onItemClick={handleClickJobs}
          />
        )}
        <Switch onChange={handleSwitch} label={'c-filters.trainingCompanies'} value={filters?.onlyTraining} />
        {token && (
          <Switch
            onChange={handleSwitchFav}
            label={'favorites.title'}
            value={filters?.favorites}
            style={{ margin: '20px 0 0 0' }}
          />
        )}
        {activitySectors?.length > 0 && (
          <ParamsDropdown
            label={'companyPage.filter.activities'}
            options={activitySectors}
            value={filters?.activitySectors}
            onItemClick={handleClickActivities}
          />
        )}
        {activityTypes?.length > 0 && (
          <ParamsDropdown
            label={'companiesPage.filters.activityTypes'}
            options={activityTypes}
            value={filters?.activityTypes}
            onItemClick={handleClickActivityTypes}
          />
        )}
        {isLoading || isActivityTypesLoading || (isJobsLoading && <Loader />)}
        <Dropdown margin={'30px 0 0 0'} label={'visits.confirmation.date'}>
          {event?.start_date && event?.end_date && (
            <CalendarDatepicker
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              minDate={new Date(event?.start_date)}
              maxDate={new Date(event?.end_date)}
              openToDate={new Date(event?.start_date)}
            />
          )}
        </Dropdown>
      </FiltersSidebar>
    </>
  );
};

export default CompanyFilters;
