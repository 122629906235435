import styled, { css, keyframes } from 'styled-components';

const slideUp = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

export const StyledAlert = styled.div`
  border-radius: 10px;
  padding: 10px 20px;
  background: ${({ type }) => getAlertColor(type)};
  margin: 0 auto;
  width: 50vw;
  animation: ${(props) =>
    props.animate
      ? css`
          ${slideUp} 1s ease forwards
        `
      : 'none'};
`;

export const AlertWrapper = styled.div`
  position: fixed;
  top: 20px;
  left: 0;
  width: 100%;
  z-index: 1000000000;
`;

const getAlertColor = (type) => {
  switch (type) {
    case 'error':
      return '#f24949';
    case 'success':
      return '#45E655';
    default:
      return '#3361FF';
  }
};
