import React from 'react';
import FirstLineBlackText from './components/FirstLineBlackText';
import AppText from '../../Components/Text/AppText';
import './style.scss';

const MobileStandByPage = () => {
  return (
    <div className="maintenance-container">
      <div className="maintenance-content">
        <div className="maintenance-heading">
          <FirstLineBlackText>Notre application est est fermée pour le moment.</FirstLineBlackText>
        </div>
        <div className="maintenance-text">
          <AppText
            text={'Découvrez l’aventure FribourgOPEN et les événements à venir sur notre site internet '}
            color="black"
            fontSize="sm"
          />
          <AppText text={'www.fribourgopen.ch'} color="black" fontSize="sm" className={'b-700'} />
        </div>
      </div>
    </div>
  );
};

export default MobileStandByPage;
