import React from 'react';
import Modal from '../Modal';
import Flex from '../Flex/Flex';
import LoginFormCard from '../LoginFormCard';

const LoginModal = ({ setOpenLoginModal }) => {
  return (
    <Modal width={'80%'} padding={'0 30px'}>
      <Flex alignItems={'center'} flexDirection={'column'}>
        <LoginFormCard subTitle={'modal.login.title'} onSuccess={setOpenLoginModal} />
      </Flex>
    </Modal>
  );
};

export default LoginModal;
