import React from 'react';
import styled from 'styled-components';

const StyledDivider = styled.div`
  height: 2px;
  background: #ebecf0;
  margin: ${({ margin }) => margin};
`;

const Divider = ({ margin = '0px' }) => {
  return <StyledDivider margin={margin} />;
};

export default Divider;
