import React from 'react';
import styled from 'styled-components';

const Styled = styled.a`
  color: #ff6633;
  text-decoration: underline;
  text-align: left;
`;

const MyLink = ({ href, children }) => {
  return (
    <Styled href={href} target="_blank">
      {children}
    </Styled>
  );
};

export default MyLink;
