import React, { createContext, useState } from 'react';

const SearchContext = createContext();

const MySearchProvider = ({ children }) => {
  const [searchValue, setSearchValue] = useState('');

  return <SearchContext.Provider value={[searchValue, setSearchValue]}>{children}</SearchContext.Provider>;
};

export { SearchContext, MySearchProvider };
