import styled from 'styled-components';

export const FiltersButtonWrapper = styled.div`
  padding: 20px 80px 0;
  display: none;
  @media (max-width: 900px) {
    display: block;
  }
  @media (max-width: 600px) {
    padding: 20px 40px 0;
  }
`;

export const FiltersWrapper = styled.div`
  @media (max-width: 900px) {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  display: block;
  @media (max-width: 400px) {
    display: none;
  }
`;
