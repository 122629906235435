import React, { useContext, useEffect, useState } from 'react';
import { useGetRegistrations } from '../../hooks/api/registrations/useRegistrations';
import Table from '../../Components/Table';
import { useTranslation } from 'react-i18next';
import EditVisit from './components/EditVisit';
import { columns } from './constants';
import DeleteVisit from './components/DeleteVisit';
import Loader from '../../Components/Loader';
import AppText from '../../Components/Text/AppText';
import styled from 'styled-components';
import { EventContext } from '../../context/EventContext';

const TableWrapper = styled.div`
  overflow-x: auto;
  white-space: nowrap;
`;

const Visits = () => {
  const [registrations, setRegistrations] = useState(null);
  const { registrationsData, isLoading } = useGetRegistrations();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedVisitId, setSelectedDataId] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { i18n } = useTranslation();
  const [event] = useContext(EventContext);

  useEffect(() => {
    if (registrationsData) {
      setRegistrations(registrationsData);
    }
  }, [registrationsData]);

  const handleOpenEditModal = (visitsData) => {
    setOpenEditModal(true);
    setSelectedDataId({ visit: visitsData.visit_id, registration: visitsData.id });
  };
  const handleDeleteClick = (visitsData) => {
    setOpenDeleteModal(true);
    setSelectedDataId({ visit: visitsData.visit_id, registration: visitsData.id });
  };

  if (event?.disabled) {
    return (
      <AppText
        text={'events.disabled'}
        color={'GrayBlue70'}
        fontWeight={'900'}
        textAlign={'center'}
        style={{ margin: '60px' }}
      />
    );
  }

  if (registrations?.length === 0 && !isLoading) {
    return (
      <AppText
        text={'visits.list.empty'}
        color={'GrayBlue70'}
        fontWeight={'900'}
        textAlign={'center'}
        style={{ margin: '150px 0' }}
      />
    );
  }

  if (isLoading) {
    return <Loader wrapper={true} />;
  }

  return (
    <div>
      {registrations?.length > 0 && (
        <TableWrapper>
          <Table
            columns={columns(i18n)}
            data={registrations}
            editClick={handleOpenEditModal}
            deleteClick={handleDeleteClick}
            editable
          />
        </TableWrapper>
      )}
      {openEditModal && (
        <EditVisit
          selectedVisitId={selectedVisitId.visit}
          registrationId={selectedVisitId.registration}
          setOpen={setOpenEditModal}
        />
      )}
      {openDeleteModal && (
        <DeleteVisit
          registrationId={selectedVisitId.registration}
          setRegistrations={setRegistrations}
          setOpenDeleteModal={setOpenDeleteModal}
        />
      )}
    </div>
  );
};

export default Visits;
